import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Button } from "@material-ui/core";
import ButtonRoot from "components/Buttons/ButtonRoot";
import Stepper from "components/Buttons/Stepper";
import { makeStyles } from "@material-ui/styles";
import { useApolloClient } from "react-apollo";
import * as path from "constants/routes";
import { getLeadHistoryItem, getLeadStages } from "api/queries";
import moment from "moment/moment";
import ModalCreateOrConvertLead from "components/Modals/ModalCreateOrConvertLead";
import { convertLeadToClient } from "api/mutations";
import Avatar from "react-avatar";
import { formatMediaUrl, formatName } from "utils";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TransferOwnershipForm from "components/Forms/TransferOwnershipForm";
import Box from "@material-ui/core/Box";

import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "20px 0 62px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    border: "1px solid #E0E0E0",
  },
  candidateName: {
    padding: "0 30px",
    height: "38px",
    fontSize: "32px",
    lineHeight: "38px",
    fontWeight: 400,
  },
  toolsBar: {
    display: "flex",
    padding: "0 30px",
  },
  archiveButton: {
    height: "40px",
    width: "130px",
    margin: "0 24px",
    borderColor: "#594BF6",
    color: "#594BF6",
    textTransform: "none",
    padding: "11.5px 41px",
  },
  unArchiveButton: {
    height: "40px",
    width: "130px",
    margin: "0 24px",
    color: "white",
    borderColor: "#594BF6",
    textTransform: "none",
    padding: "11.5px 41px",
    backgroundColor: "#594BF6",
    boxShadow: "none",
    "&:hover": { backgroundColor: "#3E3B9F", boxShadow: "none" },
  },
  convertButton: {},
  stepperColumn: {
    padding: 0,
    "& > #stepper": {
      "& > div": {
        height: "32px",
        borderRadius: 0,
      },
    },
  },
  transferButton: {
    height: "40px",
    width: "auto",
    color: "white",
    borderColor: "#594BF6",
    textTransform: "none",
    padding: "11.5px 41px",
    backgroundColor: "#594BF6",
    boxShadow: "none",
    "&:hover": { backgroundColor: "#3E3B9F", boxShadow: "none" },
  },
}));
const stageNames = [
  "New Lead",
  "Hot Lead",
  "Meeting Appointed",
  "Negotiation",
  "Project Approved",
];
const Header = ({
  lead,
  leadName,
  owners,
  owner,
  stage,
  leadId,
  changeStage,
  isArchive,
  changeIsArchivedHandler,
  ownerChangeHandler,
  createLead,
}) => {
  const classes = useStyles();
  const [stages, setStages] = useState([]);
  const [leadHistory, setLeadHistory] = useState([]);
  const apolloClient = useApolloClient();
  const [isModalConvertOpen, setModalConvertOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onModalConvertCloseHandler = () => {
    setModalConvertOpen((prevState) => !prevState);
  };
  const fetchDataHistory = async () => {
    if (typeof stage !== "string") return;

    const response = await apolloClient.query({
      query: getLeadHistoryItem,
      fetchPolicy: "no-cache",
      variables: { leadId },
    });
    const data = response.data.leadHistoryItem
      .filter((a) => stageNames.slice(0, stage))
      .map((a, index) => ({
        stage: stageNames.indexOf(a.nameItem),
        name: a.nameItem,
        differenceInDays: moment(
          response.data.leadHistoryItem[index + 1]?.historyDate || new Date(),
        ).diff(moment(a.historyDate), "days"),
      }));
    let newData = data.reduce((a, current) => {
      const { stage, differenceInDays } = current;
      if (!a[stage]) {
        a[stage] = {
          stage: stage,
          name: current.name,
          differenceInDays: differenceInDays,
        };
      } else {
        a[stage].differenceInDays += differenceInDays;
      }

      return a;
    }, {});
    newData = Object.values(newData).filter((item) => item.stage < stage);
    const currentMaxStage = Math.max(
      ...Object.keys(newData).map((key) => newData[key].stage),
    );

    for (let i = 0; i <= currentMaxStage; i++) {
      if (!Object.keys(newData).includes(`${i}`)) {
        newData[`${i}`] = {
          stage: i,
          name: stageNames[i],
          differenceInDays: 0,
        };
      }
    }
    const newLeadHistory = stageNames.map(
      (stage, index) =>
        newData[index] || {
          stage: index,
          name: stageNames[index],
          differenceInDays: null,
        },
    );
    setLeadHistory(newLeadHistory);
  };
  const fetchLeadStages = async () => {
    const response = await apolloClient.query({
      query: getLeadStages,
      fetchPolicy: "no-cache",
    });
    setStages(response?.data?.leadStages);
  };

  useEffect(() => {
    fetchLeadStages();
    fetchDataHistory();
  }, [stage]);

  const convertToClient = async (data) => {
    if (data?.contactPersons) {
      const [firstName, lastName] = data?.contactPersons?.text.split(" ");
      data.firstName = firstName;
      data.lastName = lastName;
    }
    if (data?.labelId) data.labelId = data.labelId.value;
    if (data?.source) data.source = data?.source.value;
    if (data?.industry) data.industry = data?.industry.value;
    if (data?.project) data.project = data?.project.value;
    if (data?.country) data.country = data?.country.label;
    if (data?.value) data.value = parseFloat(data.value);
    if (data?.currency) data.currency = data.currency.value;
    if (typeof data?.value === "number") data.value = parseFloat(data.value);
    if (typeof data?.value === "string") data.value = parseFloat(0);
    const response = await apolloClient.mutate({
      mutation: convertLeadToClient,
      variables: {
        leadId,
        ...data,
      },
    });
    if (response?.data?.convertToEmployee?.success) {
      swal({
        title: "Success!",
        content: {
          element: "p",
          attributes: {
            innerHTML: `Lead has been successfully converted into a client.  <br/>You can now view it in the Client section. <br/><a href="#" onclick="window.location.href='${path.CLIENTS_AND_CONTACTS}'" style="color: blue; text-decoration: underline;">View client page</a> and shedule activity.`,
          },
        },
        icon: "success",
        button: "OK",
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Row className={classes.wrapper}>
        <Col className={classes.candidateName}>{leadName}</Col>
        <Col md={"auto"} className={classes.toolsBar}>
          <span
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ display: "flex", cursor: "pointer" }}
          >
            <Avatar
              src={formatMediaUrl(lead?.owner?.avatar)}
              round={true}
              size={36}
              className={"avatar"}
              color="#cdcdce"
              style={{ marginRight: "8px" }}
            />
            <Box style={{ fontSize: "12px" }}>
              <span style={{ fontSize: "14px" }}>{formatName(lead.owner)}</span>{" "}
              <br />
              Owner
            </Box>
            <ArrowDropDownIcon
              style={{
                verticalAlign: "middle",
              }}
            />
          </span>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              style={{
                backgroundColor: "white",
                padding: 0,
              }}
            >
              <TransferOwnershipForm
                submitForm={ownerChangeHandler}
                closeModal={handleClose}
                managers={owners}
                manager={owner}
              />
            </MenuItem>
          </Menu>
          <Button
            className={
              isArchive ? classes.unArchiveButton : classes.archiveButton
            }
            variant={isArchive ? "contained" : "outlined"}
            onClick={changeIsArchivedHandler(!isArchive)}
          >
            {isArchive ? "Unarchive" : "Archive"}
          </Button>
          <ButtonRoot
            className={classes.convertButton}
            text="Convert to client"
            onClick={onModalConvertCloseHandler}
          />
        </Col>
        <Col className={classes.stepperColumn} md={12}>
          <Stepper
            style={{
              marginTop: "22px",
            }}
            stage={stage}
            stages={stages}
            changeStage={changeStage}
            type={"lead"}
            schema={[
              ["16.5%", "95% 0, 100% 50%, 95% 100%, 0 100%, 0 50%, 0 0"],
              ["16.5%", "95% 0, 100% 50%, 95% 100%, 0 100%, 4% 50%, 0 0"],
              ["16.5%", "95% 0, 100% 50%, 95% 100%, 0 100%, 4% 50%, 0 0"],
              ["16.5%", "95% 0, 100% 50%, 95% 100%, 0 100%, 4% 50%, 0 0"],
              ["33%", "100% 0, 100% 53%, 100% 100%, 0 100%, 2% 50%, 0 0"],
            ]}
            labels={leadHistory.map((a) =>
              a.differenceInDays === null ? null : a.differenceInDays + " days",
            )}
          />
        </Col>
      </Row>

      <ModalCreateOrConvertLead
        isOpen={isModalConvertOpen}
        onClose={onModalConvertCloseHandler}
        values={lead}
        createLead={createLead}
        onSubmit={convertToClient}
      />
    </>
  );
};

export default Header;
