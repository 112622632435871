import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import ModalAddOrEditEmailTemplate from "../../Modals/ModalAddOrEditEmailTemplate";
import { Query, useApolloClient } from "react-apollo";
import TableWrapper from "../../TableWrapper";
import i18n from "../../../i18n";
import moment from "moment";
import SelectAutocomplete from "../../Select/SelectAutocomplete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { getCandidateTemplates, getLeadTemplates } from "../../../api/queries";
import { makeStyles } from "@material-ui/styles";
import ButtonRoot from "../../Buttons/ButtonRoot";
import { Box, IconButton } from "@material-ui/core";
import { ModalFooter } from "reactstrap";
import {
  createCandidateEmailTemplate,
  createLeadEmailTemplate,
  deleteCandidateEmailTemplate,
  deleteLeadEmailTemplate,
  updateCandidateEmailTemplate,
  updateLeadEmailTemplate,
} from "../../../api/mutations";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "29px 25px",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    border: "1px solid #E0E0E0",
  },

  search: {
    width: "633px",
    margin: "0px 15px",
    marginTop: "15px",
    position: "relative",
    "& .MuiAutocomplete-endAdornment": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      height: "40px",
    },
    "& #search": {
      position: "absolute",
      width: "633px",
    },
  },
  button: {
    height: "15px",
    width: "auto",
    color: "white",
    borderColor: "#594BF6",
    textTransform: "none",
    padding: "11.5px 41px",
    margin: "15px",
    backgroundColor: "#594BF6",
    boxShadow: "none",
    "&:hover": { backgroundColor: "#3E3B9F", boxShadow: "none" },
  },
}));
const ManageEmailTemplateForm = (props) => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [table, setTable] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [search, setSearch] = useState(null);
  const apolloClient = useApolloClient();
  const classes = useStyles();
  const [templateModalData, setTempalateModalData] = useState(null);
  const [editModal, setEditModal] = useState(false);

  const getTemplateQueryHandle = (type) => {
    switch (type) {
      case "candidate":
        return {
          deleteMutation: deleteCandidateEmailTemplate,
          getTemplates: getCandidateTemplates,
        };
      case "lead":
        return {
          deleteMutation: deleteLeadEmailTemplate,
          getTemplates: getLeadTemplates,
        };
      default:
        return {};
    }
  };
  const deleteHandler = async (value) => {
    const { deleteMutation } = getTemplateQueryHandle(props.type);
    const response = await apolloClient.mutate({
      mutation: deleteMutation,
      variables: {
        templateId: value,
      },
    });
    fetchTemplates();
  };
  const filterChangeHandler = (e) => {
    setFilterValue(e);
  };
  const handleModalOpen = () => {
    setIsModalAddOpen((prevState) => !prevState);
  };
  const handleClose = () => {
    setEditModal(false);
    setTempalateModalData(null);
    setIsModalAddOpen((prevState) => !prevState);
  };
  const fetchTemplates = async () => {
    const { getTemplates } = getTemplateQueryHandle(props.type);
    const response = await apolloClient.query({
      query: getTemplates,
      fetchPolicy: "no-cache",
    });

    setTable(response?.data?.[`${props.type}EmailTemplates`]);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);
  const headerRows = [
    {
      columns: [
        {
          Header: i18n.t("Template name"),
          Cell: (row) => row.original?.templateName || "-",
          accessor: "templateName",
          className: "text-center",
        },
        {
          Header: i18n.t("Created at"),
          Cell: (row) => moment(row.original?.createdAt).format("DD-MMM-YYYY"),
          accessor: "createdAt",
          className: "text-center",
        },
        {
          Header: "",
          Cell: (row) => (
            <IconButton
              style={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={(event) => {
                event.stopPropagation();
                deleteHandler(row.original?.id);
              }}
            >
              {" "}
              <DeleteOutlineIcon />
            </IconButton>
          ),
          accessor: "templateName",
        },
      ],
    },
  ];
  useEffect(() => {
    if (table) {
      let newData = table.filter((el) =>
        !filterValue ? el : filterValue.value === el?.id,
      );
      setFilteredData(newData);
    }
  }, [table, filterValue]);

  return (
    <>
      <div>
        <SelectAutocomplete
          className={classes.search}
          placeholder="Search"
          value={search}
          setValue={filterChangeHandler}
          options={table?.map((el) => ({
            value: el.id,
            text: el.templateName,
          }))}
        />
        <ButtonRoot
          className={classes.button}
          text="Add email template"
          type="button"
          onClick={handleModalOpen}
        >
          Add new template
        </ButtonRoot>
        <div
          className={`card`}
          style={{
            marginLeft: " 15px",
            marginRight: "15px",
          }}
        >
          <TableWrapper
            headerRows={headerRows}
            objects={filteredData ? filteredData : table}
            showPagination={true}
            canHover={true}
            onRowClick={(state, row) => {
              setEditModal(true);
              handleModalOpen();
              setTempalateModalData(row.original);
            }}
          />
        </div>
        <ModalAddOrEditEmailTemplate
          isOpen={isModalAddOpen}
          onClose={handleClose}
          editMode={editModal}
          templateData={templateModalData}
          onSubmit={setIsModalAddOpen}
          fetchTemplates={fetchTemplates}
          type={props.type}
        />
      </div>
      <ModalFooter>
        <Box className="d-flex">
          <ButtonRoot text="Close" onClick={props.closeModal} type="button" />
        </Box>
      </ModalFooter>
    </>
  );
};

export default ManageEmailTemplateForm;
