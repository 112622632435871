import Box from "@material-ui/core/Box";
import ButtonRoot from "components/Buttons/ButtonRoot";
import moment from "moment";
import React, { useRef, useState, useEffect } from "react";
import { withApollo, useQuery } from "react-apollo";
import { useHistory, withRouter } from "react-router";
import { Link, matchPath } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import SideBarLinks from "./Links/sidebarLinks";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Avatar from "@material-ui/core/Avatar";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationModal from "./NotificationModal";
import { allEvents, getNotificationsForUser } from "api/queries";
import Links from "./Links";
import * as path from "constants/routes";
import { BACKEND_URL } from "constants/index";
import { useClickOutside } from "utils/index";
import i18n from "i18n";
import { ReactComponent as NotificationIcon } from "assets/notification.svg";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@material-ui/core/Badge";
import { formatMediaUrl } from "utils";

const BootstrapInput = withStyles(() => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "inherit",
    border: "none",
    fontSize: 16,
    padding: "9px 26px 9px 12px",
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#BDBDBD",
    backgroundColor: "#FFFFFF",
    minHeight: "61px",
    position: "sticky",
    top: 0,
    boxShadow: "0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)",
    [theme.breakpoints.down(1200)]: {
      paddingLeft: 0,
    },
  },
  wrap: {
    height: "61px",
    padding: "7px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnLogout: {
    color: "#594BF6",
    marginLeft: "10px",
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "26px",
    cursor: "default",
  },
  drawer: {
    width: "271px",
    flexShrink: 0,
  },
  drawerPaper: {
    width: "271px",
    marginTop: "62px",
  },
  userName: {
    fontSize: 16,
    paddingRight: 10,
    color: "#212529",
  },
  avatarStyles: {
    maxHeight: 24,
    alignItems: "center",
    display: "flex",
    marginLeft: 15,
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  avatar: {
    width: 40,
    height: 40,
    background: "#f5f5f5",
    borderRadius: "100%",
    boxSizing: "border-box",
    position: "relative",
    objectFit: "cover",
  },
  secondarySelect: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 37,
    background: "#ffffff",
    boxShadow:
      "0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%)",
    minWidth: 168,
    maxHeight: 255,
    zIndex: "100",
    cursor: "auto",
    color: "#828282",
    border: "1px solid #d4d4d5",
    borderRadius: 5,
  },
  coreAvatar: {
    margin: "auto",
  },
  notificationButton: {
    position: "relative",
  },
}));

const NavBar = (props) => {
  const classes = useStyles();
  const innerRef = useRef(null);
  const [isOpenNotification, setIsOpenNotification] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  useClickOutside(innerRef, () => toggleSelect(false));
  const [width, setWidth] = useState(window.innerWidth);
  const [driverRight, setDriverRight] = useState(false);
  const [driverLeft, setDriverLeft] = useState(false);
  const [select, toggleSelect] = useState(false);
  const history = useHistory();

  const [hasNotifications, setHasNotifications] = useState(false);

  const { data: eventsData } = useQuery(allEvents, {
    fetchPolicy: "network-only",
  });
  const { data: notificationsData, refetch: refetchNotifications } = useQuery(
    getNotificationsForUser,
    {
      variables: { page: 1, number: 10 },
      fetchPolicy: "network-only",
    },
  );

  const notifications =
    notificationsData?.getNotificationsForUser?.objects || [];

  useEffect(() => {
    if (notificationsData) {
      const hasUnreadNotifications = notifications.some(
        (notification) => !notification.isViewed,
      );
      setHasNotifications(hasUnreadNotifications);
    }
  }, [notificationsData, notifications]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    if (window.innerWidth >= 1200) {
      setDriverRight(false);
      setDriverLeft(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerRight = () => {
    setDriverRight(!driverRight);
  };

  const handleDrawerLeft = () => {
    setDriverLeft(!driverLeft);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    props.history.push(path.SIGN_IN);
    props.client.resetStore();
  };

  const handleOpenModal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
  };

  const pageTitle = () => {
    const pathname = props.location.pathname;
    switch (true) {
      case pathname === path.PROFILE:
        return "Settings > Account";
      case pathname === path.SUBSCRIPTION:
        return "Settings > Subscription";
      case pathname === path.COMPANY:
        return "Settings > Company";
      case pathname === path.EMPLOYEES:
        return "Resources > Employees";
      case pathname === path.TEAMS:
        return "Resources > Teams";
      case pathname === path.EQUIPMENTS:
        return "Resources > Equipments";
      case pathname.startsWith(path.CANDIDATES):
        return "Resources > Candidates";
      case pathname.startsWith(path.JOB_POSTS):
        return "Resources > Job Posts";
      case pathname === path.CALENDAR:
        return "Calendar > Dates";
      case pathname === path.CALENDAR_OVERVIEW:
        return "Calendar > Overview";
      case pathname === path.PROJECTS:
        return "Business > Projects";
      case pathname === path.PROSPECTS:
        return "Business > Prospects";
      case pathname.startsWith(path.LEADS):
        return "Business > Leads";
      case pathname === path.CLIENTS_AND_CONTACTS:
        return "Business > Clients And Contacts";
      case pathname === path.INVOICES:
        return "Business > Invoices";
      case pathname === path.TIMESHEET:
        return "Timesheet";
      case pathname === path.REPORTS_RAW:
        return "Reports > Raw";
      case pathname === path.TEXTS:
        return "Documents > Texts";
      case pathname === path.DIAGRAMS:
        return "Documents > Diagrams";
      case pathname === path.CREATE_DIAGRAM:
        return "Documents > Create diagram";
      case pathname === path.DAILY_WORK:
        return "Daily work";
      case pathname === path.APPROVED_REPORTS:
        return "Reports > Approved reports";
      case pathname === path.WEEKLY_WORK:
        return "Weekly work";
      case pathname === path.PAYMENTS:
        return "Payments";
      case pathname === path.FINANCE_OVERVIEW:
        return "Finance > Overview";
      case pathname === path.FINANCE_INCOME_AND_EXPENSES:
        return "Finance > Income And Expenses";
      case pathname === path.NOTIFICATIONS:
        return "Communication > Notifications";
      case pathname === path.POLLS:
        return "Communication > Polls";
      case pathname === path.REVIEWS:
        return "Communication > Reviews";
      case pathname === path.FINANCE_SALARY:
        return "Finance > Salaries";
      case pathname === path.MAILBOX:
        return "Mailbox";
      case /^\/space\/\d+$/.test(pathname):
        return "Space";
      case pathname === path.DOCUMENTS:
        return "Documents";
      case pathname === path.EMAIL_SYNC:
        return "Email sync";
      case matchPath(pathname, { path: path.PROSPECT_PAGE, exact: true })
        ?.path === path.PROSPECT_PAGE:
        return "Business > Prospects > Prospect";
      default:
        if (pathname.indexOf("/project/") !== -1) {
          return `Detail project / ${props.location.state.projectName}`;
        } else {
          return "";
        }
    }
  };

  const clickNavItem = () => {
    handleDrawerLeft();
  };

  const onClosePopupMenu = () => {
    toggleSelect((prevState) => !prevState);
  };

  const user = props.user.me;
  const avatar = !!user.avatar && formatMediaUrl(user.avatar);
  const userName = `${user.firstName} ${user.lastName}`;

  const avatarElementDiv = avatar ? (
    <img src={avatar} className={classes.avatar} alt="" />
  ) : (
    <Avatar className={classes.coreAvatar} />
  );
  const handleCloseNotification = () => {
    setIsOpenNotification(false);
    localStorage.setItem("notificationClosed", "false");
  };
  useEffect(() => {
    if (user) {
      const endDate = user?.subscription?.endDate;
      if (endDate) {
        const finalDate = moment(endDate);
        const today = moment();
        const diffDays = finalDate.diff(today, "days");
        if (diffDays <= 2) {
          setIsOpenNotification(true);
        } else {
          setIsOpenNotification(false);
        }
      } else {
        setIsOpenNotification(false);
      }
    }
  }, []);
  const getExpiredDays = () => {
    if (user) {
      const endDate = user?.subscription?.endDate;
      if (!endDate) {
        return "Subscription Period Expired!";
      }
      const expiredDays = moment(endDate)?.diff(moment(), "days");
      if (expiredDays <= 2 && expiredDays > 0) {
        return ` ${user?.subscription?.isTrial ? "Trial" : "Subscription"} Period Expiring in ${expiredDays}  Days!`;
      }
      if (expiredDays === 0) {
        return ` ${user?.subscription?.isTrial ? "Trial" : "Subscription"} Period Expiring   Today!`;
      }
      if (expiredDays < 0) {
        return "Subscription Period Expired!";
      }
    }
  };
  const getNotificationText = () => {
    if (user) {
      const endDate = user?.subscription?.endDate;
      if (!endDate) {
        return "Subscription not found!Choose subscription plan to  using the platform";
      }
      const expiredDays = moment(user?.subscription?.endDate)?.diff(
        moment(),
        "days",
      );
      if (expiredDays <= 2 && expiredDays >= 0) {
        return `Your ${user?.subscription?.plan?.duration === 12 ? "Yearly" : "Quarterly"} ${user?.subscription?.isTrial ? "trial" : ""} period is
            coming to an end. To continue enjoying uninterrupted access,
            please proceed to the  `;
      }
      return `Please renew your subscription to continue using the platform`;
    }
  };
  return (
    <AppBar position="static" classes={{ root: classes.appBar }}>
      <div
        className={classes.wrap}
        style={{ marginLeft: props.fullMenu ? "221px" : "91px" }}
      >
        {" "}
        {width <= 1199 && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerLeft}
          >
            <MenuIcon />
          </IconButton>
        )}{" "}
        {isOpenNotification && (
          <Box
            style={{
              color: "black",
              position: "absolute",
              zIndex: 1,
              backgroundColor: "#FFEFEF",
              boxShadow: "0px 0px 10px 0px #00000026",
              padding: "24px 20px 18px",
              width: `calc(100% - ${props.fullMenu ? "221px" : "91px"})`,
              left: props.fullMenu ? "221px" : "91px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <NotificationIcon />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  fontWeight: 600,
                  lineHeight: "27px",
                  textAlign: "center",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                  color: "#F14545",
                }}
              >
                {getExpiredDays()}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "22.5px",
                  textAlign: "left",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                }}
              >
                {" "}
                {getNotificationText()} <strong>Subscription Page.</strong>
              </Typography>
            </Box>
            <ButtonRoot
              style={{
                fontFamily: "Lato",
                fontWeight: "700",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={() => {
                history.push(path.SUBSCRIPTION);
              }}
              text={"Go to Subscription Page"}
            />
            <CloseIcon
              style={{
                height: "25px",
                width: "25px !important",
              }}
              onClick={handleCloseNotification}
            />
          </Box>
        )}
        <Typography className={classes.title}>{i18n.t(pageTitle())}</Typography>
        {width <= 1199 ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerRight}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div className="d-flex align-items-center">
            <IconButton
              className={classes.notificationButton}
              color="inherit"
              aria-label="notifications"
              onClick={handleOpenModal}
            >
              <Badge color="error" variant="dot" invisible={!hasNotifications}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <NotificationModal
              anchorEl={anchorEl}
              onClose={handleCloseModal}
              setHasNotifications={setHasNotifications}
            />
            <div className="position-relative">
              <div onClick={onClosePopupMenu} className={classes.avatarStyles}>
                <Typography className={classes.userName}>{userName}</Typography>
                <span className="pointer">{avatarElementDiv}</span>
              </div>
              {select && (
                <div className={classes.secondarySelect} ref={innerRef}>
                  <div className="text-center pt-3 pb-3">
                    {avatarElementDiv}
                    <p className="mt-2 mb-0 font-weight-bold">{userName}</p>
                    <p className="mb-0 font-weight-normal">
                      {user.jobTitle?.name}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <Select
              variant="outlined"
              id="select"
              value={props.language}
              onChange={props.handleLanguageChange}
              input={<BootstrapInput />}
            >
              <MenuItem value={"en"}>EN</MenuItem>
              <MenuItem value={"ru"}>RU</MenuItem>
            </Select>
          </div>
        )}
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={driverRight}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Links handleLogout={handleLogout} />
      </Drawer>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={driverLeft}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <SideBarLinks click={clickNavItem} user={user} />
      </Drawer>
    </AppBar>
  );
};

export default withApollo(withRouter(NavBar));
