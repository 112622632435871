import { Form, Formik } from "formik";
import SelectAutocomplete from "components/Select/SelectAutocomplete";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
const useStyles = makeStyles((theme) => ({
  successButton: {
    borderColor: "#594BF6",
    color: "#ffffff",
    textTransform: "none",
    border: " 1px solid  #43A762 ",
    backgroundColor: "#594BF6",
    boxShadow: "none",
    "&:hover": { backgroundColor: "#3E3B9F", boxShadow: "none" },
    margin: "8px 8px",
  },
  cancelButton: {
    borderColor: "#594BF6",
    border: " 1px solid  #43A762 ",
    color: "#594BF6",
    textTransform: "none",
    margin: "8px 8px",
  },
  header: {
    marginTop: "10px",
    padding: "4px 15px",
    fontSize: "18px",
    fontWeight: 500,
  },
  form: {
    padding: "0px 15px",
    marginTop: "10px",
  },
  footer: {
    marginTop: "15px",
    borderTop: "2px solid #ced4da",
  },
  root: {
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
  },
}));
const TransferOwnershipForm = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.header}>Transfer ownership</Box>
      <Formik
        initialValues={{
          manager: props?.manager,
        }}
        onSubmit={(variables, form) => {
          props.closeModal();
          props.submitForm(variables);
        }}
        enableReinitialize={true}>
        {({ values, submitForm, setFieldValue }) => (
          <>
            <Form onSubmit={submitForm} className={classes.form}>
              <Box>
                <SelectAutocomplete
                  id="managers"
                  name="managers"
                  placeholder="Transfer Ownership"
                  value={values.manager}
                  setValue={(e) => {
                    setFieldValue("manager", e);
                  }}
                  options={props.managers}
                  disableClearable
                  style={{
                    minWidth: 255,
                  }}
                />
              </Box>
            </Form>
            <Box
              className={classes.footer}
              display="flex"
              justifyContent="flex-end">
              <Button
                className={classes.cancelButton}
                style={{
                  marginRight: "5px",
                }}
                onClick={props.closeModal}>
                Cancel
              </Button>
              <Button
                className={classes.successButton}
                onClick={submitForm}
                type="submit">
                Save
              </Button>
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default TransferOwnershipForm;
