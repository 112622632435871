export const HOME = "/dashboard/daily-work";
export const SIGN_IN = "/login";
export const SIGN_UP = "/signup";
export const INVITATION_SIGN_UP = "/invitation_signup";
export const TIMESHEET = "/dashboard/timesheet";
export const EMPLOYEES = "/dashboard/employees";
export const CALENDAR = "/dashboard/calendar";
export const CALENDAR_OVERVIEW = "/dashboard/calendar-overview";
export const PROFILE = "/dashboard/account_settings";
export const RESET_PASSWORD = "/dashboard/reset-password";
export const PROJECTS = "/dashboard/projects";
export const PROJECT_DETAIL = "/dashboard/project/:name";
export const FORGOT_PASSWORD = "/forgot-password";
export const TEAMS = "/dashboard/teams";
export const TEAM_DETAIL = "/dashboard/team/:id";
export const REPORTS = "/dashboard/reports";
export const REPORTS_RAW = "/dashboard/reports/raw";
export const DOCUMENTS = "/dashboard/documents";
export const DAILY_WORK = "/dashboard/daily-work";
export const WEEKLY_WORK = "/dashboard/weekly-work";
export const EQUIPMENTS = "/dashboard/equipments";
export const CANDIDATES = "/dashboard/candidates";
export const CANDIDATE_INFO = "/dashboard/candidates/:id";
export const PAYMENTS = "/dashboard/payments";
export const SUBSCRIPTION = "/dashboard/subscription";
export const COMPANY = "/dashboard/company";
export const FINANCE = "/dashboard/finance";
export const FINANCE_OVERVIEW = "/dashboard/finance/overview";
export const FINANCE_INCOME_AND_EXPENSES =
  "/dashboard/finance/income-and-expenses";
export const FINANCE_SALARY = "/dashboard/finance/salaries";
export const JOB_POSTS = "/dashboard/job-posts";
export const PROSPECTS = "/dashboard/prospects";
export const JOB_POST_INFO = "/dashboard/job-posts/:id";
export const LEADS = "/dashboard/leads";
export const LEAD_INFO = "/dashboard/leads/:id";
export const CLIENTS_AND_CONTACTS = "/dashboard/clients-and-contacts";
export const NOTIFICATIONS = "/dashboard/notifications";
export const POLLS = "/dashboard/polls";
export const TEXTS = "/dashboard/texts";
export const DIAGRAMS = "/dashboard/diagrams";
export const CREATE_DIAGRAM = "/dashboard/create-diagram";
export const DIAGRAM = "/dashboard/diagram";
export const DIAGRAM_INFO = "/dashboard/diagram/:id";
export const EDIT_DIAGRAM = "/dashboard/edit-diagram";
export const EDIT_DIAGRAM_ID = "/dashboard/edit-diagram/:id";
export const REVIEWS = "/dashboard/reviews";
export const INVOICES = "/dashboard/invoices";
export const REPORTS_OVERVIEW = "/dashboard/reports/overview";
export const REPORTS_DOCUMENTS = "/dashboard/documents/reports";
export const EMAIL_SYNC = "/dashboard/email-sync";
export const INBOX = "/dashboard/inbox";
export const MAILBOX = "/dashboard/mailbox";
export const SPACE = "/dashboard/space/:id";
export const APPROVED_REPORTS = "/dashboard/approved-reports";
export const INVOICE_OVERVIEW = "/dashboard/invoice-overview";
export const ONBOARDING_DASHBOARD = "/dashboard/onboarding-dashboard";
export const ONBOARD_COURSE = "/dashboard/onboarding/course/:id";
export const ONBOARD_COURSES = "/dashboard/onboarding-courses";
export const PROSPECT_PAGE = "/dashboard/prospects/:id";
export const PROCESS_TOKEN = "/process-token-dashboard";
