import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Collapse, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { toggleValue } from "utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  colapseListRow: {
    height: "44px",
    display: "flex",
    padding: "0 20px",
    margin: "4px 0",
    position: "relative",
  },
  colapseListIcon: {
    width: "10%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    color: "#747474",
    marginRight: "15px",
  },
  colapseListValue: {
    fontSize: "16px",
    fontWeight: 400,
    color: "black",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  editBar: {
    paddingRight: "20px",
    backgroundColor: "white",
    border: "1px solid #BDBDBD",
    borderLeft: "0",
    borderRight: "0",
    position: "sticky",
    bottom: "0",
    width: "100%",
    height: "78px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  saveButton: {
    marginLeft: "10px",
    boxShadow: "none",
    backgroundColor: "#2D8647",
    color: "white",
    height: "38px",
    "&:hover": {
      backgroundColor: "#1E603D",
      boxShadow: "none",
    },
  },
  cancelButton: { color: "#979797" },
  list: {
    padding: 0,
    backgroundColor: "white",
    borderLeft: "1px solid #E0E0E0",
    borderRight: "1px solid #E0E0E0",
  },
  underFieldMessage: {
    color: "#333",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    cursor: "pointer",
    position: "absolute",
    bottom: "0%",
    height: "44px",
    display: "flex",
    alignItems: "center",
  },
}));

const EditableCollapsedList = ({
  schema,
  onSubmit,
  handleSubmit,
  setValue,
  values,
}) => {
  const classes = useStyles();

  const [collapsedHeaders, setCollapsedHeaders] = useState([]);
  const [editableHeaders, setEditableHeaders] = useState([]);

  const collapseHandler = (header) => () => {
    toggleValue(setCollapsedHeaders, header, editableHeaders.includes(header));
  };

  const setEditHandler = (header) => (e) => {
    e.stopPropagation();
    toggleValue(setCollapsedHeaders, header, true);
    toggleValue(setEditableHeaders, header);
    if (editableHeaders.includes(header)) return;
    for (let key in values[header]) {
      setValue(key, values[header][key]);
    }
  };

  const closeEditModeHandler = () => {
    setEditableHeaders([]);
  };

  const onSubmitHandler = (data) => {
    onSubmit(data);
    setEditableHeaders([]);
  };

  return (
    <Box className={classes.wrapper}>
      {schema.map((header, index) => (
        <List
          key={header.title}
          className={classes.list}
          style={{
            borderBottom:
              schema.length === index + 1 ? "1px solid #E0E0E0" : "none",
          }}>
          <ListItem button onClick={collapseHandler(header.title)}>
            {!collapsedHeaders.includes(header.title) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
            <ListItemText primary={header.title} />
            <IconButton size="small" onClick={setEditHandler(header.title)}>
              {editableHeaders.includes(header.title) ? (
                <CloseIcon />
              ) : (
                <EditOutlinedIcon />
              )}
            </IconButton>
          </ListItem>
          <Collapse
            in={!collapsedHeaders.includes(header.title)}
            timeout="auto"
            unmountOnExit>
            {!editableHeaders.includes(header.title) ? (
              <div className={classes.colapseList}>
                {header.items.map((item) => (
                  <div
                    key={item.key}
                    className={classes.colapseListRow}
                    style={{
                      height: `${item?.componentHeight || 44}px`,
                    }}>
                    <div className={classes.colapseListIcon}>{item?.icon}</div>
                    <div
                      style={{ paddingLeft: "6px" }}
                      className={classes.colapseListValue}>
                      {item?.isLink && item.component ? (
                        <a
                          target="_blank"
                          style={{ color: "#2F80ED" }}
                          href={item.component}>
                          {item?.component?.length > 30
                            ? `${item.component.slice(0, 30)}...`
                            : item.component}
                        </a>
                      ) : item?.component ? (
                        <div style={{ color: "#212529" }}>{item.component}</div>
                      ) : (
                        <div style={{ color: "#A6A7AB" }}>{item.key}</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <form className={classes.colapseList}>
                {header.items.map((item) => (
                  <div
                    key={item.key}
                    className={classes.colapseListRow}
                    style={{
                      height: `${
                        (item?.editableComponentHeight || 44) +
                        (item?.underFieldMessage ? 44 : 0)
                      }px`,
                      paddingBottom: item?.underFieldMessage ? "44px" : "0px",
                    }}>
                    <div className={classes.colapseListIcon}>{item?.icon}</div>
                    <div className={classes.colapseListValue}>
                      {item.editableComponent || "-"}{" "}
                      {item?.underFieldMessage && item?.editableComponent ? (
                        <div className={classes.underFieldMessage}>
                          <div
                            onMouseDown={item?.underFieldMessageClickHandler}>
                            {item.underFieldMessage}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </form>
            )}
          </Collapse>
        </List>
      ))}
      {editableHeaders.length > 0 &&
        editableHeaders.some(
          (header) => !collapsedHeaders.includes(header)
        ) && (
          <div className={classes.editBar}>
            <Button
              onClick={closeEditModeHandler}
              variant="outlined"
              className={classes.cancelButton}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmitHandler)}
              variant="contained"
              className={classes.saveButton}>
              Save
            </Button>
          </div>
        )}
    </Box>
  );
};

export default EditableCollapsedList;
