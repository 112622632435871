import { styled, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import signupBackground from "assets/signup-background.svg";
import ButtonRoot from "components/Buttons/ButtonRoot";
import ForgotPasswordForm from "pages/ForgotPassword/ForgotPasswordForm";
import MobileForgotPassword from "pages/ForgotPassword/MobileForgotPassword";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-apollo";
import { ReactComponent as Logo } from "assets/TUC_logo.svg";
import { sendResetPasswordEmail } from "api/mutations";
import { makeStyles } from "@material-ui/core/styles";
import * as path from "constants/routes";
import bottomWave from "assets/bottom_wave.svg";
import blobLeft from "assets/blobLeft.svg";
import blobRight from "assets/blobRight.svg";
const StyledCard = styled(Card)({
  background: "white",
  boxShadow: "0px 4px 17px 0px #00000040",
  borderRadius: "10px",
  minHeight: "200px",
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "hidden",
    overflowY: "hidden",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${signupBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#EAF0FA",
    backgroundPositionY: "bottom",
    paddingBottom: "230px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: "100%",
    lineHeight: "28px",
    fontSize: "20px",
    padding: "0px 24px",
    fontFamily: "Lato",
  },
  formContainer: {
    padding: "40px 60px 10px",
    gap: "30px",
    display: "flex",
    flexDirection: "column",
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    padding: "10px",
  },
  inputField: {
    height: "40px",
    "&:before": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0,0,0,0.32)",
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#2D3748",
    textAlign: "center",
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    textTransform: "none",
    height: "48px",
    borderRadius: "6px",
  },
}));

const ForgotPassword = () => {
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendResetPasswordEmailMutation] = useMutation(sendResetPasswordEmail);
  const [userEmail, setUserEmail] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const history = useHistory();
  const sendResetEmail = useCallback(
    async (values, { setErrors }) => {
      const { email } = values;
      const response = await sendResetPasswordEmailMutation({
        variables: {
          email: email,
        },
      });
      if (response.data.sendResetPasswordEmail.success) {
        setUserEmail(email);
        setSendSuccess(true);
      } else {
        setErrors({
          email:
            response?.data?.sendResetPasswordEmail?.errors?.[0] ||
            "Something went wrong",
        });
      }
    },
    [sendResetPasswordEmailMutation],
  );

  return isMobile ? (
    <MobileForgotPassword
      handleSubmit={sendResetEmail}
      initialEmail={userEmail}
      isMobile={isMobile}
      sendSuccess={sendSuccess}
      setSendSuccess={setSendSuccess}
    />
  ) : (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        backgroundImage: `url(${bottomWave}), url(${blobLeft}), url(${blobRight})`,
        backgroundSize: "contain, contain, contain",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundPosition: "bottom, left top, right center",
        backgroundColor: "#fff",
      }}
    >
      {" "}
      <Box
        style={{
          width: "80vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Logo
            onClick={() => (window.location.href = "https://tuc24.com")}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <ButtonRoot
            className={classes.button}
            variant="outlined"
            onClick={() => {
              history.push(path.SIGN_IN);
            }}
            text={"Sign in"}
          />
        </Box>
      </Box>
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {sendSuccess ? (
          <Box className={classes.cardBox}>
            <StyledCard>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "40px 60px 10px",
                  textAlign: "center",
                }}
              >
                <Typography className={classes.text}>
                  A password reset email has been sent to {userEmail}.
                </Typography>
                <Typography
                  className={classes.text}
                  style={{
                    color: "#2F80ED",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSendSuccess(false);
                  }}
                >
                  Resend or change email
                </Typography>
              </Box>
              <Typography
                className={classes.text}
                style={{
                  paddingBottom: "50px",
                  paddingTop: "20px",
                  fontWeight: 500,
                }}
                onClick={() => {
                  history.push(path.SIGN_IN);
                }}
              >
                Sing in
              </Typography>
            </StyledCard>
          </Box>
        ) : (
          <ForgotPasswordForm
            handleSubmit={sendResetEmail}
            initialEmail={userEmail}
          />
        )}
      </Box>
    </Box>
  );
};

export default ForgotPassword;
