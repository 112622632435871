import * as Yup from "yup";
import { MIN_PASSWORD_LENGTH } from "../../constants/index";

export const RegisterValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  email: Yup.string()
    .email("Email is required!")
    .required("Email is required!"),
  password1: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`,
    )
    .required("Password is required!")
    .test("passwords-match", "Passwords dont match match", function (value) {
      const { password2 } = this.parent;
      return !(password2 && value && password2 !== value);
    }),

  password2: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Confirm password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`,
    )
    .required("Confirm password is required!")
    .test("passwords-match", "Passwords dont match match", function (value) {
      const { password1 } = this.parent;
      return !(password1 && value && password1 !== value);
    }),

  companyName: Yup.string().required("Company name is required!"),
  companySite: Yup.string().required("Company web-site is required!"),
  industry: Yup.object().required("Choose industry!").nullable(),
  companySize: Yup.object().required("Choose company size!").nullable(),
});
