import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import ModalContent from "../../Modals/ModalContent";
import { Box, InputLabel } from "@material-ui/core";
import TextEditor from "../../TextEditor/TextEditor";
import { makeStyles } from "@material-ui/core/styles";
import { ModalFooter } from "reactstrap";
import ButtonRoot from "../../Buttons/ButtonRoot";
import TextField from "@material-ui/core/TextField";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AtomicBlockUtils from "draft-js/lib/AtomicBlockUtils";
import {
  createCandidateEmailTemplate,
  createLeadEmailTemplate,
  updateCandidateEmailTemplate,
  updateLeadEmailTemplate,
} from "api/mutations";
import { useApolloClient } from "react-apollo";

const useStyles = makeStyles((theme) => ({
  textEditor: {
    "& .demo-wrapper": {
      display: "flex",
      flexDirection: "column-reverse",
      justifyContent: "start",
      borderTop: "1px solid #D9D9D9",
    },
    "& .rdw-editor-toolbar": {
      margin: 0,
      borderBottom: "1px solid #D9D9D9",
    },
    "& .demo-editor": {
      backgroundColor: "white",
    },
  },
  error: {
    padding: "4px 0 0 14px",
    color: "#f44336",
    fontSize: "0.75rem",
  },
  formRow: {
    border: "1px solid #D9D9D9",
    padding: "4px 15px",
    color: "#747474",
    fontSize: "18px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
  formLabel: { margin: "0 10px 0 0" },
  editorWrapper: {
    border: "solid 1px #efefef",
    "& figure": {
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },
  },
}));

const AddOrEditEmailTemplateForm = (props) => {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [readOnlyEditor, setReadOnlyEditor] = useState(false);
  const apolloClient = useApolloClient();
  const getTemplateMutation = (type) => {
    switch (type) {
      case "candidate":
        return {
          update: updateCandidateEmailTemplate,
          create: createCandidateEmailTemplate,
        };
      case "lead":
        return {
          update: updateLeadEmailTemplate,
          create: createLeadEmailTemplate,
        };
      default:
        return {};
    }
  };

  const templateHandleMutation = async (mutation, variables) => {
    const response = await apolloClient.mutate({
      mutation,
      variables: {
        content: JSON.stringify(
          convertToRaw(variables.letter.getCurrentContent()),
        ),
        templateName: variables.templateName,
        subject: variables.subject,
        templateId: props.templateData?.id,
      },
    });
    props.fetchTemplates();
  };

  const updateTemplateHandler = async (type, variables) => {
    const { update } = getTemplateMutation(type);
    await templateHandleMutation(update, variables);
  };
  const createTemplateHandler = async (type, templateData, variables) => {
    const { create } = getTemplateMutation(type);
    await templateHandleMutation(create, variables);
  };
  const handleInsertBlock = (f, values, setFieldValue) => {
    const contentState = values.letter.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "TEST",
      "MUTABLE",
      { testData: f },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const updatedState = EditorState.push(
      values.letter,
      contentStateWithEntity,
      "insert-fragment",
    );

    const newState = EditorState.set(updatedState, {
      currentContent: contentStateWithEntity,
    });
    const updatedState1 = AtomicBlockUtils.insertAtomicBlock(
      newState,
      entityKey,
      "",
    );
    setFieldValue("letter", updatedState1);
    setMenuAnchor(null);
  };
  const blockRenderer = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "atomic") {
      // const contentState = EditorState.createWithContent(
      //   ContentState.createFromBlockArray([contentBlock]),
      // );
      // const entityKey = contentBlock.getEntityAt(0);
      //
      // if (entityKey) {
      //   const entity = contentState.getCurrentContent().getEntity(entityKey);
      //   const entityType = entity.getType();
      //
      //   if (entityType === "TEST") {
      //     console.log("TEST");
      //     const { testData } = entity.getData();
      return {
        component: (props) => (
          <Field
            name="firstName"
            id="firstName"
            type="text"
            variant="outlined"
            size="small"
            component={TextField}
            placeholder={"firstName"}
            onFocus={() => setReadOnlyEditor(true)}
            onBlur={() => setReadOnlyEditor(false)}
          />
        ),
        editable: true,
      };
    }
    //   }
    // }

    // return null;
  };
  return (
    <Formik
      initialValues={
        props.editMode
          ? {
              subject: props.templateData?.subject || " ",
              templateName: props.templateData?.templateName || " ",
              letter:
                EditorState.createWithContent(
                  convertFromRaw(JSON.parse(props?.templateData?.content)),
                ) || EditorState.createEmpty(),
            }
          : {
              subject: "",
              templateName: "",
              letter: EditorState.createEmpty(),
            }
      }
      onSubmit={(variables, { resetForm }) => {
        props.closeModal();
        if (props.editMode) {
          updateTemplateHandler(props.type, variables);
        } else {
          createTemplateHandler(props.type, props.templateData, variables);
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue, errors, touched }) => (
        <>
          <Form>
            <ModalContent>
              <Box className={classes.formRow}>
                <InputLabel
                  className={classes.formLabel}
                  style={{ marginTop: "2px", width: "15%" }}
                >
                  Template name:
                </InputLabel>
                <Field
                  style={{
                    border: 0,
                    paddingLeft: 0,
                    fontSize: "18px",
                  }}
                  name="templateName"
                  value={values.templateName}
                  onChange={(e) =>
                    setFieldValue("templateName", e.target.value)
                  }
                />
              </Box>
              <Box className={classes.formRow}>
                <InputLabel
                  className={classes.formLabel}
                  style={{ marginTop: "2px", width: "15%" }}
                >
                  Subject:
                </InputLabel>
                <Field
                  style={{
                    border: 0,
                    paddingLeft: 0,
                    fontSize: "18px",
                  }}
                  name="subject"
                  variant="outlined"
                  value={values.subject}
                  onChange={(e) => setFieldValue("subject", e.target.value)}
                />
              </Box>
              <Box className={classes.textEditor}>
                <Box>
                  {/*<button type={"button"}>Insert block</button>*/}
                  {/*<span*/}
                  {/*  id="insertBlock"*/}
                  {/*  onClick={(e) => setMenuAnchor(e.currentTarget)}*/}
                  {/*>*/}
                  {/*  Insert block*/}
                  {/*</span>*/}
                  <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={() => setMenuAnchor(null)}
                  >
                    <MenuItem
                      onClick={() =>
                        handleInsertBlock("firstName", values, setFieldValue)
                      }
                    >
                      First Name
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleInsertBlock("lastName", values, setFieldValue)
                      }
                    >
                      Last Name
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleInsertBlock("date", values, setFieldValue)
                      }
                    >
                      Date
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleInsertBlock("organization", values, setFieldValue)
                      }
                    >
                      Organization
                    </MenuItem>
                  </Menu>
                  <div
                    className={classes.editorWrapper}
                    style={{ border: "solid 1px #EFEFEF" }}
                  >
                    <TextEditor
                      readOnly={readOnlyEditor}
                      changeEditor={(val) => {
                        setFieldValue("letter", val);
                      }}
                      editorState={values.letter}
                      minHeight="140px"
                      blockRendererFn={blockRenderer}
                    />
                  </div>
                </Box>
              </Box>
            </ModalContent>{" "}
          </Form>
          <ModalFooter>
            <Box className="d-flex">
              <ButtonRoot
                text="Close"
                onClick={props?.closeModal}
                type="button"
              />
              <ButtonRoot
                style={{ marginLeft: "5px" }}
                text={"Save"}
                type={"submit"}
                onClick={handleSubmit}
              />
            </Box>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default AddOrEditEmailTemplateForm;
