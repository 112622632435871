import Box from "@material-ui/core/Box";
import authArrowLeft from "../../assets/auth-arrow-left.svg";
import Typography from "@material-ui/core/Typography";
import { ErrorMessage, Form, Formik } from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { InputAdornment } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import * as path from "../../constants/routes";
import ButtonRoot from "../../components/Buttons/ButtonRoot";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import signInBackground from "../../assets/sign-in-background.svg";
import learnMore from "../../assets/learn-more.svg";
const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    overflowX: "hidden",
    width: "100vw",
    height: "100vh",
    backgroundPositionX: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundColor: "#EAF0FA",
    backgroundImage: `url(${signInBackground})`,
  },
  learnMoreSection: {
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "33%",
    backgroundPositionY: "center",
    backgroundPositionX: "center",
    width: "54%",
    backgroundImage: `url(${learnMore})`,
  },
  authFormSection: {
    boxShadow: "4px 0px 17px 0px #594BF61A",
    background: "#FFFFFF",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    fontFamily: '"DM Sans", sans-serif',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.02em",
    textAlign: "center",
    color: "#A3AED0",
    position: "absolute",
    width: "100%",
    bottom: 33,
  },
  header: {
    position: "absolute",
    fontFamily: '"DM Sans", sans-serif',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    color: "#A3AED0",
    top: 29,
    left: 50,
    cursor: "pointer",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const DesktopLogin = ({
  validationSchema,
  handleMouseUpPassword,
  handleMouseDownPassword,
  handleClickShowPassword,
  handleSubmit,
  showPassword,
  isPublic,
  handleContinue,
  validationSchemaWorkspace,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box
        className={classes.authFormSection}
        style={{
          width: "46%",
          minWidth: "475px",
          backgroundImage: "none",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box
          className={classes.header}
          onClick={() => (window.location.href = "https://tuc24.com")}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "5px" }}
              src={`${authArrowLeft}`}
              alt=""
            />
            Back
          </span>
        </Box>
        <Box
          style={{
            width: "62%",
            minHeight: "65%",
            marginBottom: "10%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: 32,
                fontWeight: 600,
                lineHeight: "32px",
                marginBottom: "25px",
                textAlign: "left",
                color: "#2B3674",
              }}
            >
              Sign In
            </Typography>
            <p
              style={{
                fontFamily: '"DM Sans", sans-serif',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "16px",
                textAlign: "left",
                color: "#888B94",
              }}
            >
              Enter your email and password to sign in!
            </p>
          </Box>
          <Formik
            initialValues={{
              username: "",
              password: "",
              workspace: "",
              keepLoggedIn: true,
            }}
            validationSchema={
              isPublic ? validationSchemaWorkspace : validationSchema
            }
            onSubmit={(values, { setErrors }) => {
              handleSubmit(values, { setErrors });
            }}
          >
            {({
              handleChange,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              values,
            }) => (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box marginTop={8} className={classes.formContainer}>
                  {isPublic && (
                    <FormControl
                      style={{ minHeight: "80px" }}
                      fullWidth
                      error={Boolean(errors.workspace && touched.workspace)}
                    >
                      <InputLabel
                        style={{ fontSize: "20px" }}
                        htmlFor="workspace"
                      >
                        Workspace
                      </InputLabel>
                      <Input
                        id="workspace"
                        style={{
                          height: "40px",
                        }}
                        value={values.workspace}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ marginTop: "4px" }}
                      >
                        <ErrorMessage name="workspace" />
                      </Typography>
                    </FormControl>
                  )}
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.username && touched.username)}
                  >
                    <InputLabel style={{ fontSize: "20px" }} htmlFor="username">
                      Email address
                    </InputLabel>
                    <Input
                      id="username"
                      style={{
                        height: "40px",
                      }}
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="username" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.username && touched.username)}
                  >
                    <InputLabel style={{ fontSize: "20px" }} htmlFor="password">
                      Password
                    </InputLabel>
                    <Input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      style={{
                        height: "40px",
                      }}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            onMouseUp={handleMouseUpPassword}
                          >
                            {showPassword ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="password" />
                    </Typography>
                  </FormControl>

                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 14,
                      fontWeight: 400,
                      color: "#2B3674",
                    }}
                  >
                    <Box
                      component="label"
                      htmlFor="keep-logged-in"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        height: "100%",
                        marginBottom: 0,
                      }}
                    >
                      <Checkbox
                        color="primary"
                        style={{
                          padding: 0,
                          marginRight: 5,
                        }}
                        value={values.keepLoggedIn}
                        onChange={(event, checked) =>
                          setFieldValue("keepLoggedIn", checked)
                        }
                      />
                      <Box
                        component="span"
                        style={{
                          fontSize: 14,
                        }}
                      >
                        Keep me logged in
                      </Box>
                    </Box>
                    <Link
                      to={path.FORGOT_PASSWORD}
                      style={{
                        textDecoration: "none",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 14,
                        color: "#0075E1",
                        fontWeight: 400,
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Box>
                </Box>
                <Box marginTop={7}>
                  <Box>
                    <ButtonRoot
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      style={{
                        backgroundColor: "#594BF6",
                        color: "white",
                        fontFamily: '"DM Sans", sans-serif',
                        fontSize: 18,
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "center",
                        borderRadius: "6px",
                        width: "100%",
                        textTransform: "none",
                        marginTop: "1.3vw",
                        height: "48px",
                        transition: "background-color 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#4237C1",
                        },
                      }}
                    >
                      Sign In
                    </ButtonRoot>
                  </Box>
                  {/*<Box*/}
                  {/*  mt={2}*/}
                  {/*  style={{*/}
                  {/*    fontFamily: "Poppins, sans-serif",*/}
                  {/*    fontSize: 14,*/}
                  {/*    fontWeight: 400,*/}
                  {/*    lineHeight: "21px",*/}
                  {/*    textAlign: "left",*/}
                  {/*    color: "#888B94",*/}
                  {/*    marginTop: "10px",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <span>Don't have an account?</span>*/}
                  {/*  <Link*/}
                  {/*    to={path.SIGN_UP}*/}
                  {/*    variant="body2"*/}
                  {/*    style={{*/}
                  {/*      color: "#0075E1",*/}
                  {/*      textDecoration: "none",*/}
                  {/*      marginLeft: "5px",*/}
                  {/*      fontWeight: 500,*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    &nbsp;&nbsp;Sign Up*/}
                  {/*  </Link>*/}
                  {/*</Box>*/}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
        <Box className={classes.footer}>
          © {new Date().getFullYear()} TUC24. All Rights Reserved. Made by
          RockLab
        </Box>
      </Box>
      <Box
        className={classes.learnMoreSection}
        style={{
          display: "flex",
        }}
      />
    </Box>
  );
};
export default DesktopLogin;
