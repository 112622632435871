import React from "react";
import TextFieldComponent from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& input": {
      padding: "0 6px",
      height: "44px",
      fontSize: "16px",
    },
  },
}));

const TextField = ({ fullWidth, register, name, type, style, ...props }) => {
  const classes = useStyles();

  const registerProps = register ? { ...register(name) } : {};

  return (
    <TextFieldComponent
      style={style}
      className={classes.root}
      type={type}
      {...registerProps}
      {...props}
      variant="outlined"
      fullWidth={fullWidth}
    />
  );
};

export default TextField;
