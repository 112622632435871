import gql from "graphql-tag";
import * as fragment from "../fragments/index";

export const CREATE_INCOME_ANDEXPENSES_MUTATION = gql`
  mutation createIncomeAndExpenses(
    $amount: Decimal!
    $currency: String!
    $date: Date!
    $labelId: ID
    $clientId: ID
    $name: String!
    $note: String
    $tag: String
    $typeId: Int!
    $result: Decimal!
    $projectId: ID
    $fee: Decimal!
    $feeType: Int!
    $file: [String]
    $lastDatePayment: Date
    $repeatTypeEnd: Int
    $everyDays: Int
    $afterAmountPayments: Int
    $makeRepeatable: Boolean
    $formatDays: Int
  ) {
    createIncomeAndExpenses(
      amount: $amount
      currency: $currency
      date: $date
      labelId: $labelId
      name: $name
      note: $note
      typeId: $typeId
      projectId: $projectId
      result: $result
      tag: $tag
      file: $file
      clientId: $clientId
      feeType: $feeType
      fee: $fee
      lastDatePayment: $lastDatePayment
      repeatTypeEnd: $repeatTypeEnd
      everyDays: $everyDays
      afterAmountPayments: $afterAmountPayments
      makeRepeatable: $makeRepeatable
      formatDays: $formatDays
    ) {
      success
      errors
    }
  }
`;

export const EDIT_INCOME_ANDEXPENSES_MUTATION = gql`
  mutation editIncomeAndExpenses(
    $instanceId: ID!
    $amount: Decimal!
    $currency: String!
    $date: Date!
    $labelId: ID
    $name: String!
    $note: String
    $typeId: Int!
    $tag: String
    $result: Decimal!
    $projectId: ID
    $fee: Decimal!
    $feeType: Int!
    $file: [String]
    $clientId: ID
    $everyDays: Int
    $afterAmountPayments: Int
    $formatDays: Int
    $lastDatePayment: Date
    $makeRepeatable: Boolean
    $repeatTypeEnd: Int
    $repeatPaymentsId: ID
  ) {
    editIncomeAndExpenses(
      instanceId: $instanceId
      amount: $amount
      currency: $currency
      date: $date
      labelId: $labelId
      name: $name
      note: $note
      typeId: $typeId
      tag: $tag
      result: $result
      projectId: $projectId
      file: $file
      fee: $fee
      feeType: $feeType
      clientId: $clientId
      everyDays: $everyDays
      afterAmountPayments: $afterAmountPayments
      formatDays: $formatDays
      lastDatePayment: $lastDatePayment
      makeRepeatable: $makeRepeatable
      repeatPaymentsId: $repeatPaymentsId
      repeatTypeEnd: $repeatTypeEnd
    ) {
      success
      errors
    }
  }
`;

export const DELETE_INCOME_AND_EXPENSES_MUTATION = gql`
  mutation deleteIncomeAndExpenses($instanceId: Int!) {
    deleteIncomeAndExpenses(instanceId: $instanceId) {
      success
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($username: String!, $password: String!, $workspace: String) {
    login(username: $username, password: $password, workspace: $workspace) {
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
      token
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const register = gql`
  mutation register(
    $email: String!
    $firstName: String
    $lastName: String
    $password1: String!
    $password2: String!
    $isAdmin: Boolean
    $dateJoined: DateTime!
    $companyName: String!
    $companySize: Int!
    $industry: Int!
    $companySite: String!
  ) {
    register(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password1: $password1
      password2: $password2
      isAdmin: $isAdmin
      dateJoined: $dateJoined
      companyName: $companyName
      companySize: $companySize
      industry: $industry
      companySite: $companySite
    ) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;
export const registerByInvitation = gql`
  mutation registerByInvitation(
    $firstName: String!
    $lastName: String!
    $password1: String!
    $password2: String!
    $dateJoined: Date!
    $token: String!
  ) {
    registerByInvitation(
      firstName: $firstName
      lastName: $lastName
      password1: $password1
      password2: $password2
      dateJoined: $dateJoined
      token: $token
    ) {
      success
      errors
      token
    }
  }
`;

export const verifyToken = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const confirmEmail = gql`
  mutation confirmEmail($email: String!) {
    confirmEmail(email: $email) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const resetPassword = gql`
  mutation resetPassword(
    $newPassword1: String!
    $newPassword2: String!
    $token: String!
  ) {
    resetPassword(
      input: {
        newPassword1: $newPassword1
        newPassword2: $newPassword2
        token: $token
      }
    ) {
      errors
      success
    }
  }
`;

export const changeProjectStatus = gql`
  mutation changeProjectStatus($input: ProjectStatusInput!) {
    changeProjectStatus(input: $input) {
      ok
      errors
    }
  }
`;

export const calculateWorkDay = gql`
  mutation calculateWorkDay(
    $projectId: ID!
    $seconds: Float!
    $date: Date!
    $sessionId: String!
    $timestamp: String!
  ) {
    calculateWorkDay(
      projectId: $projectId
      seconds: $seconds
      date: $date
      sessionId: $sessionId
      timestamp: $timestamp
    ) {
      ok
      errors
      statistics {
        userStatistics
      }
    }
  }
`;

export const addManualTimeToUserReport = gql`
  mutation addManualTimeToUserReport(
    $date: Date!
    $newManualTime: Time!
    $projectId: Int!
    $userId: Int!
  ) {
    addManualTimeToUserReport(
      date: $date
      newManualTime: $newManualTime
      projectId: $projectId
      userId: $userId
    ) {
      errors
      success
    }
  }
`;

export const removeManuallyTimeFromUserReport = gql`
  mutation removeManuallyTimeFromUserReport($day: Int!, $userId: ID!) {
    removeManuallyTimeFromUserReport(day: $day, userId: $userId) {
      errors
      ok
    }
  }
`;

export const updateUserManuallyTime = gql`
  mutation updateUserManuallyTime(
    $input: ManualTimeInput!
    $operation: String
  ) {
    updateUserManuallyTime(input: $input, operation: $operation) {
      ok
      errors
      report {
        ...DailyReport
      }
    }
  }
  ${fragment.DailyReportFragment}
`;

export const createUser = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $jobTitle: Int!
    $dateJoined: DateTime!
    $team: Int!
    $avatar: String
    $status: Int!
    $phone: String!
    $birthday: Date
    $role: Int!
    $programmingLangsAndSpecializations: Int!
    $englishLevel: ID
    $files: [[String]]
    $equipments: [Int]
    $additionalInformation: String
    $password1: String!
    $password2: String!
    $department: ID
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      dateJoined: $dateJoined
      team: $team
      avatar: $avatar
      status: $status
      phone: $phone
      birthday: $birthday
      role: $role
      programmingLangsAndSpecializations: $programmingLangsAndSpecializations
      englishLevel: $englishLevel
      files: $files
      equipments: $equipments
      additionalInformation: $additionalInformation
      password1: $password1
      password2: $password2
      department: $department
    ) {
      success
      errors
      user {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const updateAvatarUser = gql`
  mutation updateAvatarUser($email: String!, $avatar: String) {
    updateAvatarUser(email: $email, avatar: $avatar) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const editUser = gql`
  mutation editUser(
    $userId: Int!
    $email: String!
    $firstName: String!
    $lastName: String!
    $jobTitle: Int!
    $workingTime: Time
    $dateJoined: DateTime!
    $team: Int!
    $avatar: String
    $status: Int!
    $startTime: Time
    $phone: String!
    $birthday: Date!
    $role: Int!
    $programmingLangsAndSpecializations: Int!
    $englishLevel: ID
    $files: GenericScalar!
    $equipments: [Int]!
    $additionalInformation: String
    $password1: String
    $password2: String
    $department: ID
  ) {
    editUser(
      userId: $userId
      email: $email
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      workingTime: $workingTime
      dateJoined: $dateJoined
      team: $team
      avatar: $avatar
      status: $status
      startTime: $startTime
      phone: $phone
      birthday: $birthday
      role: $role
      programmingLangsAndSpecializations: $programmingLangsAndSpecializations
      englishLevel: $englishLevel
      files: $files
      equipments: $equipments
      additionalInformation: $additionalInformation
      password1: $password1
      password2: $password2
      department: $department
    ) {
      success
      errors
      user {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const deleteUser = gql`
  mutation archiveUser($userId: Int!) {
    archiveUser(userId: $userId) {
      success
      errors
    }
  }
`;

export const createProject = gql`
  mutation createProject(
    $name: String!
    $status: Int!
    $team: Int!
    $avatar: String
    $projectType: Int!
    $client: Int
    $projectManager: Int
  ) {
    createProject(
      name: $name
      status: $status
      team: $team
      avatar: $avatar
      projectType: $projectType
      client: $client
      projectManager: $projectManager
    ) {
      success
      errors
    }
  }
`;

export const editOutsourceProject = gql`
  mutation editOutsourceProject(
    $avatar: String
    $client: String!
    $name: String!
    $projectId: Int!
    $projectManager: Int!
    $status: Int!
    $team: Int!
  ) {
    editOutsourceProject(
      avatar: $avatar
      client: $client
      name: $name
      projectId: $projectId
      projectManager: $projectManager
      status: $status
      team: $team
    ) {
      success
      errors
    }
  }
`;

export const editProductProject = gql`
  mutation editProductProject(
    $avatar: String
    $productManager: Int!
    $name: String!
    $projectId: Int!
    $projectManager: Int!
    $status: Int!
    $team: Int!
  ) {
    editProductProject(
      avatar: $avatar
      productManager: $productManager
      name: $name
      projectId: $projectId
      projectManager: $projectManager
      status: $status
      team: $team
    ) {
      success
      errors
    }
  }
`;

export const editActivityProject = gql`
  mutation editActivityProject(
    $avatar: String
    $name: String!
    $projectId: Int!
    $team: Int!
  ) {
    editActivityProject(
      avatar: $avatar
      name: $name
      projectId: $projectId
      team: $team
    ) {
      success
      errors
    }
  }
`;

export const deleteProject = gql`
  mutation deleteProject($projectId: Int!) {
    deleteProject(projectId: $projectId) {
      success
      errors
    }
  }
`;

export const createTeam = gql`
  mutation createTeam(
    $name: String!
    $members: [Int]!
    $projects: [Int]!
    $teamLead: Int
    $technologies: [Int]!
  ) {
    createTeam(
      name: $name
      members: $members
      projects: $projects
      teamLead: $teamLead
      technologies: $technologies
    ) {
      success
      errors
    }
  }
`;

export const editTeam = gql`
  mutation editTeam(
    $teamId: Int!
    $name: String!
    $members: [Int]!
    $projects: [Int]!
    $teamLead: Int
    $technologies: [Int]!
  ) {
    editTeam(
      teamId: $teamId
      name: $name
      members: $members
      projects: $projects
      teamLead: $teamLead
      technologies: $technologies
    ) {
      success
      errors
    }
  }
`;

export const deleteTeam = gql`
  mutation removeTeam($teamId: Int!) {
    removeTeam(teamId: $teamId) {
      success
      errors
    }
  }
`;

export const createJobTitle = gql`
  mutation createJobTitle($name: String, $company: ID!) {
    createJobTitle(name: $name, company: $company) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const completeWorkingDay = gql`
  mutation completeWorkingDay($dateParam: Date!, $userId: ID!, $projId: ID!) {
    completeWorkingDay(
      dateParam: $dateParam
      userId: $userId
      projId: $projId
    ) {
      success
      errors
    }
  }
`;

export const changePassword = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    ) {
      success
      errors
    }
  }
`;

export const inviteEmploy = gql`
  mutation inviteEmploy(
    $email: Email!
    $jobTitle: ID!
    $programmingLangsAndSpecialization: ID
    $role: ID!
    $team: ID!
    $fields: [String]
  ) {
    inviteEmploy(
      email: $email
      jobTitle: $jobTitle
      programmingLangsAndSpecialization: $programmingLangsAndSpecialization
      role: $role
      team: $team
      fields: $fields
    ) {
      ok
      errors
    }
  }
`;

export const editCompany = gql`
  mutation editCompany(
    $id: ID!
    $name: String!
    $siteUrl: String!
    $industry: Int
    $size: Int
    $avatar: String
    $email: String
    $legalNumber: String
    $phone: String
  ) {
    editCompany(
      id: $id
      name: $name
      siteUrl: $siteUrl
      industry: $industry
      size: $size
      avatar: $avatar
      phone: $phone
      legalNumber: $legalNumber
      email: $email
    ) {
      success
      errors
    }
  }
`;

export const updateAvatarClient = gql`
  mutation updateAvatarClient($email: String!, $avatar: String) {
    updateAvatarClient(email: $email, avatar: $avatar) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const updateTechnologies = gql`
  mutation updateProjectTechnologies($projectId: Int!, $technologies: [Int]!) {
    updateProjectTechnologies(
      projectId: $projectId
      technologies: $technologies
    ) {
      success
      errors
    }
  }
`;

export const updateTeam = gql`
  mutation updateProjectTeam($projectId: Int!, $members: [Int]!) {
    updateProjectTeam(projectId: $projectId, members: $members) {
      success
      errors
    }
  }
`;

export const updateReport = gql`
  mutation updateReport(
    $reportId: Int
    $userId: Int
    $projectId: Int
    $dateParam: Date
    $reportPoints: [ReportPointInput]
  ) {
    updateReport(
      reportId: $reportId
      userId: $userId
      projectId: $projectId
      dateParam: $dateParam
      reportPoints: $reportPoints
    ) {
      success
      reports {
        id
        text
        spendTime
      }
      errors
    }
  }
`;
export const updateProjectFile = gql`
  mutation updateProjectFiles($file: [String]!, $projectId: Int!) {
    updateProjectFiles(file: $file, projectId: $projectId) {
      success
      errors
    }
  }
`;

export const deleteProjectFile = gql`
  mutation deleteProjectFile($projectFileId: Int!) {
    deleteProjectFile(projectFileId: $projectFileId) {
      success
      errors
    }
  }
`;

export const createProjectDocument = gql`
  mutation createProjectDocument($project: ID!, $title: String, $text: String) {
    createProjectDocument(project: $project, title: $title, text: $text) {
      success
      error {
        __typename
        ... on ValidationErrors {
          validationErrors {
            field
            messages
          }
        }
      }
    }
  }
`;

export const editProjectDocument = gql`
  mutation editProjectDocument(
    $documentId: Int!
    $project: Int!
    $title: String!
    $text: String
  ) {
    editProjectDocument(
      documentId: $documentId
      project: $project
      title: $title
      text: $text
    ) {
      success
      projectDocument {
        id
        title
        text
        project {
          id
          name
        }
      }
      errors
    }
  }
`;

export const deleteProjectDocument = gql`
  mutation deleteProjectDocument($projectDocumentId: Int!) {
    deleteProjectDocument(projectDocumentId: $projectDocumentId) {
      success
      errors
    }
  }
`;

export const createProjectNote = gql`
  mutation createProjectNote(
    $project: Int!
    $title: String!
    $text: JSONString!
    $author: Int!
    $editableFor: [Int]!
    $privateMode: Boolean!
    $publicMode: Boolean!
    $visibleFor: [Int]!
  ) {
    createProjectNote(
      project: $project
      title: $title
      text: $text
      author: $author
      editableFor: $editableFor
      privateMode: $privateMode
      publicMode: $publicMode
      visibleFor: $visibleFor
    ) {
      success
      errors
    }
  }
`;

export const editProjectNote = gql`
  mutation editProjectNote(
    $note: Int!
    $title: String!
    $text: JSONString!
    $lastEditUser: Int!
    $editableFor: [Int]!
    $privateMode: Boolean!
    $publicMode: Boolean!
    $visibleFor: [Int]!
  ) {
    editProjectNote(
      note: $note
      title: $title
      text: $text
      lastEditUser: $lastEditUser
      editableFor: $editableFor
      privateMode: $privateMode
      publicMode: $publicMode
      visibleFor: $visibleFor
    ) {
      success
      errors
    }
  }
`;

export const deleteProjectNote = gql`
  mutation deleteProjectNote($noteId: Int!) {
    deleteProjectNote(noteId: $noteId) {
      success
      errors
    }
  }
`;

export const removeManualTime = gql`
  mutation removeManualTime(
    $date: Date!
    $deleteAllTime: Boolean!
    $employee: ID!
    $projectId: ID!
    $timeSec: Int!
    $timeType: String!
  ) {
    removeManualTime(
      date: $date
      deleteAllTime: $deleteAllTime
      employee: $employee
      projectId: $projectId
      timeSec: $timeSec
      timeType: $timeType
    ) {
      ok
      errors
    }
  }
`;

export const CREATE_EQUIPMENT_MUTATION = gql`
  mutation createEquipment(
    $manufacturer: String
    $modelName: String!
    $notes: String
    $photoFiles: [String]!
    $serialNumber: String
    $type: Int!
    $userId: Int
  ) {
    createEquipment(
      manufacturer: $manufacturer
      modelName: $modelName
      notes: $notes
      photoFiles: $photoFiles
      serialNumber: $serialNumber
      type: $type
      userId: $userId
    ) {
      success
      errors
    }
  }
`;

export const EDIT_EQUIPMENT_MUTATION = gql`
  mutation editEquipment(
    $equipmentId: Int!
    $manufacturer: String
    $modelName: String!
    $notes: String
    $photos: GenericScalar!
    $serialNumber: String
    $type: Int!
    $userId: Int
  ) {
    editEquipment(
      equipmentId: $equipmentId
      manufacturer: $manufacturer
      modelName: $modelName
      notes: $notes
      photos: $photos
      serialNumber: $serialNumber
      type: $type
      userId: $userId
    ) {
      success
      errors
    }
  }
`;

export const DELETE_EQUIPMENT_MUTATION = gql`
  mutation deleteEquipment($equipmentId: Int!) {
    deleteEquipment(equipmentId: $equipmentId) {
      success
      errors
    }
  }
`;

export const CHANGE_USER_TIMES = gql`
  mutation changeUserTimesForProjectAndDate(
    $date: Date!
    $newManualTime: Time
    $newSpentTime: Time
    $newTrackedTime: Time
    $projectId: Int!
    $userId: Int!
  ) {
    changeUserTimesForProjectAndDate(
      date: $date
      newManualTime: $newManualTime
      newSpentTime: $newSpentTime
      newTrackedTime: $newTrackedTime
      projectId: $projectId
      userId: $userId
    ) {
      success
      errors
    }
  }
`;

export const createHoliday = gql`
  mutation createHoliday($date: Date!, $title: String!) {
    createHoliday(date: $date, title: $title) {
      success
      errors
    }
  }
`;

export const editHoliday = gql`
  mutation editHoliday($date: Date!, $title: String!, $holidayId: Int!) {
    editHoliday(date: $date, title: $title, holidayId: $holidayId) {
      success
      errors
    }
  }
`;

export const deleteHoliday = gql`
  mutation deleteHoliday($holidayId: Int!) {
    deleteHoliday(holidayId: $holidayId) {
      success
      errors
    }
  }
`;

export const createEvent = gql`
  mutation createEvent(
    $dateStart: Date!
    $dateEnd: Date!
    $type: Int!
    $user: Int!
  ) {
    createEvent(
      dateStart: $dateStart
      dateEnd: $dateEnd
      type: $type
      user: $user
    ) {
      success
      errors
    }
  }
`;

export const editEvent = gql`
  mutation editEvent(
    $dateStart: Date!
    $dateEnd: Date!
    $type: Int!
    $user: Int!
    $eventId: Int!
  ) {
    editEvent(
      dateStart: $dateStart
      dateEnd: $dateEnd
      type: $type
      user: $user
      eventId: $eventId
    ) {
      success
      errors
    }
  }
`;

export const deleteEvent = gql`
  mutation deleteEvent($eventId: Int!) {
    deleteEvent(eventId: $eventId) {
      success
      errors
    }
  }
`;

export const changeStatusForEvent = gql`
  mutation changeStatusForEvent($eventId: Int!, $status: Boolean!) {
    changeStatusForEvent(eventId: $eventId, status: $status) {
      success
      errors
    }
  }
`;

export const createNewDocument = gql`
  mutation createNewDocument(
    $content: JSONString!
    $editableFor: [ID]
    $file: Upload
    $project: ID
    $public: Boolean
    $title: String!
    $userId: ID!
    $visibleFor: [ID]
  ) {
    createNewDocument(
      content: $content
      editableFor: $editableFor
      file: $file
      project: $project
      public: $public
      title: $title
      userId: $userId
      visibleFor: $visibleFor
    ) {
      success
      errors
    }
  }
`;

export const updateDocument = gql`
  mutation updateDocument(
    $content: JSONString
    $editableFor: [ID]
    $project: ID
    $public: Boolean
    $title: String
    $id: ID!
    $visibleFor: [ID]
  ) {
    updateDocument(
      content: $content
      editableFor: $editableFor
      project: $project
      public: $public
      title: $title
      id: $id
      visibleFor: $visibleFor
    ) {
      success
      errors
    }
  }
`;

export const deleteDocument = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) {
      success
      errors
    }
  }
`;

export const editClient = gql`
  mutation editClient(
    $archived: Boolean
    $clientId: ID!
    $currency: String
    $website: String
    $value: Float
    $source: ID
    $skype: String
    $project: ID
    $phone: String
    $ownerId: ID
    $organization: String
    $linkedin: String
    $lastName: String
    $keyNeed: String
    $industry: ID
    $firstName: String
    $facebook: String
    $email: String
  ) {
    editClient(
      archived: $archived
      clientId: $clientId
      currency: $currency
      email: $email
      facebook: $facebook
      firstName: $firstName
      industry: $industry
      keyNeed: $keyNeed
      lastName: $lastName
      linkedin: $linkedin
      organization: $organization
      ownerId: $ownerId
      phone: $phone
      project: $project
      skype: $skype
      source: $source
      value: $value
      website: $website
    ) {
      errors
      success
    }
  }
`;

export const createClient = gql`
  mutation createClient(
    $currency: String
    $country: String
    $city: String
    $state: String
    $address2: String
    $address1: String
    $contactPerson: ID!
    $email: String!
    $facebook: String
    $industry: ID
    $keyNeed: String
    $linkedin: String
    $organization: String!
    $phone: String!
    $project: ID
    $skype: String
    $source: ID
    $value: Float
    $website: String
  ) {
    createClient(
      website: $website
      value: $value
      source: $source
      skype: $skype
      project: $project
      organization: $organization
      linkedin: $linkedin
      phone: $phone
      keyNeed: $keyNeed
      industry: $industry
      facebook: $facebook
      email: $email
      country: $country
      contactPerson: $contactPerson
      currency: $currency
      address2: $address2
      address1: $address1
      city: $city
      state: $state
    ) {
      errors
      success
      clientId
    }
  }
`;

export const deleteClient = gql`
  mutation deleteClient($clientId: ID!) {
    deleteClient(clientId: $clientId) {
      success
      errors
    }
  }
`;

export const editContactPerson = gql`
  mutation editContactPerson(
    $city: String
    $contactPersonId: ID!
    $country: String
    $facebook: String
    $firstName: String!
    $lastName: String!
    $linkedin: String
    $notes: String
    $phone: String
    $email: String
    $position: String!
    $skype: String
  ) {
    editContactPerson(
      city: $city
      contactPersonId: $contactPersonId
      country: $country
      facebook: $facebook
      firstName: $firstName
      lastName: $lastName
      linkedin: $linkedin
      notes: $notes
      phone: $phone
      email: $email
      position: $position
      skype: $skype
    ) {
      success
      errors
    }
  }
`;

export const createContactPerson = gql`
  mutation createContactPerson(
    $city: String
    $country: String
    $facebook: String
    $firstName: String!
    $lastName: String!
    $linkedin: String
    $notes: String
    $phone: String
    $email: String
    $position: String
    $skype: String
  ) {
    createContactPerson(
      city: $city
      country: $country
      facebook: $facebook
      firstName: $firstName
      lastName: $lastName
      linkedin: $linkedin
      notes: $notes
      phone: $phone
      email: $email
      position: $position
      skype: $skype
    ) {
      success
      errors
      contact {
        firstName
        lastName
        id
      }
    }
  }
`;

export const deleteContactPerson = gql`
  mutation deleteContactPerson($contactPersonId: ID!) {
    deleteContactPerson(contactPersonId: $contactPersonId) {
      success
      errors
    }
  }
`;

export const editProject = gql`
  mutation editProject(
    $avatar: String
    $client: String
    $files: GenericScalar!
    $name: String!
    $projectId: Int!
    $projectType: Int!
    $status: Int!
    $technologies: [Int]!
    $employees: [ProjectEmployeesInputType]
  ) {
    editProject(
      avatar: $avatar
      client: $client
      files: $files
      name: $name
      projectId: $projectId
      projectType: $projectType
      status: $status
      technologies: $technologies
      employees: $employees
    ) {
      success
      errors
    }
  }
`;

export const editUserAccount = gql`
  mutation editUserAccount(
    $avatar: String
    $firstName: String!
    $lastName: String!
    $phone: String!
    $userId: Int!
    $birthday: Date
  ) {
    editUserAccount(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      userId: $userId
      birthday: $birthday
    ) {
      success
      errors
    }
  }
`;

export const createDepartment = gql`
  mutation createDepartment(
    $name: String!
    $location: Int!
    $avatar: String
    $address: String
  ) {
    createDepartment(
      name: $name
      location: $location
      avatar: $avatar
      address: $address
    ) {
      success
      errors
    }
  }
`;

export const editDepartment = gql`
  mutation editDepartment(
    $id: ID!
    $name: String!
    $location: Int
    $avatar: String
    $address: String
  ) {
    editDepartment(
      id: $id
      name: $name
      location: $location
      avatar: $avatar
      address: $address
    ) {
      success
      errors
    }
  }
`;

export const deleteDepartment = gql`
  mutation deleteDepartment($id: ID!) {
    deleteDepartment(id: $id) {
      success
      errors
    }
  }
`;

export const deleteLead = gql`
  mutation deleteLead($id: Int!) {
    deleteLead(id: $id) {
      success
      errors
    }
  }
`;

export const createPoll = gql`
  mutation createPoll(
    $choices: [String]!
    $question: String!
    $startDate: Date
    $endDate: Date
    $isAnonymous: Boolean
    $isMultipleChoice: Boolean
    $isUsersAddOption: Boolean
  ) {
    createPoll(
      choices: $choices
      question: $question
      startDate: $startDate
      endDate: $endDate
      isAnonymous: $isAnonymous
      isMultipleChoice: $isMultipleChoice
      isUsersAddOption: $isUsersAddOption
    ) {
      success
      errors
    }
  }
`;

export const voteInPoll = gql`
  mutation voteInPoll($choiceId: ID, $pollId: ID!, $answer: String) {
    voteInPoll(choiceId: $choiceId, pollId: $pollId, answer: $answer) {
      success
      errors
    }
  }
`;

export const deletePoll = gql`
  mutation deletePoll($pollId: ID!) {
    deletePoll(pollId: $pollId) {
      success
      errors
    }
  }
`;

export const editPoll = gql`
  mutation editPoll(
    $choices: [EditChoiceType]!
    $endDate: Date
    $pollId: ID!
    $question: String!
    $startDate: Date
    $isAnonymous: Boolean
    $isMultipleChoice: Boolean
    $isUsersAddOption: Boolean
  ) {
    editPoll(
      choices: $choices
      question: $question
      startDate: $startDate
      endDate: $endDate
      pollId: $pollId
      isUsersAddOption: $isUsersAddOption
      isMultipleChoice: $isMultipleChoice
      isAnonymous: $isAnonymous
    ) {
      success
      errors
    }
  }
`;

export const retractVote = gql`
  mutation retractVote($pollId: ID!) {
    retractVote(pollId: $pollId) {
      success
      errors
    }
  }
`;

export const createRecurringNotification = gql`
  mutation createRecurringNotification(
    $endDate: Date!
    $frequencyType: Int!
    $frequencyValue: Int!
    $name: String!
    $startDate: Date!
    $targetUsers: [ID]!
    $time: Time!
    $title: String!
  ) {
    createRecurringNotification(
      endDate: $endDate
      frequencyType: $frequencyType
      frequencyValue: $frequencyValue
      name: $name
      startDate: $startDate
      targetUsers: $targetUsers
      time: $time
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const editRecurringNotification = gql`
  mutation editRecurringNotification(
    $endDate: Date!
    $frequencyType: Int!
    $frequencyValue: Int!
    $name: String!
    $startDate: Date!
    $targetUsers: [ID]!
    $time: Time!
    $title: String!
    $id: ID!
  ) {
    editRecurringNotification(
      endDate: $endDate
      frequencyType: $frequencyType
      frequencyValue: $frequencyValue
      name: $name
      startDate: $startDate
      targetUsers: $targetUsers
      time: $time
      title: $title
      id: $id
    ) {
      success
      errors
    }
  }
`;

export const deleteRecurringNotification = gql`
  mutation deleteRecurringNotification($id: ID!) {
    deleteRecurringNotification(id: $id) {
      success
      errors
    }
  }
`;

export const createCustomNotification = gql`
  mutation createCustomNotification(
    $releaseDatetime: DateTime!
    $name: String!
    $targetUsers: [ID]!
    $title: String!
  ) {
    createCustomNotification(
      releaseDatetime: $releaseDatetime
      name: $name
      targetUsers: $targetUsers
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const editCustomNotification = gql`
  mutation editCustomNotification(
    $id: ID!
    $releaseDatetime: DateTime!
    $name: String!
    $targetUsers: [ID]!
    $title: String!
  ) {
    editCustomNotification(
      id: $id
      releaseDatetime: $releaseDatetime
      name: $name
      targetUsers: $targetUsers
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const deleteCustomNotification = gql`
  mutation deleteCustomNotification($id: ID!) {
    deleteCustomNotification(id: $id) {
      success
      errors
    }
  }
`;

export const changeCompanyNotificationSetting = gql`
  mutation changeCompanyNotificationSetting(
    $settingType: Int!
    $settingValue: Boolean!
  ) {
    changeCompanyNotificationSetting(
      settingType: $settingType
      settingValue: $settingValue
    ) {
      success
      errors
    }
  }
`;

export const createFilledReview = gql`
  mutation createFilledReview(
    $adherenceToWorkingSchedule: Decimal!
    $communication: Decimal!
    $cooperation: Decimal!
    $feedback: String!
    $fromUser: ID!
    $general: Int!
    $meetsDeadlines: Decimal!
    $qualityOfWork: Decimal!
    $skills: Decimal!
    $toUser: ID!
  ) {
    createFilledReview(
      adherenceToWorkingSchedule: $adherenceToWorkingSchedule
      communication: $communication
      cooperation: $cooperation
      feedback: $feedback
      fromUser: $fromUser
      general: $general
      meetsDeadlines: $meetsDeadlines
      qualityOfWork: $qualityOfWork
      skills: $skills
      toUser: $toUser
    ) {
      success
      errors
    }
  }
`;

export const createRequestedReview = gql`
  mutation createRequestedReview($fromUser: ID!, $toUser: ID!) {
    createRequestedReview(fromUser: $fromUser, toUser: $toUser) {
      success
      errors
    }
  }
`;

export const fillRequestedReview = gql`
  mutation fillRequestedReview(
    $adherenceToWorkingSchedule: Decimal!
    $communication: Decimal!
    $cooperation: Decimal!
    $feedback: String!
    $general: Int!
    $meetsDeadlines: Decimal!
    $qualityOfWork: Decimal!
    $skills: Decimal!
    $id: ID!
  ) {
    fillRequestedReview(
      adherenceToWorkingSchedule: $adherenceToWorkingSchedule
      communication: $communication
      cooperation: $cooperation
      feedback: $feedback
      general: $general
      meetsDeadlines: $meetsDeadlines
      qualityOfWork: $qualityOfWork
      skills: $skills
      id: $id
    ) {
      success
      errors
    }
  }
`;

export const deleteReview = gql`
  mutation deleteReview($id: ID!) {
    deleteReview(id: $id) {
      success
      errors
    }
  }
`;

export const createDiagram = gql`
  mutation createDiagram($body: String!, $title: String!) {
    createDiagram(body: $body, title: $title) {
      success
      errors
    }
  }
`;

export const updateDiagram = gql`
  mutation updateDiagram($id: ID!, $body: String!, $title: String!) {
    updateDiagram(id: $id, body: $body, title: $title) {
      success
      errors
    }
  }
`;

export const deleteDiagram = gql`
  mutation deleteDiagram($id: ID!) {
    deleteDiagram(id: $id) {
      success
      errors
    }
  }
`;

export const createSalary = gql`
  mutation createSalary(
    $userId: ID!
    $salaryReview: ID!
    $salary: Decimal!
    $paidDate: Date!
    $currency: String!
    $bonusAmount: Decimal!
  ) {
    createSalary(
      currency: $currency
      bonusAmount: $bonusAmount
      salary: $salary
      salaryReview: $salaryReview
      userId: $userId
      paidDate: $paidDate
    ) {
      errors
      success
    }
  }
`;

export const editSalary = gql`
  mutation editSalary(
    $userId: ID!
    $salaryReview: ID!
    $salary: Decimal!
    $payoutAmount: Decimal!
    $paidDate: Date!
    $id: ID!
    $currency: String!
    $bonusAmount: Decimal!
  ) {
    editSalary(
      bonusAmount: $bonusAmount
      currency: $currency
      id: $id
      paidDate: $paidDate
      payoutAmount: $payoutAmount
      salary: $salary
      salaryReview: $salaryReview
      userId: $userId
    ) {
      errors
      success
    }
  }
`;

export const deleteSalary = gql`
  mutation deleteSalary($id: ID!) {
    deleteSalary(id: $id) {
      success
      errors
    }
  }
`;

export const createInvoice = gql`
  mutation createInvoice(
    $clientName: String!
    $companyName: String!
    $letter: String
    $currency: String!
    $invoiceDate: Date!
    $invoiceTitle: String!
    $logo: String
    $logoWidth: Int
    $name: String!
    $notes: String
    $productLines: [NewInvoiceProductLineInputType]
    $tax: Decimal!
    $term: String
    $projectId: ID
    $clientId: ID
    $quantityField: String
    $rateField: String
    $itemDescriptionField: String
    $amountField: String
    $bankName: String
    $bankInfo: String
    $accountNumber: String
    $beneficiaryName: String
    $clientLocation: String!
    $clientLegalNumber: String!
    $email: String!
    $legalNumber: String!
    $location: String!
    $phone: String!
    $swiftNumber: String
    $invoiceDueDate: Date
  ) {
    createInvoice(
      clientName: $clientName
      companyName: $companyName
      currency: $currency
      invoiceDate: $invoiceDate
      invoiceTitle: $invoiceTitle
      logo: $logo
      letter: $letter
      logoWidth: $logoWidth
      name: $name
      notes: $notes
      productLines: $productLines
      tax: $tax
      term: $term
      projectId: $projectId
      clientId: $clientId
      rateField: $rateField
      quantityField: $quantityField
      itemDescriptionField: $itemDescriptionField
      amountField: $amountField
      bankInfo: $bankInfo
      bankName: $bankName
      accountNumber: $accountNumber
      beneficiaryName: $beneficiaryName
      clientLocation: $clientLocation
      clientLegalNumber: $clientLegalNumber
      email: $email
      legalNumber: $legalNumber
      location: $location
      phone: $phone
      swiftNumber: $swiftNumber
      invoiceDueDate: $invoiceDueDate
    ) {
      success
      errors
      pdfString
    }
  }
`;

export const editInvoice = gql`
  mutation editInvoice(
    $id: ID!
    $clientName: String!
    $companyName: String!
    $letter: String
    $currency: String!
    $invoiceDate: Date!
    $invoiceTitle: String!
    $logo: String
    $logoWidth: Int
    $name: String!
    $notes: String
    $productLines: [InvoiceProductLineInputType]
    $tax: Decimal!
    $term: String
    $projectId: ID
    $clientId: ID
    $quantityField: String
    $rateField: String
    $itemDescriptionField: String
    $amountField: String
    $bankName: String
    $bankInfo: String
    $accountNumber: String
    $beneficiaryName: String
    $clientLocation: String!
    $clientLegalNumber: String!
    $email: String!
    $legalNumber: String!
    $location: String!
    $phone: String!
    $swiftNumber: String
    $invoiceDueDate: Date
  ) {
    editInvoice(
      id: $id
      clientName: $clientName
      companyName: $companyName
      currency: $currency
      invoiceDate: $invoiceDate
      invoiceTitle: $invoiceTitle
      logo: $logo
      letter: $letter
      logoWidth: $logoWidth
      name: $name
      notes: $notes
      productLines: $productLines
      tax: $tax
      term: $term
      projectId: $projectId
      clientId: $clientId
      rateField: $rateField
      quantityField: $quantityField
      itemDescriptionField: $itemDescriptionField
      amountField: $amountField
      bankInfo: $bankInfo
      bankName: $bankName
      accountNumber: $accountNumber
      beneficiaryName: $beneficiaryName
      clientLocation: $clientLocation
      clientLegalNumber: $clientLegalNumber
      email: $email
      legalNumber: $legalNumber
      location: $location
      phone: $phone
      swiftNumber: $swiftNumber
      invoiceDueDate: $invoiceDueDate
    ) {
      success
      errors
      pdfString
    }
  }
`;

export const deleteInvoice = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoice(id: $id) {
      success
      errors
    }
  }
`;

export const changeCandidateItemLocation = gql`
  mutation changeItemLocation($itemId: ID!, $listId: ID!, $order: Float!) {
    changeItemLocation(itemId: $itemId, listId: $listId, order: $order) {
      success
      errors
    }
  }
`;

export const createCandidate = gql`
  mutation createCandidate(
    $firstName: String!
    $lastName: String!
    $emails: [EmailType]!
    $listId: ID!
    $jobTitleId: ID!
    $jobType: Int
    $phones: [PhoneType]!
    $files: [[String]]
    $englishLevelId: ID
    $linkedinLink: String
    $birthday: Date
    $currency: String
    $expectedValue: String
    $source: ID
  ) {
    createCandidate(
      firstName: $firstName
      lastName: $lastName
      emails: $emails
      listId: $listId
      jobTitleId: $jobTitleId
      jobType: $jobType
      phones: $phones
      files: $files
      englishLevelId: $englishLevelId
      linkedinLink: $linkedinLink
      birthday: $birthday
      currency: $currency
      expectedValue: $expectedValue
      source: $source
    ) {
      success
      errors
    }
  }
`;

export const updateCandidate = gql`
  mutation updateCandidate(
    $candidateId: ID!
    $emails: [EmailType]
    $listId: ID
    $managerId: ID
    $archived: Boolean
    $firstName: String
    $lastName: String
    $jobTitleId: ID
    $jobType: Int
    $birthday: Date
    $englishLevelId: ID
    $expectedValue: Float
    $linkedinLink: String
    $facebookLink: String
    $currency: String
    $education: String
    $source: ID
  ) {
    updateCandidate(
      candidateId: $candidateId
      emails: $emails
      listId: $listId
      managerId: $managerId
      archived: $archived
      firstName: $firstName
      lastName: $lastName
      jobTitleId: $jobTitleId
      jobType: $jobType
      birthday: $birthday
      englishLevelId: $englishLevelId
      expectedValue: $expectedValue
      linkedinLink: $linkedinLink
      facebookLink: $facebookLink
      currency: $currency
      source: $source
      education: $education
    ) {
      success
      errors
    }
  }
`;

export const sendCandidateEmail = gql`
  mutation sendCandidateEmail(
    $candidateId: ID!
    $content: String!
    $emails: [String]!
    $title: String!
  ) {
    sendCandidateEmail(
      candidateId: $candidateId
      content: $content
      emails: $emails
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const createNote = gql`
  mutation createNote($candidateId: ID!, $note: String!) {
    createNote(note: $note, candidateId: $candidateId) {
      errors
      success
    }
  }
`;

export const createActivity = gql`
  mutation createActivity(
    $activityType: Int!
    $done: Boolean
    $candidateId: ID!
    $managerId: ID!
    $endDate: Date!
    $endTime: Time!
    $note: String!
    $startDate: Date!
    $startTime: Time!
    $title: String!
  ) {
    createActivity(
      activityType: $activityType
      done: $done
      candidateId: $candidateId
      managerId: $managerId
      endDate: $endDate
      endTime: $endTime
      note: $note
      startDate: $startDate
      startTime: $startTime
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const addCandidateFiles = gql`
  mutation addFiles($candidateId: ID!, $files: [[String]]!) {
    addFiles(candidateId: $candidateId, files: $files) {
      errors
      success
      files {
        title
        size
        id
        file
        description
        createdAt
        candidate {
          firstName
          lastName
          manager {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const deleteCandidateFile = gql`
  mutation deleteFile($fileId: ID!) {
    deleteFile(fileId: $fileId) {
      errors
      success
    }
  }
`;

export const convertToEmployee = gql`
  mutation convertToEmployee(
    $candidateId: ID!
    $firstName: String!
    $lastName: String!
    $jobTitleId: ID!
    $jobType: Int
    $salary: String
    $phone: String!
    $email: String!
    $linkedinLink: String
    $birthday: Date
    $englishLevelId: ID
    $onboardingSteps: String!
  ) {
    convertToEmployee(
      candidateId: $candidateId
      firstName: $firstName
      lastName: $lastName
      jobTitleId: $jobTitleId
      jobType: $jobType
      salary: $salary
      phone: $phone
      email: $email
      linkedinLink: $linkedinLink
      birthday: $birthday
      englishLevelId: $englishLevelId
      onboardingSteps: $onboardingSteps
    ) {
      success
      errors
    }
  }
`;

export const changeLeadItemLocation = gql`
  mutation changeItemLeadLocation($itemId: ID!, $listId: ID!, $order: Float!) {
    changeItemLeadLocation(itemId: $itemId, listId: $listId, order: $order) {
      success
      errors
    }
  }
`;
export const createIncomeAndExpensesLabel = gql`
  mutation createIncomeAndExpensesLabel($name: String!) {
    createIncomeAndExpensesLabel(name: $name) {
      errors
      success
      label {
        id
        name
      }
    }
  }
`;
export const createLead = gql`
  mutation createLead(
    $phones: [LeadPhoneType]
    $listId: ID!
    $linkedin: String
    $labelId: ID
    $keyNeed: String
    $industry: ID
    $facebook: String
    $currency: String
    $contactPerson: ID!
    $website: String
    $value: Float
    $source: ID
    $skype: String
    $organization: String
    $emails: [LeadEmailType]
  ) {
    createNewLead(
      contactPerson: $contactPerson
      currency: $currency
      facebook: $facebook
      industry: $industry
      keyNeed: $keyNeed
      labelId: $labelId
      linkedin: $linkedin
      listId: $listId
      organization: $organization
      skype: $skype
      source: $source
      website: $website
      value: $value
      phones: $phones
      emails: $emails
    ) {
      success
      errors
    }
  }
`;
export const updateLead = gql`
  mutation updateLead(
    $currency: String
    $archived: Boolean
    $email: String
    $phone: String
    $website: String
    $value: Float
    $source: ID
    $skype: String
    $ownerId: ID
    $organization: String
    $listId: ID
    $linkedin: String
    $leadId: ID!
    $labelId: ID
    $keyNeed: String
    $industry: ID
    $facebook: String
    $firstName: String
    $lastName: String
    $newContacts: [ID]
    $removeContacts: [ID]
    $primaryContactId: ID
  ) {
    updateLead(
      archived: $archived
      currency: $currency
      email: $email
      facebook: $facebook
      industry: $industry
      keyNeed: $keyNeed
      labelId: $labelId
      leadId: $leadId
      linkedin: $linkedin
      listId: $listId
      organization: $organization
      ownerId: $ownerId
      phone: $phone
      skype: $skype
      source: $source
      value: $value
      firstName: $firstName
      lastName: $lastName
      website: $website
      newContacts: $newContacts
      removeContacts: $removeContacts
      primaryContactId: $primaryContactId
    ) {
      errors
      success
    }
  }
`;

export const sendLeadEmail = gql`
  mutation sendLeadEmail(
    $leadId: ID!
    $content: String!
    $emails: [String]!
    $title: String!
  ) {
    sendLeadEmail(
      leadId: $leadId
      content: $content
      emails: $emails
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const addLeadFiles = gql`
  mutation addLeadFiles($leadId: ID!, $files: [[String]]!) {
    addLeadFiles(leadId: $leadId, files: $files) {
      errors
      success
      files {
        title
        size
        id
        file
        description
        createdAt
        lead {
          ...Lead
        }
      }
    }
  }
  ${fragment.LeadFragment}
`;

export const deleteLeadFile = gql`
  mutation deleteLeadFile($fileId: ID!) {
    deleteLeadFile(fileId: $fileId) {
      errors
      success
    }
  }
`;

export const convertLeadToClient = gql`
  mutation convertLeadToClient(
    $website: String
    $value: Float
    $source: ID
    $skype: String
    $project: ID
    $phone: String
    $ownerId: ID
    $organization: String
    $linkedin: String
    $leadId: ID!
    $lastName: String
    $labelId: ID
    $keyNeed: String
    $industry: ID
    $firstName: String
    $facebook: String
    $email: String
    $currency: String
    $country: String
    $archived: Boolean
  ) {
    convertToClient(
      archived: $archived
      country: $country
      currency: $currency
      email: $email
      facebook: $facebook
      firstName: $firstName
      industry: $industry
      keyNeed: $keyNeed
      labelId: $labelId
      lastName: $lastName
      leadId: $leadId
      linkedin: $linkedin
      organization: $organization
      ownerId: $ownerId
      phone: $phone
      project: $project
      skype: $skype
      source: $source
      value: $value
      website: $website
    ) {
      errors
      success
    }
  }
`;

export const createLeadActivity = gql`
  mutation createLeadActivity(
    $activityType: Int!
    $done: Boolean
    $leadId: ID!
    $managerId: ID!
    $endDate: Date!
    $endTime: Time!
    $note: String!
    $startDate: Date!
    $startTime: Time!
    $title: String!
  ) {
    createLeadActivity(
      activityType: $activityType
      done: $done
      leadId: $leadId
      managerId: $managerId
      endDate: $endDate
      endTime: $endTime
      note: $note
      startDate: $startDate
      startTime: $startTime
      title: $title
    ) {
      success
      errors
    }
  }
`;

export const createLeadNote = gql`
  mutation createLeadNote($leadId: ID!, $note: String!) {
    createLeadNote(note: $note, leadId: $leadId) {
      errors
      success
    }
  }
`;

export const updateActivityCandidate = gql`
  mutation updateActivity(
    $activityId: ID!
    $activityType: ID
    $candidateId: ID
    $done: Boolean
    $endDate: Date
    $endTime: Time
    $managerId: ID
    $note: String
    $startDate: Date
    $startTime: Time
    $title: String
  ) {
    updateActivity(
      title: $title
      startTime: $startTime
      startDate: $startDate
      note: $note
      managerId: $managerId
      endTime: $endTime
      endDate: $endDate
      done: $done
      candidateId: $candidateId
      activityType: $activityType
      activityId: $activityId
    ) {
      success
      errors
    }
  }
`;
export const updateActivityLead = gql`
  mutation updateActivityLead(
    $activityId: ID!
    $activityType: ID
    $done: Boolean
    $endDate: Date
    $endTime: Time
    $title: String
    $startTime: Time
    $startDate: Date
    $ownerId: ID
    $note: String
    $leadId: ID
  ) {
    updateLeadActivity(
      activityId: $activityId
      activityType: $activityType
      done: $done
      endDate: $endDate
      endTime: $endTime
      leadId: $leadId
      note: $note
      ownerId: $ownerId
      startDate: $startDate
      startTime: $startTime
      title: $title
    ) {
      errors
      success
    }
  }
`;

export const createCandidateEmailTemplate = gql`
  mutation createCandidateEmailTemplate(
    $subject: String
    $templateName: String!
    $content: JSONString!
    $category: ID
  ) {
    createCandidateEmailTemplate(
      category: $category
      content: $content
      templateName: $templateName
      subject: $subject
    ) {
      errors
      success
    }
  }
`;

export const createClientActivity = gql`
  mutation createClientActivity(
    $title: String!
    $startTime: Time!
    $startDate: Date!
    $note: String!
    $managerId: ID!
    $endTime: Time!
    $endDate: Date!
    $done: Boolean!
    $clientId: ID!
    $activityType: ID!
  ) {
    createClientActivity(
      activityType: $activityType
      clientId: $clientId
      done: $done
      endDate: $endDate
      note: $note
      startDate: $startDate
      title: $title
      startTime: $startTime
      managerId: $managerId
      endTime: $endTime
    ) {
      errors
      success
    }
  }
`;

export const createJobPost = gql`
  mutation createJobPosts(
    $titleParagraph: String
    $title: String!
    $reviewCount: Int
    $record: String
    $publicLink: String!
    $paragraph: [ID]
  ) {
    createJobPosts(
      paragraph: $paragraph
      publicLink: $publicLink
      record: $record
      reviewCount: $reviewCount
      title: $title
      titleParagraph: $titleParagraph
    ) {
      errors
      success
    }
  }
`;

export const updateClientActivity = gql`
  mutation updateClientActivity(
    $activityId: ID!
    $title: String
    $startTime: Time
    $startDate: Date
    $note: String
    $managerId: ID
    $endTime: Time
    $endDate: Date
    $done: Boolean
    $clientId: ID
    $activityType: ID
  ) {
    updateClientActivity(
      activityType: $activityType
      clientId: $clientId
      done: $done
      endDate: $endDate
      note: $note
      startDate: $startDate
      title: $title
      startTime: $startTime
      managerId: $managerId
      endTime: $endTime
      activityId: $activityId
    ) {
      errors
      success
    }
  }
`;

export const createClientNote = gql`
  mutation createClientNote($clientId: ID!, $note: String!) {
    createClientNote(clientId: $clientId, note: $note) {
      errors
      success
    }
  }
`;

export const sendClientEmail = gql`
  mutation sendClientEmail(
    $title: String!
    $emails: [String]!
    $content: String!
    $clientId: ID!
  ) {
    sendEmailClient(clientId: $clientId, content: $content, emails: $emails)
  }
`;

export const updateJobPost = gql`
  mutation updateJobPosts(
    $title: String!
    $reviewCount: Int
    $publicLink: String!
    $jobPostId: ID!
  ) {
    updateJobPosts(
      jobPostId: $jobPostId
      publicLink: $publicLink
      reviewCount: $reviewCount
      title: $title
    ) {
      errors
      success
    }
  }
`;

export const updateCandidateEmailTemplate = gql`
  mutation updateCandidateEmailTemplate(
    $subject: String!
    $templateId: ID!
    $templateName: String!
    $content: JSONString!
    $category: ID
  ) {
    updateCandidateEmailTemplate(
      category: $category
      content: $content
      templateName: $templateName
      templateId: $templateId
      subject: $subject
    ) {
      errors
      success
    }
  }
`;

export const deleteCandidateEmailTemplate = gql`
  mutation deleteCandidateEmailTemplate($templateId: ID!) {
    deleteCandidateEmailTemplate(templateId: $templateId) {
      errors
      success
    }
  }
`;

export const createLeadEmailTemplate = gql`
  mutation createLeadEmailTemplate(
    $templateName: String!
    $subject: String
    $content: JSONString!
    $category: ID
  ) {
    createLeadEmailTemplate(
      category: $category
      content: $content
      templateName: $templateName
      subject: $subject
    ) {
      errors
      success
    }
  }
`;

export const updateLeadEmailTemplate = gql`
  mutation updateLeadEmailTemplate(
    $templateName: String!
    $templateId: ID!
    $subject: String!
    $content: JSONString!
    $category: ID
  ) {
    updateLeadEmailTemplate(
      category: $category
      content: $content
      subject: $subject
      templateId: $templateId
      templateName: $templateName
    ) {
      errors
      success
    }
  }
`;
export const deleteLeadEmailTemplate = gql`
  mutation deleteLeadEmailTemplate($templateId: ID!) {
    deleteLeadEmailTemplate(templateId: $templateId) {
      errors
      success
    }
  }
`;

export const createLabelLead = gql`
  mutation createLabelLead($color: String, $name: String!) {
    createLabelLead(color: $color, name: $name) {
      errors
      success
      label {
        ...Label
      }
    }
  }
  ${fragment.LabelFragment}
`;

export const addClientFiles = gql`
  mutation addClientFiles($files: [[String]]!, $clientId: ID!) {
    addClientFiles(clientId: $clientId, files: $files) {
      errors
      success
      files {
        createdAt
        description
        file
        id
        size
        title
        manager {
          id
          firstName
          lastName
        }
        client {
          primaryContact {
            firstName
            id
            lastName
          }
        }
      }
    }
  }
`;

export const deleteClientFile = gql`
  mutation deleteClientFile($fileId: ID!) {
    deleteClientFile(fileId: $fileId) {
      errors
      success
    }
  }
`;

export const deleteJobPost = gql`
  mutation deleteJobPost($jobPostId: ID!) {
    deleteJobPost(jobPostId: $jobPostId) {
      errors
      success
    }
  }
`;
export const deleteNoteCandidate = gql`
  mutation deleteNote($noteId: ID!) {
    deleteNote(noteId: $noteId) {
      errors
      success
    }
  }
`;

export const updateNoteCandidate = gql`
  mutation updateNote($noteId: ID!, $note: String!) {
    updateNote(note: $note, noteId: $noteId) {
      errors
      success
    }
  }
`;
export const deleteActivityCandidate = gql`
  mutation deleteActivity($activityId: ID!) {
    deleteActivity(activityId: $activityId) {
      success
      errors
    }
  }
`;

export const updateNoteLead = gql`
  mutation updateLeadNote($noteId: ID!, $note: String!) {
    updateLeadNote(noteId: $noteId, note: $note) {
      success
      errors
    }
  }
`;
export const deleteActivityLead = gql`
  mutation deleteActivityLead($activityId: ID!) {
    deleteLeadActivity(activityId: $activityId) {
      errors
      success
    }
  }
`;

export const deleteNoteLead = gql`
  mutation deleteLeadNote($noteId: ID!) {
    deleteLeadNote(noteId: $noteId) {
      errors
      success
    }
  }
`;

export const addProspect = gql`
  mutation addProspect(
    $files: [[String]]
    $jobLink: String
    $q1: String
    $q2: String
    $q3: String
    $q6: String
    $q5: String
    $q4: String
    $coverLetter: String
  ) {
    addProspect(
      q5: $q5
      q6: $q6
      q3: $q3
      q2: $q2
      q1: $q1
      jobLink: $jobLink
      files: $files
      coverLetter: $coverLetter
      q4: $q4
    ) {
      success
      errors
    }
  }
`;

export const updateProspect = gql`
  mutation updateProspect($status: ID!, $prospectId: ID!) {
    updateProspect(prospectId: $prospectId, status: $status) {
      errors
      success
    }
  }
`;

export const updateClientNote = gql`
  mutation updateClientNote($noteId: ID!, $note: String!) {
    updateClientNote(note: $note, noteId: $noteId) {
      errors
      success
    }
  }
`;
export const deleteClientActivity = gql`
  mutation deleteClientActivity($activityId: ID!) {
    deleteClientActivity(activityId: $activityId) {
      errors
      success
    }
  }
`;

export const deleteClientNote = gql`
  mutation deleteClientNote($noteId: ID!) {
    deleteClientNote(noteId: $noteId) {
      errors
      success
    }
  }
`;

export const generateReport = gql`
  mutation generateReport(
    $clientName: String
    $companyAddress: String
    $companyAddress2: String
    $companyCountry: String
    $companyName: String
    $employeeId: ID
    $endDate: Date
    $logo: String
    $logoWidth: Int
    $name: String
    $projectId: ID
    $startDate: Date
    $title: String!
    $type: String
    $clientCountry: String
    $clientAddress2: String
    $clientAddress: String
    $reports: [ReportItem]
  ) {
    generateReportPdf(
      type: $type
      title: $title
      startDate: $startDate
      projectId: $projectId
      name: $name
      logoWidth: $logoWidth
      logo: $logo
      endDate: $endDate
      employeeId: $employeeId
      companyName: $companyName
      companyCountry: $companyCountry
      companyAddress: $companyAddress
      clientName: $clientName
      companyAddress2: $companyAddress2
      clientCountry: $clientCountry
      clientAddress: $clientAddress
      clientAddress2: $clientAddress2
      reports: $reports
    ) {
      success
      errors
      pdfString
      report {
        id
        space {
          id
          name
        }
      }
    }
  }
`;

export const createSMTPProfile = gql`
  mutation createSMTPProfile(
    $accessType: Int!
    $smtpPort: Int!
    $smtpHost: String!
    $authUser: Email!
    $authPassword: String!
    $accessUsers: [ID]
  ) {
    createSmtpProfile(
      accessUsers: $accessUsers
      authPassword: $authPassword
      authUser: $authUser
      smtpHost: $smtpHost
      smtpPort: $smtpPort
      accessType: $accessType
    ) {
      errors
      success
    }
  }
`;
export const createIMAPProfile = gql`
  mutation createIMAPProfile(
    $accessType: Int!
    $imapPort: Int!
    $imapHost: String!
    $authUser: Email!
    $authPassword: String!
    $accessUsers: [ID]
  ) {
    createImapProfile(
      accessUsers: $accessUsers
      authPassword: $authPassword
      authUser: $authUser
      imapHost: $imapHost
      imapPort: $imapPort
      accessType: $accessType
    ) {
      errors
      success
    }
  }
`;
export const sendEmailFromInbox = gql`
  mutation sendEmailFromInbox(
    $body: String!
    $fromAddr: String!
    $subject: String
    $toAddrs: [String!]
  ) {
    sendEmailFromInbox(
      toAddrs: $toAddrs
      subject: $subject
      fromAddr: $fromAddr
      body: $body
    ) {
      success
      errors
    }
  }
`;
export const saveEmailAsDraft = gql`
  mutation saveEmailAsDraft(
    $body: String!
    $toAddrs: [String!]
    $subject: String
    $mailbox: String!
    $fromAddr: String!
  ) {
    saveEmailDraft(
      body: $body
      fromAddr: $fromAddr
      mailbox: $mailbox
      subject: $subject
      toAddrs: $toAddrs
    ) {
      errors
      success
    }
  }
`;

export const moveEmailToTrash = gql`
  mutation moveToTrash($addr: String!, $emailId: ID!, $mailbox: String!) {
    moveToTrash(emailId: $emailId, addr: $addr, mailbox: $mailbox) {
      errors
      success
    }
  }
`;
export const deleteEmailForever = gql`
  mutation deleteEmailForever(
    $addr: String!
    $emailId: ID!
    $mailbox: String!
  ) {
    deleteEmailForever(emailId: $emailId, addr: $addr, mailbox: $mailbox) {
      errors
      success
    }
  }
`;
export const createDeviceFCMToken = gql`
  mutation createDeviceFcmToken($token: String!) {
    createDeviceFcmToken(token: $token) {
      errors
      success
    }
  }
`;

export const createSpace = gql`
  mutation createSpace($name: String!, $logo: String, $key: String) {
    createSpace(key: $key, logo: $logo, name: $name) {
      errors
      success
    }
  }
`;

export const createShortCutsSpace = gql`
  mutation createShortCutsSpace($spaceId: ID!, $link: String!, $name: String!) {
    createShortcutsSpace(link: $link, spaceId: $spaceId, name: $name) {
      errors
      success
    }
  }
`;

export const createContentFileSpace = gql`
  mutation createContentFileSpace(
    $fileToBase64: String
    $name: String
    $spaceId: ID!
  ) {
    createContentFileSpace(
      fileToBase64: $fileToBase64
      name: $name
      spaceId: $spaceId
    ) {
      errors
      success
      newFile {
        createdAt
        editable
        isStarred
        file
        filename
        id
        statusDraft
        updatedAt
        createdBy {
          lastName
          firstName
          id
        }
        updatedBy {
          lastName
          firstName
          id
        }
        space {
          id
          name
        }
      }
    }
  }
`;

export const updateContentFileSpace = gql`
  mutation updateContentFileSpace(
    $publish: Boolean
    $isStarred: Boolean
    $html: String
    $filename: String
    $fileId: ID!
  ) {
    updateContentFileSpace(
      fileId: $fileId
      filename: $filename
      html: $html
      isStarred: $isStarred
      publish: $publish
    ) {
      errors
      success
    }
  }
`;

export const updateSpace = gql`
  mutation UpdateSpaceMutation(
    $spaceId: ID!
    $isStarred: Boolean
    $name: String
    $logo: String
    $htmlOverview: String
    $description: String
    $accessUsers: [ID]
    $accessType: Int
    $isArchived: Boolean
  ) {
    updateSpace(
      isStarred: $isStarred
      spaceId: $spaceId
      accessType: $accessType
      accessUsers: $accessUsers
      description: $description
      htmlOverview: $htmlOverview
      logo: $logo
      name: $name
      isArchived: $isArchived
    ) {
      errors
      success
    }
  }
`;
export const updateShortcut = gql`
  mutation updateShortcut($shortcutId: ID!, $name: String!, $link: String!) {
    updateShortcut(link: $link, name: $name, shortcutId: $shortcutId) {
      errors
      success
    }
  }
`;
export const deleteShortcut = gql`
  mutation deleteShortcut($shortcutId: ID!) {
    deleteShortcut(shortcutId: $shortcutId) {
      errors
      success
    }
  }
`;

export const deleteContentFile = gql`
  mutation deleteContentFIle($fileId: ID!) {
    deleteContentFileSpace(fileId: $fileId) {
      errors
      success
    }
  }
`;

export const deleteSpace = gql`
  mutation deleteSpace($spaceId: ID!) {
    deleteSpace(spaceId: $spaceId) {
      errors
      success
    }
  }
`;
export const createWhiteBoard = gql`
  mutation createWhiteBoard($spaceId: ID!) {
    createWhiteboard(spaceId: $spaceId) {
      errors
      success
      whiteboard {
        body
        createdAt
        id
        isStarred
        name
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        space {
          id
          name
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const updateWhiteBoard = gql`
  mutation updateWhiteBoard(
    $body: String
    $name: String
    $whiteboardId: ID!
    $isStarred: Boolean
  ) {
    updateWhiteboard(
      body: $body
      isStarred: $isStarred
      name: $name
      whiteboardId: $whiteboardId
    ) {
      errors
      success
    }
  }
`;
export const deleteWhiteboard = gql`
  mutation deleteWhiteboard($whiteboardId: ID!) {
    deleteWhiteboard(whiteboardId: $whiteboardId) {
      errors
      success
    }
  }
`;
export const updateStatusInvoice = gql`
  mutation updateStatusInvoice($status: Int!, $id: ID!) {
    updateStatusInvoice(status: $status, id: $id) {
      errors
      success
    }
  }
`;
export const copyContentSpace = gql`
  mutation copyContentSpace($type: String!, $id: ID!) {
    copyContentSpace(id: $id, type: $type) {
      errors
      success
    }
  }
`;

export const updateCompanyLocation = gql`
  mutation updateCompanyLocation(
    $country: String
    $region: String
    $companyId: ID!
    $city: String
    $address2: String
    $address1: String
  ) {
    updateCompanyLocation(
      companyId: $companyId
      address1: $address1
      address2: $address2
      city: $city
      country: $country
      region: $region
    ) {
      errors
      success
    }
  }
`;

export const updateCompanyPaymentInfo = gql`
  mutation updateCompanyPaymentInfo(
    $paymentId: ID!
    $swiftNumber: String!
    $beneficiaryName: String!
    $bankName: String!
    $bankInfo: String!
    $accountNumber: String!
  ) {
    updateCompanyPaymentInfo(
      accountNumber: $accountNumber
      bankInfo: $bankInfo
      bankName: $bankName
      beneficiaryName: $beneficiaryName
      paymentId: $paymentId
      swiftNumber: $swiftNumber
    ) {
      errors
      success
    }
  }
`;

export const createCompanyPaymentInfo = gql`
  mutation createCompanyPaymentInfo(
    $companyId: ID!
    $swiftNumber: String!
    $beneficiaryName: String!
    $bankName: String!
    $bankInfo: String!
    $accountNumber: String!
  ) {
    createCompanyPaymentInfo(
      accountNumber: $accountNumber
      bankInfo: $bankInfo
      bankName: $bankName
      beneficiaryName: $beneficiaryName
      companyId: $companyId
      swiftNumber: $swiftNumber
    ) {
      errors
      success
    }
  }
`;
export const createApprovedReport = gql`
  mutation createApprovedReport(
    $approvedObjects: [ApprovedListType] = {}
    $projectId: ID!
    $userId: ID!
  ) {
    createApprovedReport(
      approvedObjects: $approvedObjects
      userId: $userId
      projectId: $projectId
    ) {
      success
      errors
    }
  }
`;
export const deleteApprovedReport = gql`
  mutation deleteApprovedReport($approvedReportId: ID) {
    deleteApprovedReport(approvedReportId: $approvedReportId) {
      errors
      success
    }
  }
`;

export const updateApprovedReport = gql`
  mutation updateApprovedReport(
    $approvedReportId: ID!
    $approvedObjects: [ApprovedEditListType]
    $title: String
  ) {
    updateApprovedReport(
      approvedReportId: $approvedReportId
      approvedObjects: $approvedObjects
      title: $title
    ) {
      errors
      success
    }
  }
`;
export const generateApprovedReportFile = gql`
  mutation generateApprovedReportFile(
    $approvedReportId: ID!
    $fileType: FileTypeEnum!
    $fields: [String]
    $senderUser: ID!
    $receiverUser: ID!
    $letter: String
  ) {
    generateApprovedReportFile(
      approvedReportId: $approvedReportId
      fileType: $fileType
      fields: $fields
      receiverUser: $receiverUser
      senderUser: $senderUser
      letter: $letter
    ) {
      errors
      fileBase64
      success
      filename
    }
  }
`;

export const changeDefaultPaymentInfo = gql`
  mutation changeDefaultPaymentInfo($paymentId: ID!) {
    changeDefaultPaymentInfo(paymentId: $paymentId) {
      errors
      success
    }
  }
`;
export const deleteCompanyPaymentInfo = gql`
  mutation deleteCompanyPaymentInfo($paymentId: ID!) {
    deleteCompanyPaymentInfo(paymentId: $paymentId) {
      errors
      success
    }
  }
`;

export const updateCompanyServices = gql`
  mutation updateCompanyServices(
    $services: [CompanyServicesInputType]!
    $companyId: ID!
  ) {
    updateCompanyServices(services: $services, companyId: $companyId) {
      errors
      success
    }
  }
`;
export const generateDocxInvoice = gql`
  mutation generateDocxInvoice($invoiceId: ID = "") {
    generateDocxInvoice(invoiceId: $invoiceId) {
      errors
      fileBase64
      filename
      success
    }
  }
`;
export const changePlan = gql`
  mutation changePlan($planId: ID!) {
    changePlan(planId: $planId) {
      errors
      success
    }
  }
`;

export const completeOnboardingStep = gql`
  mutation completeOnboardingStep($step: ID!, $status: Boolean!) {
    completeOnboardingStep(step: $step, status: $status) {
      errors
      success
    }
  }
`;

export const userUpdateMe = gql`
  mutation userUpdateMe(
    $additionalInformation: String
    $birthday: Date!
    $email: String!
    $englishLevel: ID
    $firstName: String!
    $lastName: String!
    $phone: String!
  ) {
    userUpdateMe(
      additionalInformation: $additionalInformation
      email: $email
      englishLevel: $englishLevel
      phone: $phone
      lastName: $lastName
      firstName: $firstName
      birthday: $birthday
    ) {
      errors
      success
    }
  }
`;
export const createUserIdentificationDocument = gql`
  mutation createUserIdentificationDocument($documents: [DocumentInput]!) {
    createUserIdentificationDocument(documents: $documents) {
      errors
      success
    }
  }
`;

export const convertProspectToLead = gql`
  mutation convertProspectToLead(
    $phones: [LeadPhoneType]
    $listId: ID!
    $linkedin: String
    $labelId: ID
    $keyNeed: String
    $industry: ID
    $facebook: String
    $currency: String
    $contactPerson: ID!
    $website: String
    $value: Float
    $source: ID
    $skype: String
    $organization: String
    $emails: [LeadEmailType]
    $prospectId: ID!
  ) {
    convertProspectToLead(
      contactPerson: $contactPerson
      currency: $currency
      facebook: $facebook
      industry: $industry
      keyNeed: $keyNeed
      labelId: $labelId
      linkedin: $linkedin
      listId: $listId
      organization: $organization
      skype: $skype
      source: $source
      website: $website
      value: $value
      phones: $phones
      emails: $emails
      prospectId: $prospectId
    ) {
      success
      errors
      leadId
    }
  }
`;
export const editProspect = gql`
  mutation editProspect(
    $files: [ProspectInputFileType]
    $coverLetter: String
    $q4: String
    $q3: String
    $q2: String
    $q1: String
    $jobLink: String
    $q5: String
    $q6: String
    $prospectId: ID!
  ) {
    editProspect(
      coverLetter: $coverLetter
      files: $files
      jobLink: $jobLink
      prospectId: $prospectId
      q1: $q1
      q2: $q2
      q3: $q3
      q6: $q6
      q4: $q4
      q5: $q5
    ) {
      errors
      success
    }
  }
`;
export const deleteProspect = gql`
  mutation deleteProspect($prospectId: ID!) {
    deleteProspect(prospectId: $prospectId) {
      errors
      success
    }
  }
`;

export const sendResetPasswordEmail = gql`
  mutation sendResetPasswordEmail($email: Email!) {
    sendResetPasswordEmail(email: $email) {
      errors
      success
    }
  }
`;

export const createCustomNotificationMutation = gql`
  mutation createCustomNotification(
    $name: String!
    $title: String!
    $targetUsers: [ID!]!
    $releaseDatetime: DateTime!
  ) {
    createCustomNotification(
      name: $name
      title: $title
      targetUsers: $targetUsers
      releaseDatetime: $releaseDatetime
    ) {
      success
      errors
    }
  }
`;
