import React, { useState } from "react";
import { Query } from "react-apollo";
import { TextField, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loading from "components/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "44px",
    padding: 0,
    "& .MuiInputBase-root": {
      height: "44px",
    },
    "& .MuiInputBase-input": {
      position: "relative",
      fontSize: "16px",
      bottom: "6px",
      right: "9px",
    },
  },
  noOptionsText: { cursor: "pointer", textDecoration: "underline" },
}));

const Select = ({
  query,
  fetchPolicy,
  getOptions,
  control,
  name,
  disableClearable,
  fullWidth,
  placeholder,
  options,
  onNoOptionsTextClickHandler,
  nameIndex,
  actionButtons,
  ...props
}) => {
  const classes = useStyles();
  const [textValue, setTextValue] = useState("");

  const renderAutocomplete = (optionsToUse) => (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: valueOrValues } }) => {
        const value = nameIndex ? valueOrValues[nameIndex] : valueOrValues;

        const onNoOptionsTextClick = async () => {
          onNoOptionsTextClickHandler(textValue, nameIndex);
        };

        return (
          <Autocomplete
            {...props}
            className={classes.root}
            value={value}
            onChange={(event, newValue) =>
              onChange(
                nameIndex
                  ? valueOrValues?.map((a, index) =>
                      index == nameIndex ? newValue : a,
                    )
                  : newValue,
              )
            }
            disableClearable={disableClearable}
            fullWidth={fullWidth}
            options={optionsToUse}
            getOptionLabel={(option) => option.text || ""}
            getOptionSelected={(option, value) => option.value === value.value}
            noOptionsText={
              props.noOptionsText ||
              (props.noOptionsTitle && textValue && (
                <div
                  onMouseDown={onNoOptionsTextClick}
                  className={classes.noOptionsText}
                >
                  {props?.noOptionsTitle} {textValue}
                </div>
              )) ||
              "No options"
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
                onChange={(e) => setTextValue(e.target.value)}
                value={textValue}
              />
            )}
          />
        );
      }}
    />
  );

  if (options) {
    if (actionButtons) {
      const buttonOptions = actionButtons.map((button) => ({
        text: button?.title,
        value: button?.title,
        isButton: true,
      }));
      options = [...buttonOptions, ...options];
    }
    return renderAutocomplete(options);
  }

  return (
    <Query query={query} fetchPolicy={fetchPolicy}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <div className="wrap-loading">
              <Loading size={20} />
            </div>
          );
        }
        if (error) return `Error! ${error.message}`;
        const fetchedOptions = getOptions(data);
        return renderAutocomplete(fetchedOptions);
      }}
    </Query>
  );
};

export default Select;
