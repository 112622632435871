import React, { useEffect, useState, useMemo } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Link,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  allEventsPerMonth,
  getNotificationsForUser,
  saveUserViewedNotifications,
} from "api/queries";
import moment from "moment";
import {
  Flight as Luggage,
  LocalCafe,
  LocalHospital as MedicalServices,
  Cake,
  Assignment as Checklist,
  EmojiEvents,
  PersonAdd,
  EventSeat as Chair,
  Work as RemoteWork,
} from "@material-ui/icons";
import { formatName } from "utils";

const notificationIcons = {
  vacation: Luggage,
  "day off": Chair,
  sick: MedicalServices,
  birthday: Cake,
  survey: Checklist,
  event: EmojiEvents,
  joined: PersonAdd,
  "remote work": RemoteWork,
};

function NotificationModal({ anchorEl, onClose, setHasNotifications }) {
  const open = Boolean(anchorEl);
  const history = useHistory();
  const {
    data,
    loading,
    error,
    refetch: refetchEvents,
  } = useQuery(allEventsPerMonth, {
    variables: { year: moment().year(), month: moment().month() + 1 },
  });

  const { data: notificationsData, refetch } = useQuery(
    getNotificationsForUser,
    {
      variables: { page: 1, number: 50 },
      fetchPolicy: "network-only",
      pollInterval: 300000,
    },
  );

  const [markAllAsViewed] = useMutation(saveUserViewedNotifications, {
    onCompleted: () => {
      setHasNotifications(false);
      refetch();
    },
  });

  const notifications = useMemo(() => {
    return notificationsData?.getNotificationsForUser?.objects || [];
  }, [notificationsData]);

  useEffect(() => {
    if (notificationsData) {
      const hasUnreadNotifications = notifications.some(
        (notification) => !notification.isViewed,
      );
      setHasNotifications(hasUnreadNotifications);
    }
  }, [notificationsData, notifications, setHasNotifications]);

  const handleNotificationClick = async (notification) => {
    if (!notification.isViewed) {
      await markAllAsViewed();
    }

    if (notification.redirectTo) {
      history.push(`${notification.redirectTo}`);
    } else {
      history.push("/dashboard/calendar");
      refetchEvents();
    }
    onClose();
  };

  const handleCloseModal = async () => {
    await markAllAsViewed();
    onClose();
  };

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  const getNotificationIcon = (title) => {
    const lowerCaseTitle = title.toLowerCase();
    for (const [keyword, IconComponent] of Object.entries(notificationIcons)) {
      if (lowerCaseTitle.includes(keyword.toLowerCase())) {
        return <IconComponent style={{ fontSize: 24 }} />;
      }
    }
    return <LocalCafe style={{ fontSize: 24 }} />;
  };

  const formatNotificationTitle = (notification) => {
    const { title, user, startDate, endDate, date } = notification;
    const userName = formatName(user);

    if (/vacation request/i.test(title)) {
      return (
        <>
          {`${userName} requested vacation from ${moment(startDate).format("MMM D")} to ${moment(endDate).format("MMM D")}. `}
          <Link
            href="#"
            onClick={(e) => {
              e.stopPropagation();
              handleNotificationClick(notification);
            }}
          >
            View
          </Link>
        </>
      );
    }

    switch (title.toLowerCase()) {
      case "vacation":
        return (
          <>
            {`${userName} requested vacation from ${moment(startDate).format("MMM D")} to ${moment(endDate).format("MMM D")}. `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                handleNotificationClick(notification);
              }}
            >
              View
            </Link>
          </>
        );
      case "day off":
      case "day off request":
        return (
          <>
            {`${userName} requested a day off on ${moment(date).format("MMM D")}. `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                handleNotificationClick(notification);
              }}
            >
              View
            </Link>
          </>
        );
      case "sick leave":
      case "sick leave request":
        return (
          <>
            {`${userName} requested sick leave from ${moment(startDate).format("MMM D")} to ${moment(endDate).format("MMM D")}. `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                handleNotificationClick(notification);
              }}
            >
              View
            </Link>
          </>
        );
      case "remote work":
      case "remote work request":
        return (
          <>
            {`${userName} requested to work remotely on ${moment(date).format("MMM D")}. `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                handleNotificationClick(notification);
              }}
            >
              View
            </Link>
          </>
        );
      case "birthday":
        return (
          <>
            {`Today is ${userName}'s birthday! 🎉 `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                alert(`Send congrats to ${userName}`);
              }}
            >
              Send Congrats
            </Link>
          </>
        );
      case "polls":
        return (
          <>
            {`${userName} invites you to take the poll. Your opinion matters! `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                history.push("/polls");
              }}
            >
              Go to the poll
            </Link>
          </>
        );
      case "corporate event":
        return `Corporate Party - ${moment(date).format("MMM D")} at 6 PM 🎉 Don't forget!`;
      case "new team member":
        return (
          <>
            {`New teammate ${userName} has joined the team. 👋 `}
            <Link
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                alert(`Say hello to ${userName}`);
              }}
            >
              Say "Hello!"
            </Link>
          </>
        );
      default:
        return title;
    }
  };

  const formatDate = (date) => {
    const now = moment();
    const notificationDate = moment(date);
    const diffInMinutes = now.diff(notificationDate, "minutes");
    const diffInHours = now.diff(notificationDate, "hours");
    const diffInDays = now.diff(notificationDate, "days");

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h`;
    } else {
      return `${diffInDays}d`;
    }
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseModal}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: 400,
          maxHeight: 500,
          overflowY: "auto",
          zIndex: 9999,
          scrollbarWidth: "thin",
          scrollbarColor: "#e0e0e0 transparent",
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#e0e0e0",
            borderRadius: "3px",
          },
        },
      }}
    >
      <div style={{ padding: 16 }}>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", marginBottom: 8 }}
        >
          Notifications
        </Typography>
        <Divider />
        <List style={{ padding: 0 }}>
          {notifications.length > 0 ? (
            notifications.map((notification) => {
              const formattedTitle = formatNotificationTitle(notification);

              return (
                <React.Fragment key={notification.id}>
                  <ListItem
                    button
                    onClick={() => handleNotificationClick(notification)}
                    style={{
                      padding: "12px 16px",
                      backgroundColor: notification.isViewed
                        ? "#ffffff"
                        : "#f5f5f5",
                    }}
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minWidth={40}
                        height={40}
                        mr={2}
                      >
                        {getNotificationIcon(notification.title)}
                      </Box>
                      <Box flexGrow={1}>
                        <Typography variant="body1">
                          {formattedTitle}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minWidth={60}
                        height={40}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {formatDate(notification.createdAt)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })
          ) : (
            <ListItem>
              <ListItemText primary="No notifications" />
            </ListItem>
          )}
        </List>
      </div>
    </Popover>
  );
}

export default NotificationModal;
