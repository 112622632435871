import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#333333",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ marginRight: "20px" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      overflowY: "auto",
      marginBottom: "0px",
      marginTop: "0px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  header: {
    borderBottom: "1px solid #ced4da",
  },
}));

export default function Modal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { open = false, handleClose, title } = props;

  useEffect(() => {
    const bodyOverflow = open ? "hidden" : "auto";
    document.documentElement.style.overflow = bodyOverflow;

    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [open]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={false}
      fullScreen={fullScreen}
      className={classes.root}
      scroll={"body"}
      PaperProps={{
        style: fullScreen
          ? {}
          : {
              marginTop: "20px",
              marginBottom: "20px",
            },
      }}
    >
      <DialogTitle
        id="customized-dialog-title"
        className={classes.header}
        onClose={handleClose}
      >
        {title}
      </DialogTitle>
      {props.children}
    </Dialog>
  );
}
