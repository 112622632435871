import { InputAdornment, styled } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import signupBackground from "assets/signup-background.svg";
import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { ResetPasswordSchema } from "./validation";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import ButtonRoot from "../../Buttons/ButtonRoot";
import { makeStyles } from "@material-ui/core/styles";
const StyledCard = styled(Card)({
  background: "white",
  boxShadow: "0px 4px 17px 0px #00000040",
  borderRadius: "10px",
  minHeight: "200px",
  width: "547px",
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "hidden",
    overflowY: "hidden",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${signupBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#EAF0FA",
    backgroundPositionY: "bottom",
    paddingBottom: "230px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: "100%",
    lineHeight: "28px",
    fontSize: "20px",
    padding: "0px 24px",
    fontFamily: "Lato",
  },
  formContainer: {
    padding: "40px 60px 10px",
    gap: "15px",
    display: "flex",
    flexDirection: "column",
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "547px",
    margin: "10px",
  },
  inputField: {
    height: "40px",
    "&:before": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0,0,0,0.32)",
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "6px",
    width: "209px",
    height: "48px",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#2D3748",
    textAlign: "justify",
  },
  cancel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "none",
    color: "black",
    display: "flex",
    background: "#E0E0E0",
    cursor: "pointer",
    borderRadius: "6px",
    width: "209px",
    height: "48px",
    "&:hover": {
      background: "#dadada",
    },
  },
}));

export const ResetPasswordForm = ({ resetPassword, isMobile }) => {
  const classes = useStyles();

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword1 = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword2 = (event) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        newPassword1: "",
        newPassword2: "",
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={resetPassword}
    >
      {({ values, touched, errors, handleBlur, handleChange }) => (
        <Form>
          {isMobile ? (
            <Box>
              <Box
                style={{
                  padding: "100px 30px 10px",
                  gap: "20px",
                }}
                className={classes.formContainer}
              >
                <Typography className={classes.title}>
                  Change your password
                </Typography>
                <Typography
                  className={classes.text}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Enter a new password below to change your password.
                </Typography>

                <FormControl
                  style={{ minHeight: "60px" }}
                  fullWidth
                  error={Boolean(errors.newPassword1 && touched.newPassword1)}
                >
                  <InputLabel className={classes.label} htmlFor="newPassword1">
                    Password
                  </InputLabel>
                  <Input
                    id="newPassword1"
                    type={showPassword1 ? "text" : "password"}
                    className={classes.inputField}
                    value={values.newPassword1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword1
                              ? "hide the password"
                              : "display the password"
                          }
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                          onMouseUp={handleMouseUpPassword1}
                        >
                          {showPassword1 ? (
                            <Visibility style={{ color: "black" }} />
                          ) : (
                            <VisibilityOff style={{ color: "black" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "4px" }}
                  >
                    <ErrorMessage name="newPassword1" />
                  </Typography>
                </FormControl>
                <FormControl
                  style={{ minHeight: "60px" }}
                  fullWidth
                  error={Boolean(errors.newPassword2 && touched.newPassword2)}
                >
                  <InputLabel className={classes.label} htmlFor="newPassword2">
                    Confirm password
                  </InputLabel>
                  <Input
                    id="newPassword2"
                    type={showPassword2 ? "text" : "password"}
                    className={classes.inputField}
                    value={values.newPassword2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword2
                              ? "hide the password"
                              : "display the password"
                          }
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          onMouseUp={handleMouseUpPassword2}
                        >
                          {showPassword2 ? (
                            <Visibility style={{ color: "black" }} />
                          ) : (
                            <VisibilityOff style={{ color: "black" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "4px" }}
                  >
                    <ErrorMessage name="newPassword2" />
                  </Typography>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                  marginTop: "32px",
                  gap: "10px",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {" "}
                <ButtonRoot
                  className={classes.button}
                  text={"Reset Password"}
                  style={{
                    width: "90%",
                  }}
                  type={"submit"}
                />
                <ButtonRoot
                  className={classes.cancel}
                  text={"Cancel"}
                  type={"submit"}
                  onClick={() => (window.location.href = "https://tuc24.com")}
                  style={{
                    width: "90%",
                  }}
                />
              </Box>
            </Box>
          ) : (
            <Box className={classes.cardBox}>
              <StyledCard>
                <Box className={classes.formContainer}>
                  <Typography className={classes.title}>
                    Change your password
                  </Typography>
                  <Typography
                    className={classes.text}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Enter a new password below to change your password.
                  </Typography>

                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.newPassword1 && touched.newPassword1)}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="newPassword1"
                    >
                      Password
                    </InputLabel>
                    <Input
                      id="newPassword1"
                      type={showPassword1 ? "text" : "password"}
                      className={classes.inputField}
                      value={values.newPassword1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword1
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            onMouseUp={handleMouseUpPassword1}
                          >
                            {showPassword1 ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="newPassword1" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.newPassword2 && touched.newPassword2)}
                  >
                    <InputLabel
                      className={classes.label}
                      htmlFor="newPassword2"
                    >
                      Confirm password
                    </InputLabel>
                    <Input
                      id="newPassword2"
                      type={showPassword2 ? "text" : "password"}
                      className={classes.inputField}
                      value={values.newPassword2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword2
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            onMouseUp={handleMouseUpPassword2}
                          >
                            {showPassword2 ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="newPassword2" />
                    </Typography>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "40px",
                  }}
                >
                  <ButtonRoot
                    className={classes.button}
                    text={"Change password"}
                    type={"submit"}
                  />
                </Box>
              </StyledCard>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};
