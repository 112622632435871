import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { LOGIN_MUTATION } from "api/mutations";
import { getInternalToken, saveData, useQueryParams } from "utils";
import * as path from "constants/routes";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as Yup from "yup";
import MobileLogin from "./MobileLogin";
import DesktopLogin from "./DesktopLogin";
const validationSchema = Yup.object({
  username: Yup.string()
    .email("Enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});
const validationSchemaWorkspace = Yup.object({
  workspace: Yup.string().required("Workspace is required"),
  username: Yup.string()
    .email("Enter a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});

const LoginPage = (props) => {
  const destinationPath = useQueryParams().get("destination");
  const [login] = useMutation(LOGIN_MUTATION);
  const [showPassword, setShowPassword] = useState(false);

  const isMobile = useMediaQuery("(max-width:700px)");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const token = getInternalToken();

  const handleCheckIsPublic = () => {
    const hostname = window.location.hostname.split(".");
    const isLocalhost =
      hostname.includes("localhost") || hostname.includes("127.0.0.1");
    const amountItems = isLocalhost ? 1 : 2;
    return hostname.length <= amountItems;
  };
  const isPublic = handleCheckIsPublic();
  // Immediately redirect user to the dashboard
  useEffect(() => {
    if (token) {
      props.history.push(path.DAILY_WORK);
    }
  }, [token, props.history]);

  const handleSubmit = (values, { setErrors }) => {
    login({
      variables: {
        username: values.username,
        password: values.password,
        workspace: values?.workspace,
      },
    }).then((response) => {
      if (!response.data.login.error) {
        if (isPublic) {
          const hostname = window.location.hostname.split(".");
          const endpoint = window.location.hostname;
          const isLocalhost =
            hostname.includes("localhost") || hostname.includes("127.0.0.1");
          const domain = isLocalhost ? `${endpoint}:10002` : endpoint;
          const protocol = isLocalhost ? "http" : "https";
          window.location.href = `${protocol}://${values?.workspace}.${domain}/process-token-dashboard?token=${response.data.login.token}&keepLoggedIn=${values.keepLoggedIn}`;
        } else {
          const token = response.data.login.token;
          saveData("token", token, values.keepLoggedIn);
          props.history.push(destinationPath || path.DAILY_WORK);
        }
      } else {
        let errors = {};
        response.data.login.error.validationErrors.forEach((error) => {
          if (error["field"] === "__all__") {
            errors["username"] = error["messages"].join(" ");
            errors["password"] = error["messages"].join(" ");
          } else {
            errors[error] = error["messages"];
          }
        });

        setErrors(errors);
      }
    });
  };

  return isMobile ? (
    <MobileLogin
      validationSchema={validationSchema}
      handleMouseUpPassword={handleMouseUpPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      handleClickShowPassword={handleClickShowPassword}
      showPassword={showPassword}
      handleSubmit={handleSubmit}
      isPublic={isPublic}
      validationSchemaWorkspace={validationSchemaWorkspace}
    />
  ) : (
    <DesktopLogin
      validationSchema={validationSchema}
      handleMouseUpPassword={handleMouseUpPassword}
      handleMouseDownPassword={handleMouseDownPassword}
      handleClickShowPassword={handleClickShowPassword}
      showPassword={showPassword}
      handleSubmit={handleSubmit}
      isPublic={isPublic}
      validationSchemaWorkspace={validationSchemaWorkspace}
    />
  );
};

export default LoginPage;
