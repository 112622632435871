import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  DAILY_WORK,
  FORGOT_PASSWORD,
  HOME,
  INVITATION_SIGN_UP,
  ONBOARDING_DASHBOARD,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP,
} from "../constants/routes";
import { isCompletedOnboarding } from "../api/queries";
import { useQuery } from "react-apollo";
import { useLocation } from "react-router";

export function useCheckOnboardCompletion() {
  const history = useHistory();
  const location = useLocation();
  const excludedPaths = [
    HOME,
    SIGN_IN,
    SIGN_UP,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    INVITATION_SIGN_UP,
  ];

  const { loading, data } = useQuery(isCompletedOnboarding, {
    fetchPolicy: "no-cache",
    skip:
      excludedPaths.includes(window.location.pathname) ||
      !localStorage.getItem("token"),
  });

  useEffect(() => {
    if (
      !loading &&
      (data?.isCompletedOnboarding === true ||
        data?.isCompletedOnboarding === null) &&
      window.location.pathname === ONBOARDING_DASHBOARD
    ) {
      history.push(DAILY_WORK);
    }
    if (!loading && data?.isCompletedOnboarding === false) {
      if (window.location.pathname !== ONBOARDING_DASHBOARD) {
        history.push(ONBOARDING_DASHBOARD);
      }
    }
  }, [data, loading, history, location]);

  return null;
}
