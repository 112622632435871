import { MIN_PASSWORD_LENGTH } from "constants/index";
import * as Yup from "yup";

export const InvitationSignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("First name is required!"),
  lastName: Yup.string().required("Last name is required!"),
  password1: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`,
    )
    .required("Password is required!")
    .test("passwords-match", "Passwords dont match match", function (value) {
      const { password2 } = this.parent;
      return !(password2 && value && password2 !== value);
    }),

  password2: Yup.string()
    .min(
      MIN_PASSWORD_LENGTH,
      `Confirm password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`,
    )
    .required("Confirm password is required!")
    .test("passwords-match", "Passwords dont match match", function (value) {
      const { password1 } = this.parent;
      return !(password1 && value && password1 !== value);
    }),
});
