import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import bottomWave from "assets/bottom_wave.svg";
import * as path from "constants/routes";
import ForgotPasswordForm from "pages/ForgotPassword/ForgotPasswordForm";
import React from "react";
import { ReactComponent as Logo } from "assets/TUC_logo.svg";
import headerImg from "assets/mobile-fixed-top-vector.svg";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: "100%",
    lineHeight: "28px",
    fontSize: "20px",
    padding: "0px 24px",
    fontFamily: "Lato",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#2D3748",
    textAlign: "center",
  },
}));
const MobileForgotPassword = ({
  handleSubmit,
  isMobile,
  initialEmail,
  sendSuccess,
  setSendSuccess,
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        backgroundColor: "#ffffff",
        backgroundImage: `url(${bottomWave}), url(${headerImg})`,
        backgroundSize: "contain, contain",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "bottom, top",
      }}
    >
      <Box
        style={{
          position: "absolute",
          fontFamily: '"DM Sans", sans-serif',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "-0.02em",
          textAlign: "left",
          color: "#A3AED0",
          cursor: "pointer",
          display: "flex",
          width: "85%",
          margin: "10px 15px",
        }}
      >
        <Logo onClick={() => (window.location.href = "https://tuc24.com")} />
      </Box>
      <Box
        style={{
          height: "100%",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        {sendSuccess ? (
          <Box
            style={{
              padding: "120px  0 0 0 ",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography className={classes.text}>
                A password reset email has been sent to {initialEmail}.
              </Typography>
              <Typography
                className={classes.text}
                style={{
                  color: "#2F80ED",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSendSuccess(false);
                }}
              >
                Resend or change email
              </Typography>
            </Box>
            <Typography
              className={classes.text}
              style={{
                paddingBottom: "50px",
                paddingTop: "20px",
                fontWeight: 500,
              }}
              onClick={() => {
                history.push(path.SIGN_IN);
              }}
            >
              Sing in
            </Typography>
          </Box>
        ) : (
          <ForgotPasswordForm
            handleSubmit={handleSubmit}
            initialEmail={initialEmail}
            isMobile={isMobile}
          />
        )}
      </Box>
    </Box>
  );
};

export default MobileForgotPassword;
