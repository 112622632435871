import { useQuery } from "react-apollo";
import { isCompletedOnboarding, isSubscriptionValid } from "../api/queries";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import {
  FORGOT_PASSWORD,
  HOME,
  INVITATION_SIGN_UP,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  SUBSCRIPTION,
} from "../constants/routes";
import Loader from "../components/Loader";

export function useCheckSubscription() {
  const history = useHistory();
  const location = useLocation();
  const excludedPaths = [
    HOME,
    SIGN_IN,
    SIGN_UP,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    INVITATION_SIGN_UP,
  ];

  const { loading, data } = useQuery(isSubscriptionValid, {
    fetchPolicy: "no-cache",
    skip:
      excludedPaths.includes(window.location.pathname) ||
      !localStorage.getItem("token"),
  });

  useEffect(() => {
    if (!loading && data) {
      if (!data.isSubscriptionValid) {
        history.push(SUBSCRIPTION);
      }
    }
  }, [data, loading, history, location]);
}
