export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const TOKEN = "token";
export const MIN_PASSWORD_LENGTH = 8;
export const MANUAL_TIME_OPERATION = {
  ADD: "ADD",
  SUBSTRACTION: "SUBSTRACTION",
};
export const PROJECT_STATUSES = {
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
};

export const IMG_MAX_SIZE = {
  MAX_SIZE: 1 * 1024 * 1024,
  ERROR_MESSAGE: "Max size 1MB",
};

export const URL_INSTALL_TRACKER = process.env.REACT_APP_URL_INSTALL_TRACKER;

export const STATUS = [
  { value: 1, text: "Active" },
  { value: 2, text: "Archived" },
  { value: 3, text: "Business trip" },
  { value: 4, text: "Sick" },
  { value: 5, text: "On vacation" },
];

export const deviceType = {
  1: "Laptop",
  2: "Smartphone",
  3: "Computer part",
  4: "Device",
  5: "Other",
};

export const ENGLISHLEVEL = [
  { value: "BEGINNER/ELEMENTARY", text: "Beginner/Elementary" },
  { value: "PRE_INTERMEDIATE", text: "Pre Intermediate" },
  { value: "INTERMEDIATE", text: "Intermediate" },
  { value: "UPPER_INTERMEDIATE", text: "Upper Intermediate" },
  { value: "ADVANCED", text: "Advanced" },
  { value: "PROFICIENT", text: "Proficient" },
];

export const OCCUPATIONS = {
  NO_STUDENT: "No student",
  STUDENT: "Student",
};

export const ROLE = [
  { value: 1, text: "Owner" },
  { value: 2, text: "Business manager" },
  { value: 3, text: "Admin" },
  { value: 4, text: "Employee" },
];

export const currencies = [
  { cc: "USD", symbol: "US$", name: "United States dollar" },
  { cc: "EUR", symbol: "\u20ac", name: "European Euro" },
  { cc: "UAH", symbol: "UAH", name: "Ukrainian hryvnia" },
  { cc: "AED", symbol: "\u062f.\u0625;", name: "UAE dirham" },
  { cc: "AFN", symbol: "Afs", name: "Afghan afghani" },
  { cc: "ALL", symbol: "L", name: "Albanian lek" },
  { cc: "AMD", symbol: "AMD", name: "Armenian dram" },
  { cc: "ANG", symbol: "NA\u0192", name: "Netherlands Antillean gulden" },
  { cc: "AOA", symbol: "Kz", name: "Angolan kwanza" },
  { cc: "ARS", symbol: "$", name: "Argentine peso" },
  { cc: "AUD", symbol: "$", name: "Australian dollar" },
  { cc: "AWG", symbol: "\u0192", name: "Aruban florin" },
  { cc: "AZN", symbol: "AZN", name: "Azerbaijani manat" },
  {
    cc: "BAM",
    symbol: "KM",
    name: "Bosnia and Herzegovina konvertibilna marka",
  },
  { cc: "BBD", symbol: "Bds$", name: "Barbadian dollar" },
  { cc: "BDT", symbol: "\u09f3", name: "Bangladeshi taka" },
  { cc: "BGN", symbol: "BGN", name: "Bulgarian lev" },
  { cc: "BHD", symbol: ".\u062f.\u0628", name: "Bahraini dinar" },
  { cc: "BIF", symbol: "FBu", name: "Burundi franc" },
  { cc: "BMD", symbol: "BD$", name: "Bermudian dollar" },
  { cc: "BND", symbol: "B$", name: "Brunei dollar" },
  { cc: "BOB", symbol: "Bs.", name: "Bolivian boliviano" },
  { cc: "BRL", symbol: "R$", name: "Brazilian real" },
  { cc: "BSD", symbol: "B$", name: "Bahamian dollar" },
  { cc: "BTN", symbol: "Nu.", name: "Bhutanese ngultrum" },
  { cc: "BWP", symbol: "P", name: "Botswana pula" },
  { cc: "BYR", symbol: "Br", name: "Belarusian ruble" },
  { cc: "BZD", symbol: "BZ$", name: "Belize dollar" },
  { cc: "CAD", symbol: "$", name: "Canadian dollar" },
  { cc: "CDF", symbol: "F", name: "Congolese franc" },
  { cc: "CHF", symbol: "Fr.", name: "Swiss franc" },
  { cc: "CLP", symbol: "$", name: "Chilean peso" },
  { cc: "CNY", symbol: "\u00a5", name: "Chinese/Yuan renminbi" },
  { cc: "COP", symbol: "Col$", name: "Colombian peso" },
  { cc: "CRC", symbol: "\u20a1", name: "Costa Rican colon" },
  { cc: "CUC", symbol: "$", name: "Cuban peso" },
  { cc: "CVE", symbol: "Esc", name: "Cape Verdean escudo" },
  { cc: "CZK", symbol: "K\u010d", name: "Czech koruna" },
  { cc: "DJF", symbol: "Fdj", name: "Djiboutian franc" },
  { cc: "DKK", symbol: "Kr", name: "Danish krone" },
  { cc: "DOP", symbol: "RD$", name: "Dominican peso" },
  { cc: "DZD", symbol: "\u062f.\u062c", name: "Algerian dinar" },
  { cc: "EEK", symbol: "KR", name: "Estonian kroon" },
  { cc: "EGP", symbol: "\u00a3", name: "Egyptian pound" },
  { cc: "ERN", symbol: "Nfa", name: "Eritrean nakfa" },
  { cc: "ETB", symbol: "Br", name: "Ethiopian birr" },
  { cc: "FJD", symbol: "FJ$", name: "Fijian dollar" },
  { cc: "FKP", symbol: "\u00a3", name: "Falkland Islands pound" },
  { cc: "GBP", symbol: "\u00a3", name: "British pound" },
  { cc: "GEL", symbol: "GEL", name: "Georgian lari" },
  { cc: "GHS", symbol: "GH\u20b5", name: "Ghanaian cedi" },
  { cc: "GIP", symbol: "\u00a3", name: "Gibraltar pound" },
  { cc: "GMD", symbol: "D", name: "Gambian dalasi" },
  { cc: "GNF", symbol: "FG", name: "Guinean franc" },
  { cc: "GQE", symbol: "CFA", name: "Central African CFA franc" },
  { cc: "GTQ", symbol: "Q", name: "Guatemalan quetzal" },
  { cc: "GYD", symbol: "GY$", name: "Guyanese dollar" },
  { cc: "HKD", symbol: "HK$", name: "Hong Kong dollar" },
  { cc: "HNL", symbol: "L", name: "Honduran lempira" },
  { cc: "HRK", symbol: "kn", name: "Croatian kuna" },
  { cc: "HTG", symbol: "G", name: "Haitian gourde" },
  { cc: "HUF", symbol: "Ft", name: "Hungarian forint" },
  { cc: "IDR", symbol: "Rp", name: "Indonesian rupiah" },
  { cc: "ILS", symbol: "\u20aa", name: "Israeli new sheqel" },
  { cc: "INR", symbol: "\u20B9", name: "Indian rupee" },
  { cc: "IQD", symbol: "\u062f.\u0639", name: "Iraqi dinar" },
  { cc: "IRR", symbol: "IRR", name: "Iranian rial" },
  { cc: "ISK", symbol: "kr", name: "Icelandic kr\u00f3na" },
  { cc: "JMD", symbol: "J$", name: "Jamaican dollar" },
  { cc: "JOD", symbol: "JOD", name: "Jordanian dinar" },
  { cc: "JPY", symbol: "\u00a5", name: "Japanese yen" },
  { cc: "KES", symbol: "KSh", name: "Kenyan shilling" },
  { cc: "KGS", symbol: "\u0441\u043e\u043c", name: "Kyrgyzstani som" },
  { cc: "KHR", symbol: "\u17db", name: "Cambodian riel" },
  { cc: "KMF", symbol: "KMF", name: "Comorian franc" },
  { cc: "KPW", symbol: "W", name: "North Korean won" },
  { cc: "KRW", symbol: "W", name: "South Korean won" },
  { cc: "KWD", symbol: "KWD", name: "Kuwaiti dinar" },
  { cc: "KYD", symbol: "KY$", name: "Cayman Islands dollar" },
  { cc: "KZT", symbol: "T", name: "Kazakhstani tenge" },
  { cc: "LAK", symbol: "KN", name: "Lao kip" },
  { cc: "LBP", symbol: "\u00a3", name: "Lebanese lira" },
  { cc: "LKR", symbol: "Rs", name: "Sri Lankan rupee" },
  { cc: "LRD", symbol: "L$", name: "Liberian dollar" },
  { cc: "LSL", symbol: "M", name: "Lesotho loti" },
  { cc: "LTL", symbol: "Lt", name: "Lithuanian litas" },
  { cc: "LVL", symbol: "Ls", name: "Latvian lats" },
  { cc: "LYD", symbol: "LD", name: "Libyan dinar" },
  { cc: "MAD", symbol: "MAD", name: "Moroccan dirham" },
  { cc: "MDL", symbol: "MDL", name: "Moldovan leu" },
  { cc: "MGA", symbol: "FMG", name: "Malagasy ariary" },
  { cc: "MKD", symbol: "MKD", name: "Macedonian denar" },
  { cc: "MMK", symbol: "K", name: "Myanma kyat" },
  { cc: "MNT", symbol: "\u20ae", name: "Mongolian tugrik" },
  { cc: "MOP", symbol: "P", name: "Macanese pataca" },
  { cc: "MRO", symbol: "UM", name: "Mauritanian ouguiya" },
  { cc: "MUR", symbol: "Rs", name: "Mauritian rupee" },
  { cc: "MVR", symbol: "Rf", name: "Maldivian rufiyaa" },
  { cc: "MWK", symbol: "MK", name: "Malawian kwacha" },
  { cc: "MXN", symbol: "$", name: "Mexican peso" },
  { cc: "MYR", symbol: "RM", name: "Malaysian ringgit" },
  { cc: "MZM", symbol: "MTn", name: "Mozambican metical" },
  { cc: "NAD", symbol: "N$", name: "Namibian dollar" },
  { cc: "NGN", symbol: "\u20a6", name: "Nigerian naira" },
  { cc: "NIO", symbol: "C$", name: "Nicaraguan c\u00f3rdoba" },
  { cc: "NOK", symbol: "kr", name: "Norwegian krone" },
  { cc: "NPR", symbol: "NRs", name: "Nepalese rupee" },
  { cc: "NZD", symbol: "NZ$", name: "New Zealand dollar" },
  { cc: "OMR", symbol: "OMR", name: "Omani rial" },
  { cc: "PAB", symbol: "B./", name: "Panamanian balboa" },
  { cc: "PEN", symbol: "S/.", name: "Peruvian nuevo sol" },
  { cc: "PGK", symbol: "K", name: "Papua New Guinean kina" },
  { cc: "PHP", symbol: "\u20b1", name: "Philippine peso" },
  { cc: "PKR", symbol: "Rs.", name: "Pakistani rupee" },
  { cc: "PLN", symbol: "z\u0142", name: "Polish zloty" },
  { cc: "PYG", symbol: "\u20b2", name: "Paraguayan guarani" },
  { cc: "QAR", symbol: "QR", name: "Qatari riyal" },
  { cc: "RON", symbol: "L", name: "Romanian leu" },
  { cc: "RSD", symbol: "din.", name: "Serbian dinar" },
  { cc: "RUB", symbol: "R", name: "Russian ruble" },
  { cc: "SAR", symbol: "SR", name: "Saudi riyal" },
  { cc: "SBD", symbol: "SI$", name: "Solomon Islands dollar" },
  { cc: "SCR", symbol: "SR", name: "Seychellois rupee" },
  { cc: "SDG", symbol: "SDG", name: "Sudanese pound" },
  { cc: "SEK", symbol: "kr", name: "Swedish krona" },
  { cc: "SGD", symbol: "S$", name: "Singapore dollar" },
  { cc: "SHP", symbol: "\u00a3", name: "Saint Helena pound" },
  { cc: "SLL", symbol: "Le", name: "Sierra Leonean leone" },
  { cc: "SOS", symbol: "Sh.", name: "Somali shilling" },
  { cc: "SRD", symbol: "$", name: "Surinamese dollar" },
  { cc: "SYP", symbol: "LS", name: "Syrian pound" },
  { cc: "SZL", symbol: "E", name: "Swazi lilangeni" },
  { cc: "THB", symbol: "\u0e3f", name: "Thai baht" },
  { cc: "TJS", symbol: "TJS", name: "Tajikistani somoni" },
  { cc: "TMT", symbol: "m", name: "Turkmen manat" },
  { cc: "TND", symbol: "DT", name: "Tunisian dinar" },
  { cc: "TRY", symbol: "TRY", name: "Turkish new lira" },
  { cc: "TTD", symbol: "TT$", name: "Trinidad and Tobago dollar" },
  { cc: "TWD", symbol: "NT$", name: "New Taiwan dollar" },
  { cc: "TZS", symbol: "TZS", name: "Tanzanian shilling" },
  { cc: "UGX", symbol: "USh", name: "Ugandan shilling" },
  { cc: "UYU", symbol: "$U", name: "Uruguayan peso" },
  { cc: "UZS", symbol: "UZS", name: "Uzbekistani som" },
  { cc: "VEB", symbol: "Bs", name: "Venezuelan bolivar" },
  { cc: "VND", symbol: "\u20ab", name: "Vietnamese dong" },
  { cc: "VUV", symbol: "VT", name: "Vanuatu vatu" },
  { cc: "WST", symbol: "WS$", name: "Samoan tala" },
  { cc: "XAF", symbol: "CFA", name: "Central African CFA franc" },
  { cc: "XCD", symbol: "EC$", name: "East Caribbean dollar" },
  { cc: "XDR", symbol: "SDR", name: "Special Drawing Rights" },
  { cc: "XOF", symbol: "CFA", name: "West African CFA franc" },
  { cc: "XPF", symbol: "F", name: "CFP franc" },
  { cc: "YER", symbol: "YER", name: "Yemeni rial" },
  { cc: "ZAR", symbol: "R", name: "South African rand" },
  { cc: "ZMK", symbol: "ZK", name: "Zambian kwacha" },
  { cc: "ZWR", symbol: "Z$", name: "Zimbabwean dollar" },
];

export const typeFeeOptions = [
  { text: "fixed", value: 1 },
  { text: "percent", value: 2 },
];

export const financeTypeOptions = [
  { text: "Income", value: 1 },
  { text: "Expense", value: 2 },
];
export const projectStatus = [
  { value: 1, text: "In discussion" },
  { value: 2, text: "In development" },
  { value: 3, text: "On pause" },
  { value: 4, text: "Completed" },
];

export const typeProject = [
  { value: 1, text: "Outsourse" },
  { value: 2, text: "Product" },
  { value: 3, text: "Activity" },
];

export const typeEvent = [
  { value: 1, text: "Remote work" },
  { value: 2, text: "Day off" },
];

export const allEvents = [
  { value: 1, text: "Remote work" },
  { value: 2, text: "Day off" },
  { value: 3, text: "Vacation" },
  { value: 4, text: "Sick leave" },
];

export const allEventsandHoliday = [
  { id: 1, text: "Official Holiday" },
  { id: 2, text: "Birthday" },
  { id: 3, text: "Remote work" },
  { id: 4, text: "Day off" },
  { id: 4, text: "Vacation" },
  { id: 6, text: "Sick leave" },
];

export const vacationOrSick = {
  vacation: 3,
  sick: 4,
};

export const ProjectTypeChoice = {
  1: "Outsourse",
  2: "Product",
  3: "Activity",
};

export const ProjectStatusChoice = {
  1: "In discussion",
  2: "In development",
  3: "On pause",
  4: "Completed",
};

export const WorkPlaceChoice = {
  1: "office",
  2: "remote",
};

export const HolidaysTypeChoice = {
  1: "Official Holiday",
  2: "Birthday",
};

export const EventsTypeChoice = {
  1: "Remote work",
  2: "Day off",
  3: "Vacation",
  4: "Sick leave",
};

export const EventsStatusChoice = {
  1: "Approved",
  2: "Declined",
  3: "Pending",
};

export const userRoleChoice = {
  1: "Owner",
  2: "Business manager",
  3: "Admin",
  4: "Employee",
};

export const userStatusChoice = {
  1: "Active",
  2: "Archived",
  3: "Business trip",
  4: "Sick",
  5: "On vacation",
};

export const MAX_FILES_SIZE = 31457280;

export const LEAD_STATUS = [
  { id: 1, text: "Qualification" },
  { id: 2, text: "Proposal" },
  { id: 4, text: "Evaluation" },
  { id: 6, text: "Negotiation" },
  { id: 5, text: "Closed-Lost" },
  { id: 3, text: "Closed-Won" },
  { id: 7, text: "On pause" },
];

export const LEAD_PRIORITY = [
  { id: 1, text: "Low" },
  { id: 2, text: "Medium" },
  { id: 3, text: "High" },
];

export const POLL_STATUS = [
  { id: 1, text: "Active" },
  { id: 2, text: "Completed" },
];

export const LEAD_STATUS_CHOISE = {
  1: "Qualification",
  2: "Proposal",
  3: "Closed-Won",
  4: "Evaluation",
  5: "Closed-Lost",
  6: "Negotiation",
  7: "On pause",
};

export const LEAD_PRIORITY_CHOISE = {
  1: "Low",
  2: "Medium",
  3: "High",
};

export const FREQUENCY_TYPE = [
  { id: 1, text: "Hour" },
  { id: 2, text: "Day" },
  { id: 3, text: "Week" },
  { id: 4, text: "Month" },
  { id: 5, text: "Quartal" },
];

export const FREQUENCY_TYPE_CHOISE = {
  1: "Hour",
  2: "Day",
  3: "Week",
  4: "Month",
  5: "Quartal",
};

export const NOTIFICATION_TYPE_CHOISE = {
  1: "New employee",
  2: "Start new project",
  3: "End of project",
  4: "Employee's birthday",
  5: "Holiday",
};

export const GENERAL_OPTIONS = [
  { id: "1", text: "1" },
  { id: "2", text: "2" },
  { id: "3", text: "3" },
  { id: "4", text: "4" },
  { id: "5", text: "5" },
  { id: "6", text: "6" },
  { id: "7", text: "7" },
  { id: "8", text: "8" },
  { id: "9", text: "9" },
  { id: "10", text: "10" },
];

export const REVIEW_LABELS = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

export const REVIEW_STATUS = {
  1: "Requested",
  2: "Filled",
};

export const SALARY_SURCHARGE_FREQUENCY = [
  { value: 1, text: "One-time" },
  { value: 2, text: "Once a month" },
  { value: 3, text: "Once every three months" },
  { value: 4, text: "Semiannually" },
  { value: 5, text: "Once a year" },
];

export const timeOptions = [
  {
    value: "00:00:00",
    text: "00:00 AM",
  },
  {
    value: "00:15:00",
    text: "00:15 AM",
  },
  {
    value: "00:30:00",
    text: "00:30 AM",
  },
  {
    value: "00:45:00",
    text: "00:45 AM",
  },
  {
    value: "01:00:00",
    text: "1:00 AM",
  },
  {
    value: "01:15:00",
    text: "1:15 AM",
  },
  {
    value: "01:30:00",
    text: "1:30 AM",
  },
  {
    value: "01:45:00",
    text: "1:45 AM",
  },
  {
    value: "02:00:00",
    text: "2:00 AM",
  },
  {
    value: "02:15:00",
    text: "2:15 AM",
  },
  {
    value: "02:30:00",
    text: "2:30 AM",
  },
  {
    value: "02:45:00",
    text: "2:45 AM",
  },
  {
    value: "03:00:00",
    text: "3:00 AM",
  },
  {
    value: "03:15:00",
    text: "3:15 AM",
  },
  {
    value: "03:30:00",
    text: "3:30 AM",
  },
  {
    value: "03:45:00",
    text: "3:45 AM",
  },
  {
    value: "04:00:00",
    text: "4:00 AM",
  },
  {
    value: "04:15:00",
    text: "4:15 AM",
  },
  {
    value: "04:30:00",
    text: "4:30 AM",
  },
  {
    value: "04:45:00",
    text: "4:45 AM",
  },
  {
    value: "05:00:00",
    text: "5:00 AM",
  },
  {
    value: "05:15:00",
    text: "5:15 AM",
  },
  {
    value: "05:30:00",
    text: "5:30 AM",
  },
  {
    value: "05:45:00",
    text: "5:45 AM",
  },
  {
    value: "06:00:00",
    text: "6:00 AM",
  },
  {
    value: "06:15:00",
    text: "6:15 AM",
  },
  {
    value: "06:30:00",
    text: "6:30 AM",
  },
  {
    value: "06:45:00",
    text: "6:45 AM",
  },
  {
    value: "07:00:00",
    text: "7:00 AM",
  },
  {
    value: "07:15:00",
    text: "7:15 AM",
  },
  {
    value: "07:30:00",
    text: "7:30 AM",
  },
  {
    value: "07:45:00",
    text: "7:45 AM",
  },
  {
    value: "08:00:00",
    text: "8:00 AM",
  },
  {
    value: "08:15:00",
    text: "8:15 AM",
  },
  {
    value: "08:30:00",
    text: "8:30 AM",
  },
  {
    value: "08:45:00",
    text: "8:45 AM",
  },
  {
    value: "09:00:00",
    text: "9:00 AM",
  },
  {
    value: "09:15:00",
    text: "9:15 AM",
  },
  {
    value: "09:30:00",
    text: "9:30 AM",
  },
  {
    value: "09:45:00",
    text: "9:45 AM",
  },
  {
    value: "10:00:00",
    text: "10:00 AM",
  },
  {
    value: "10:15:00",
    text: "10:15 AM",
  },
  {
    value: "10:30:00",
    text: "10:30 AM",
  },
  {
    value: "10:45:00",
    text: "10:45 AM",
  },
  {
    value: "11:00:00",
    text: "11:00 AM",
  },
  {
    value: "11:15:00",
    text: "11:15 AM",
  },
  {
    value: "11:30:00",
    text: "11:30 AM",
  },
  {
    value: "11:45:00",
    text: "11:45 AM",
  },
  {
    value: "12:00:00",
    text: "12:00 PM",
  },
  {
    value: "12:15:00",
    text: "12:15 PM",
  },
  {
    value: "12:30:00",
    text: "12:30 PM",
  },
  {
    value: "12:45:00",
    text: "12:45 PM",
  },
  {
    value: "13:00:00",
    text: "1:00 PM",
  },
  {
    value: "13:15:00",
    text: "1:15 PM",
  },
  {
    value: "13:30:00",
    text: "1:30 PM",
  },
  {
    value: "13:45:00",
    text: "1:45 PM",
  },
  {
    value: "14:00:00",
    text: "2:00 PM",
  },
  {
    value: "14:15:00",
    text: "2:15 PM",
  },
  {
    value: "14:30:00",
    text: "2:30 PM",
  },
  {
    value: "14:45:00",
    text: "2:45 PM",
  },
  {
    value: "15:00:00",
    text: "3:00 PM",
  },
  {
    value: "15:15:00",
    text: "3:15 PM",
  },
  {
    value: "15:30:00",
    text: "3:30 PM",
  },
  {
    value: "15:45:00",
    text: "3:45 PM",
  },
  {
    value: "16:00:00",
    text: "4:00 PM",
  },
  {
    value: "16:15:00",
    text: "4:15 PM",
  },
  {
    value: "16:30:00",
    text: "4:30 PM",
  },
  {
    value: "16:45:00",
    text: "4:45 PM",
  },
  {
    value: "17:00:00",
    text: "5:00 PM",
  },
  {
    value: "17:15:00",
    text: "5:15 PM",
  },
  {
    value: "17:30:00",
    text: "5:30 PM",
  },
  {
    value: "17:45:00",
    text: "5:45 PM",
  },
  {
    value: "18:00:00",
    text: "6:00 PM",
  },
  {
    value: "18:15:00",
    text: "6:15 PM",
  },
  {
    value: "18:30:00",
    text: "6:30 PM",
  },
  {
    value: "18:45:00",
    text: "6:45 PM",
  },
  {
    value: "19:00:00",
    text: "7:00 PM",
  },
  {
    value: "19:15:00",
    text: "7:15 PM",
  },
  {
    value: "19:30:00",
    text: "7:30 PM",
  },
  {
    value: "19:45:00",
    text: "7:45 PM",
  },
  {
    value: "20:00:00",
    text: "8:00 PM",
  },
  {
    value: "20:15:00",
    text: "8:15 PM",
  },
  {
    value: "20:30:00",
    text: "8:30 PM",
  },
  {
    value: "20:45:00",
    text: "8:45 PM",
  },
  {
    value: "21:00:00",
    text: "9:00 PM",
  },
  {
    value: "21:15:00",
    text: "9:15 PM",
  },
  {
    value: "21:30:00",
    text: "9:30 PM",
  },
  {
    value: "21:45:00",
    text: "9:45 PM",
  },
  {
    value: "22:00:00",
    text: "10:00 PM",
  },
  {
    value: "22:15:00",
    text: "10:15 PM",
  },
  {
    value: "22:30:00",
    text: "10:30 PM",
  },
  {
    value: "22:45:00",
    text: "10:45 PM",
  },
  {
    value: "23:00:00",
    text: "11:00 PM",
  },
  {
    value: "23:15:00",
    text: "11:15 PM",
  },
  {
    value: "23:30:00",
    text: "11:30 PM",
  },
  {
    value: "23:45:00",
    text: "11:45 PM",
  },
];

export const ACTIVITY_TYPES = ["A_1", "A_2", "A_3"];

export const HOURS = [
  "0 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 AM",
];

export const ACTIVITIES_NAMES = { A_1: "Call", A_2: "Meeting", A_3: "Task" };
export const WORK_FORMAT = [
  { value: 1, text: "Remote" },
  {
    value: 2,
    text: "Office",
  },
];

export const TYPE_OF_WORK = [
  { value: 1, text: "Full-time" },
  {
    value: 2,
    text: "Part-time",
  },
  {
    value: 3,
    text: "Contract",
  },
];
