import Box from "@material-ui/core/Box";
import bottomWave from "assets/bottom_wave.svg";
import headerImg from "assets/mobile-fixed-top-vector.svg";
import { ResetPasswordForm } from "components/Forms/ResetPasswordForm";
import React from "react";
import { useHistory } from "react-router";
import { ReactComponent as Logo } from "assets/TUC_logo.svg";

const ResetPasswordMobile = ({ isMobile, resetPasswordHandle }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        backgroundColor: "#ffffff",
        backgroundImage: `url(${bottomWave}), url(${headerImg})`,
        backgroundSize: "contain, contain",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "bottom, top",
      }}
    >
      <Box
        style={{
          fontFamily: '"DM Sans", sans-serif',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "-0.02em",
          textAlign: "left",
          color: "#A3AED0",
          cursor: "pointer",
          display: "flex",
          width: "85%",
          margin: "10px 15px",
        }}
      >
        <Logo onClick={() => (window.location.href = "https://tuc24.com")} />
      </Box>
      <Box
        style={{
          height: "100%",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
        }}
      >
        <ResetPasswordForm
          isMobile={isMobile}
          resetPassword={resetPasswordHandle}
        />
      </Box>
    </Box>
  );
};

export default ResetPasswordMobile;
