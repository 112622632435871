import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useApolloClient, useQuery } from "react-apollo";
import { User } from "../../api/queries";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import * as path from "constants/routes";
import Collapse from "@material-ui/core/Collapse";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import ViewListIcon from "@material-ui/icons/ViewList";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DescriptionIcon from "@material-ui/icons/Description";
import NotesIcon from "@material-ui/icons/Notes";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EventIcon from "@material-ui/icons/Event";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Box from "@material-ui/core/Box";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import i18n from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import UserInfo from "./UserInfo";
import Drawer from "@material-ui/core/Drawer";
import { userRoleChoice } from "constants/index";
import SvgIcon from "@material-ui/core/SvgIcon";
import { MailOutlined, MoveToInbox } from "@material-ui/icons";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
const useStyles = makeStyles((theme) => ({
  rootIcon: {
    minWidth: "35px",
    color: "inherit",
  },
  listItemButton: {
    "&:hover": {
      borderRadius: "4px",
    },
  },
  noPadding: {
    padding: 0,
  },

  divider: {
    width: "100%",
    margin: 0,
    color: "grey",
  },
  listGroup: {
    color: "#828282",
    padding: "18px 0px",
  },
  wrap: {
    overflowY: "auto",
  },
  drawer: {
    flexShrink: 0,
  },
  resoursesDrawerPaper: {
    left: "91px",
    top: "115px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  calendarDrawerPaper: {
    left: "91px",
    top: "182px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  calendarDrawerPaperNoAdmin: {
    left: "91px",
    top: "143px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  businessDrawerPaper: {
    left: "91px",
    top: "174px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  reportsDrawerPaper: {
    left: "91px",
    top: "322px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  reportsDrawerPaperNoAdmin: {
    left: "91px",
    top: "242px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  financeDrawerPaper: {
    left: "91px",
    top: "315px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  settingDrawerPaper: {
    left: "91px",
    top: "440px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  communicationDrawerPaper: {
    left: "91px",
    top: "355px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  communicationDrawerPaperNoOwner: {
    left: "91px",
    top: "315px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  communicationDrawerPaperEmployee: {
    left: "91px",
    top: "235px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  settingDrawerNoOwner: {
    left: "91px",
    top: "447px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  settingDrawerPaperEmployee: {
    left: "91px",
    top: "367px",
    height: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
  menuIcon: {
    color: "#828282",
    height: "45px",
    marginTop: "auto",
    marginBottom: "auto",
    "& .activeClass": {
      width: "auto !important",
    },
  },
}));

function CampaignIcon(props) {
  return (
    <SvgIcon {...props} style={{ marginLeft: "3px" }}>
      <path d="M 18 11 v 2 h 4 v -2 h -4 Z m -2 6.61 c 0.96 0.71 2.21 1.65 3.2 2.39 c 0.4 -0.53 0.8 -1.07 1.2 -1.6 c -0.99 -0.74 -2.24 -1.68 -3.2 -2.4 c -0.4 0.54 -0.8 1.08 -1.2 1.61 Z M 20.4 5.6 c -0.4 -0.53 -0.8 -1.07 -1.2 -1.6 c -0.99 0.74 -2.24 1.68 -3.2 2.4 c 0.4 0.53 0.8 1.07 1.2 1.6 c 0.96 -0.72 2.21 -1.65 3.2 -2.4 Z M 4 9 c -1.1 0 -2 0.9 -2 2 v 2 c 0 1.1 0.9 2 2 2 h 1 v 4 h 2 v -4 h 1 l 5 3 V 6 L 8 9 H 4 Z m 11.5 3 c 0 -1.33 -0.58 -2.53 -1.5 -3.35 v 6.69 c 0.92 -0.81 1.5 -2.01 1.5 -3.34 Z" />
    </SvgIcon>
  );
}

const Sidebar = ({ fullMenu, setFullMenu }) => {
  const [open, setOpen] = React.useState(false);
  const [financeIsCollapse, setFinanceIsCollapse] = React.useState(false);
  const [resourcesIsCollapse, setResourcesIsCollapse] = React.useState(false);
  const [businessIsCollapse, setBusinessIsCollapse] = React.useState(false);
  const [calendarIsCollapse, setCalendarIsCollapse] = React.useState(false);
  const [reportsIsCollapse, setReportsIsCollapse] = React.useState(false);
  const [communicationIsCollapse, setCommunicationIsCollapse] =
    React.useState(false);
  const { loading, error, data } = useQuery(User);
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();
  const handleClick = () => {
    setOpen(!open);
    if (!fullMenu) {
      setResourcesIsCollapse(false);
      setBusinessIsCollapse(false);
      setCalendarIsCollapse(false);
      setReportsIsCollapse(false);
      setFinanceIsCollapse(false);
      setCommunicationIsCollapse(false);
    }
  };

  const resourcesIsOpen = () => {
    setResourcesIsCollapse(!resourcesIsCollapse);
    if (!fullMenu) {
      setBusinessIsCollapse(false);
      setCalendarIsCollapse(false);
      setReportsIsCollapse(false);
      setFinanceIsCollapse(false);
      setOpen(false);
      setCommunicationIsCollapse(false);
    }
  };

  const businessIsOpen = () => {
    setBusinessIsCollapse(!businessIsCollapse);
    if (!fullMenu) {
      setResourcesIsCollapse(false);
      setCalendarIsCollapse(false);
      setReportsIsCollapse(false);
      setFinanceIsCollapse(false);
      setOpen(false);
      setCommunicationIsCollapse(false);
    }
  };

  const calendarIsOpen = () => {
    setCalendarIsCollapse(!calendarIsCollapse);
    if (!fullMenu) {
      setResourcesIsCollapse(false);
      setBusinessIsCollapse(false);
      setReportsIsCollapse(false);
      setFinanceIsCollapse(false);
      setOpen(false);
      setCommunicationIsCollapse(false);
    }
  };

  const reportsIsOpen = () => {
    setReportsIsCollapse(!reportsIsCollapse);
    if (!fullMenu) {
      setResourcesIsCollapse(false);
      setBusinessIsCollapse(false);
      setCalendarIsCollapse(false);
      setFinanceIsCollapse(false);
      setOpen(false);
      setCommunicationIsCollapse(false);
    }
  };

  const financeIsOpen = () => {
    setFinanceIsCollapse(!financeIsCollapse);
    if (!fullMenu) {
      setResourcesIsCollapse(false);
      setBusinessIsCollapse(false);
      setCalendarIsCollapse(false);
      setReportsIsCollapse(false);
      setOpen(false);
      setCommunicationIsCollapse(false);
    }
  };

  const communicationIsOpen = () => {
    setCommunicationIsCollapse(!communicationIsCollapse);
    if (!fullMenu) {
      setResourcesIsCollapse(false);
      setBusinessIsCollapse(false);
      setCalendarIsCollapse(false);
      setReportsIsCollapse(false);
      setOpen(false);
      setFinanceIsCollapse(false);
    }
  };

  const closeDrawer = () => {
    if (!fullMenu) {
      setOpen(false);
      setResourcesIsCollapse(false);
      setBusinessIsCollapse(false);
      setCalendarIsCollapse(false);
      setReportsIsCollapse(false);
      setFinanceIsCollapse(false);
      setCommunicationIsCollapse(false);
    }
  };

  const activeStyle = {
    backgroundColor: "#594BF6",
    borderRadius: "2px",
    display: "flex",
    color: "#FFFFFF",
    width: "auto",
  };
  const handleLogout = () => {
    setOpen(false);
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    client.resetStore();
    history.push(path.SIGN_IN);
  };
  if (loading) return null;
  if (error) return null;

  const user = data.me;
  user.isOwner = user.company?.owner?.id === user.id;

  const changeMenuSize = () => {
    if (fullMenu) {
      localStorage.setItem("fullMenu", false);
      setFullMenu(false);
    } else {
      localStorage.setItem("fullMenu", true);
      setFullMenu(true);
    }
  };

  return (
    <div
      className="sidebar-fixed position-fixed"
      style={{ width: fullMenu ? "220px" : "auto" }}
    >
      <Box
        style={{
          display: "flex",
          paddingLeft: "5px",
          height: "61px",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          className={classes.menuIcon}
          onClick={changeMenuSize}
        >
          <MenuIcon />
        </IconButton>
        {fullMenu && <UserInfo user={user} />}
      </Box>
      <hr className={classes.divider} />
      <Box className={classes.wrap}>
        <div className={classes.listGroup}>
          <List component="nav">
            {userRoleChoice[user.role] === "Employee" && (
              <NavLink
                exact={true}
                to={path.WEEKLY_WORK}
                activeClassName="activeClass"
                activeStyle={activeStyle}
                onClick={closeDrawer}
              >
                <ListItem button classes={{ button: classes.listItemButton }}>
                  <ListItemIcon classes={{ root: classes.rootIcon }}>
                    <EqualizerIcon />
                  </ListItemIcon>
                  {fullMenu && <ListItemText primary={i18n.t("Weekly work")} />}
                </ListItem>
              </NavLink>
            )}
            {userRoleChoice[user.role] !== "Employee" ? (
              <NavLink
                exact={true}
                to={path.DAILY_WORK}
                activeClassName="activeClass"
                activeStyle={activeStyle}
                onClick={closeDrawer}
              >
                <ListItem button classes={{ button: classes.listItemButton }}>
                  <ListItemIcon classes={{ root: classes.rootIcon }}>
                    <EqualizerIcon />
                  </ListItemIcon>
                  {fullMenu && <ListItemText primary={i18n.t("Daily work")} />}
                </ListItem>
              </NavLink>
            ) : null}

            <NavLink
              exact={true}
              to={path.TIMESHEET}
              activeClassName="activeClass"
              activeStyle={activeStyle}
              onClick={closeDrawer}
            >
              <ListItem button classes={{ button: classes.listItemButton }}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <AccessAlarmsIcon />
                </ListItemIcon>
                {fullMenu && <ListItemText primary={i18n.t("Timesheet")} />}
              </ListItem>
            </NavLink>

            <NavLink
              exact={true}
              to={path.MAILBOX}
              activeClassName="activeClass"
              activeStyle={activeStyle}
              onClick={closeDrawer}
            >
              <ListItem button classes={{ button: classes.listItemButton }}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <MoveToInbox />
                </ListItemIcon>
                {fullMenu && <ListItemText primary={i18n.t("Mailbox")} />}
              </ListItem>
            </NavLink>
            {userRoleChoice[user.role] !== "Employee" ? (
              <List component="nav" classes={{ root: classes.noPadding }}>
                <ListItem button onClick={resourcesIsOpen}>
                  <ListItemIcon classes={{ root: classes.rootIcon }}>
                    <PeopleAltIcon />
                  </ListItemIcon>
                  {fullMenu && <ListItemText primary={i18n.t("Resources")} />}
                  {fullMenu ? (
                    resourcesIsCollapse ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : resourcesIsCollapse ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </ListItem>
                {!fullMenu ? (
                  <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={resourcesIsCollapse}
                    classes={{
                      paper: classes.resoursesDrawerPaper,
                    }}
                  >
                    <List component="div" disablePadding>
                      <NavLink
                        exact={true}
                        to={path.EMPLOYEES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Employees")} />
                        </ListItem>
                      </NavLink>

                      <NavLink
                        exact={true}
                        to={path.TEAMS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Teams")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.EQUIPMENTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Equipments")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.CANDIDATES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Candidates")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.JOB_POSTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Job Posts")} />
                        </ListItem>
                      </NavLink>
                    </List>
                  </Drawer>
                ) : (
                  <Collapse
                    in={resourcesIsCollapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <NavLink
                        exact={true}
                        to={path.EMPLOYEES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Employees")} />
                        </ListItem>
                      </NavLink>

                      <NavLink
                        exact={true}
                        to={path.TEAMS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Teams")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.EQUIPMENTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Equipments")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.CANDIDATES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Candidates")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.JOB_POSTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Job Posts")} />
                        </ListItem>
                      </NavLink>
                    </List>
                  </Collapse>
                )}
              </List>
            ) : null}

            <List component="nav" classes={{ root: classes.noPadding }}>
              <ListItem button onClick={calendarIsOpen}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <EventIcon />
                </ListItemIcon>
                {fullMenu && <ListItemText primary={i18n.t("Calendar")} />}
                {fullMenu ? (
                  calendarIsCollapse ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : calendarIsCollapse ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </ListItem>
              {!fullMenu ? (
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="left"
                  open={calendarIsCollapse}
                  classes={{
                    paper:
                      userRoleChoice[user.role] !== "Employee"
                        ? classes.calendarDrawerPaper
                        : classes.calendarDrawerPaperNoAdmin,
                  }}
                >
                  {" "}
                  <List component="div" disablePadding>
                    <NavLink
                      exact={true}
                      to={path.CALENDAR}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                      onClick={closeDrawer}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemText primary={i18n.t("Dates")} />
                      </ListItem>
                    </NavLink>
                    <NavLink
                      exact={true}
                      to={path.CALENDAR_OVERVIEW}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                      onClick={closeDrawer}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemText primary={i18n.t("Overview")} />
                      </ListItem>
                    </NavLink>
                  </List>
                </Drawer>
              ) : (
                <Collapse in={calendarIsCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink
                      exact={true}
                      to={path.CALENDAR}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemIcon
                          classes={{ root: classes.rootIcon }}
                        ></ListItemIcon>
                        <ListItemText primary={i18n.t("Dates")} />
                      </ListItem>
                    </NavLink>
                    <NavLink
                      exact={true}
                      to={path.CALENDAR_OVERVIEW}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemIcon
                          classes={{ root: classes.rootIcon }}
                        ></ListItemIcon>

                        <ListItemText primary={i18n.t("Overview")} />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>
              )}
            </List>
            {userRoleChoice[user.role] !== "Employee" && (
              <List component="nav" classes={{ root: classes.noPadding }}>
                <ListItem button onClick={businessIsOpen}>
                  <ListItemIcon classes={{ root: classes.rootIcon }}>
                    <ViewListIcon />
                  </ListItemIcon>
                  {fullMenu && <ListItemText primary={i18n.t("Business")} />}
                  {fullMenu ? (
                    businessIsCollapse ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : businessIsCollapse ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </ListItem>
                {!fullMenu ? (
                  <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={businessIsCollapse}
                    classes={{
                      paper: classes.businessDrawerPaper,
                    }}
                  >
                    {" "}
                    <List component="div" disablePadding>
                      <NavLink
                        exact={true}
                        to={path.PROSPECTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Prospects")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.LEADS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Leads")} />
                        </ListItem>
                      </NavLink>

                      <NavLink
                        exact={true}
                        to={path.CLIENTS_AND_CONTACTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText
                            primary={i18n.t("Clients And Contacts")}
                          />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.PROJECTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Projects")} />
                        </ListItem>
                      </NavLink>
                    </List>
                  </Drawer>
                ) : (
                  <Collapse
                    in={businessIsCollapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <NavLink
                        exact={true}
                        to={path.PROSPECTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Prospects")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.LEADS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Leads")} />
                        </ListItem>
                      </NavLink>

                      <NavLink
                        exact={true}
                        to={path.CLIENTS_AND_CONTACTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText
                            primary={i18n.t("Clients And Contacts")}
                          />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.PROJECTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{ root: classes.rootIcon }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Projects")} />
                        </ListItem>
                      </NavLink>
                    </List>
                  </Collapse>
                )}
              </List>
            )}
            <NavLink
              exact={true}
              to={path.DOCUMENTS}
              activeClassName="activeClass"
              activeStyle={activeStyle}
              onClick={closeDrawer}
            >
              <ListItem button classes={{ button: classes.listItemButton }}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <DescriptionIcon />
                </ListItemIcon>
                {fullMenu && <ListItemText primary={i18n.t("Documents")} />}
              </ListItem>
            </NavLink>
            <List component="nav" classes={{ root: classes.noPadding }}>
              <ListItem button onClick={reportsIsOpen}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <NotesIcon />
                </ListItemIcon>
                {fullMenu && <ListItemText primary={i18n.t("Reports")} />}
                {fullMenu ? (
                  reportsIsCollapse ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : reportsIsCollapse ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </ListItem>
              {!fullMenu ? (
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="left"
                  open={reportsIsCollapse}
                  classes={{
                    paper:
                      userRoleChoice[user.role] !== "Employee"
                        ? classes.reportsDrawerPaper
                        : classes.reportsDrawerPaperNoAdmin,
                  }}
                >
                  <List component="div" disablePadding>
                    <NavLink
                      exact={true}
                      to={path.REPORTS_RAW}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                      onClick={closeDrawer}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemText primary={i18n.t("Raw")} />
                      </ListItem>
                    </NavLink>
                    {(user.isOwner || user.role === 3) && (
                      <NavLink
                        exact={true}
                        to={path.APPROVED_REPORTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Approved")} />
                        </ListItem>
                      </NavLink>
                    )}
                  </List>
                </Drawer>
              ) : (
                <Collapse in={reportsIsCollapse} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink
                      exact={true}
                      to={path.REPORTS_RAW}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemIcon
                          classes={{ root: classes.rootIcon }}
                        ></ListItemIcon>
                        <ListItemText primary={i18n.t("Raw")} />
                      </ListItem>
                    </NavLink>
                    {(user.isOwner || user.role === 3) && (
                      <NavLink
                        exact={true}
                        to={path.APPROVED_REPORTS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{ root: classes.rootIcon }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Approved")} />
                        </ListItem>
                      </NavLink>
                    )}
                  </List>
                </Collapse>
              )}
            </List>
            {user.isOwner && (
              <List component="nav" classes={{ root: classes.noPadding }}>
                <ListItem button onClick={financeIsOpen}>
                  <ListItemIcon classes={{ root: classes.rootIcon }}>
                    <MonetizationOnIcon />
                  </ListItemIcon>
                  {fullMenu && <ListItemText primary={i18n.t("Finance")} />}
                  {fullMenu ? (
                    financeIsCollapse ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  ) : financeIsCollapse ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </ListItem>
                {!fullMenu ? (
                  <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={financeIsCollapse}
                    classes={{
                      paper: classes.financeDrawerPaper,
                    }}
                  >
                    <List component="div" disablePadding>
                      <NavLink
                        exact={true}
                        to={path.FINANCE_OVERVIEW}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{
                            button: classes.listItemButton,
                          }}
                        >
                          <ListItemText primary={i18n.t("Overview")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.FINANCE_INCOME_AND_EXPENSES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          onClick={closeDrawer}
                          classes={{
                            button: classes.listItemButton,
                          }}
                        >
                          <ListItemText
                            primary={i18n.t("Income and Expenses")}
                          />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.FINANCE_SALARY}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          onClick={closeDrawer}
                          classes={{
                            button: classes.listItemButton,
                          }}
                        >
                          <ListItemText primary={i18n.t("Salaries")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.INVOICES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Invoices")} />
                        </ListItem>
                      </NavLink>
                    </List>
                  </Drawer>
                ) : (
                  <Collapse in={financeIsCollapse} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <NavLink
                        exact={true}
                        to={path.FINANCE_OVERVIEW}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{
                            button: classes.listItemButton,
                          }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Overview")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.FINANCE_INCOME_AND_EXPENSES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{
                            button: classes.listItemButton,
                          }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText
                            primary={i18n.t("Income and Expenses")}
                          />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.FINANCE_SALARY}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{
                            button: classes.listItemButton,
                          }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Salaries")} />
                        </ListItem>
                      </NavLink>{" "}
                      <NavLink
                        exact={true}
                        to={path.INVOICES}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{ root: classes.rootIcon }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Invoices")} />
                        </ListItem>
                      </NavLink>
                    </List>
                  </Collapse>
                )}
              </List>
            )}
            <List component="nav" classes={{ root: classes.noPadding }}>
              <ListItem button onClick={communicationIsOpen}>
                <ListItemIcon classes={{ root: classes.rootIcon }}>
                  <CampaignIcon />
                </ListItemIcon>
                {fullMenu && <ListItemText primary={i18n.t("Communication")} />}
                {fullMenu ? (
                  communicationIsCollapse ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : communicationIsCollapse ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </ListItem>
              {!fullMenu ? (
                <Drawer
                  className={classes.drawer}
                  variant="persistent"
                  anchor="left"
                  open={communicationIsCollapse}
                  classes={{
                    paper: user.isOwner
                      ? classes.communicationDrawerPaper
                      : userRoleChoice[user.role] !== "Employee"
                        ? classes.communicationDrawerPaperNoOwner
                        : classes.communicationDrawerPaperEmployee,
                  }}
                >
                  <List component="div" disablePadding>
                    <NavLink
                      exact={true}
                      to={path.NOTIFICATIONS}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                      onClick={closeDrawer}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemText primary={i18n.t("Notifications")} />
                      </ListItem>
                    </NavLink>
                    {(user.isOwner || user.role === 3) && (
                      <NavLink
                        exact={true}
                        to={path.POLLS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Polls")} />
                        </ListItem>
                      </NavLink>
                    )}
                    <NavLink
                      exact={true}
                      to={path.REVIEWS}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                      onClick={closeDrawer}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemText primary={i18n.t("Reviews")} />
                      </ListItem>
                    </NavLink>
                  </List>
                </Drawer>
              ) : (
                <Collapse
                  in={communicationIsCollapse}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {" "}
                    <NavLink
                      exact={true}
                      to={path.NOTIFICATIONS}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemIcon
                          classes={{
                            root: classes.rootIcon,
                          }}
                        ></ListItemIcon>
                        <ListItemText primary={i18n.t("Notifications")} />
                      </ListItem>
                    </NavLink>
                    {(user.isOwner || user.role === 3) && (
                      <NavLink
                        exact={true}
                        to={path.POLLS}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{
                              root: classes.rootIcon,
                            }}
                          ></ListItemIcon>

                          <ListItemText primary={i18n.t("Polls")} />
                        </ListItem>
                      </NavLink>
                    )}
                    <NavLink
                      exact={true}
                      to={path.REVIEWS}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemIcon
                          classes={{ root: classes.rootIcon }}
                        ></ListItemIcon>

                        <ListItemText primary={i18n.t("Reviews")} />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>
              )}
            </List>
          </List>
        </div>
        <hr className={classes.divider} />
        <div className={classes.listGroup}>
          <List component="nav" classes={{ root: classes.noPadding }}>
            <ListItem button onClick={handleClick}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <SettingsIcon />
              </ListItemIcon>
              {fullMenu && <ListItemText primary={i18n.t("Settings")} />}
              {fullMenu ? (
                open ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : open ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </ListItem>
            {!fullMenu ? (
              <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                  paper: user.isOwner
                    ? classes.settingDrawerPaper
                    : userRoleChoice[user.role] !== "Employee"
                      ? classes.settingDrawerNoOwner
                      : classes.settingDrawerPaperEmployee,
                }}
              >
                <List component="div" disablePadding>
                  <NavLink
                    exact={true}
                    to={path.PROFILE}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                    onClick={closeDrawer}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemText primary={i18n.t("Account")} />
                    </ListItem>
                  </NavLink>
                  {user.isOwner && (
                    <>
                      <NavLink
                        exact={true}
                        to={path.SUBSCRIPTION}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Subscription")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.COMPANY}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                        onClick={closeDrawer}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemText primary={i18n.t("Company")} />
                        </ListItem>
                      </NavLink>
                    </>
                  )}
                  {(user.isOwner || user.role === 3) && (
                    <NavLink
                      exact={true}
                      to={path.EMAIL_SYNC}
                      activeClassName="activeClass"
                      activeStyle={activeStyle}
                      onClick={closeDrawer}
                    >
                      <ListItem
                        button
                        classes={{ button: classes.listItemButton }}
                      >
                        <ListItemText primary={i18n.t("Email Sync")} />
                      </ListItem>
                    </NavLink>
                  )}
                </List>
              </Drawer>
            ) : (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink
                    exact={true}
                    to={path.PROFILE}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{ root: classes.rootIcon }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Account")} />
                    </ListItem>
                  </NavLink>
                  {user.isOwner && (
                    <>
                      <NavLink
                        exact={true}
                        to={path.SUBSCRIPTION}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{ root: classes.rootIcon }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Subscription")} />
                        </ListItem>
                      </NavLink>
                      <NavLink
                        exact={true}
                        to={path.COMPANY}
                        activeClassName="activeClass"
                        activeStyle={activeStyle}
                      >
                        <ListItem
                          button
                          classes={{ button: classes.listItemButton }}
                        >
                          <ListItemIcon
                            classes={{ root: classes.rootIcon }}
                          ></ListItemIcon>
                          <ListItemText primary={i18n.t("Company")} />
                        </ListItem>
                      </NavLink>
                    </>
                  )}
                  <NavLink
                    exact={true}
                    to={path.EMAIL_SYNC}
                    activeClassName="activeClass"
                    activeStyle={activeStyle}
                  >
                    <ListItem
                      button
                      classes={{ button: classes.listItemButton }}
                    >
                      <ListItemIcon
                        classes={{ root: classes.rootIcon }}
                      ></ListItemIcon>
                      <ListItemText primary={i18n.t("Email Sync")} />
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse>
            )}
          </List>
          <List component="nav" classes={{ root: classes.noPadding }}>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon classes={{ root: classes.rootIcon }}>
                <ExitToAppOutlinedIcon
                  style={{ transform: "rotate(180deg)" }}
                />
              </ListItemIcon>
              {fullMenu && <ListItemText primary={i18n.t("Log out")} />}
            </ListItem>
          </List>
        </div>
      </Box>
    </div>
  );
};

export default Sidebar;
