import React, { useState, useEffect } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";

import ButtonRoot from "components/Buttons/ButtonRoot";

import { withPromise, getMilliseconds } from "utils/index";
import "react-circular-progressbar/dist/styles.css";

const ModalFooter = (props) => {
  const [percentage, setPercentage] = useState(0);
  const [inProgress, toggleProgress] = useState(false);
  const [displayDeleteIcon, setDeleteIcon] = useState(true);

  const onDeleteInstance = React.useCallback(
    () => props.handleDelete(),
    [props.handleDelete],
  );

  useEffect(() => {
    if (percentage === 0) return;

    const timer = setTimeout(() => {
      onDeleteInstance();
      setPercentage(0);
      toggleProgress(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [percentage, onDeleteInstance]);

  const handleSetTime = async () => {
    setDeleteIcon(false);
    await withPromise(toggleProgress(true));

    setPercentage(100);
  };

  const stopTimer = () => {
    toggleProgress(false);
    setDeleteIcon(true);
    setPercentage(0);
  };

  return (
    <MuiDialogActions {...props}>
      <Box className="d-flex justify-content-between">
        {props.isOpen && props.handleDelete && (
          <Box className="mr-2">
            {!inProgress ? (
              <Box
                className={props.classes.deleteButton}
                onClick={handleSetTime}
                style={{ visibility: displayDeleteIcon ? "visible" : "hidden" }}
              >
                <DeleteForeverIcon style={{ color: "#fff" }} />
              </Box>
            ) : (
              <Button
                style={{
                  width: 40,
                  height: "36.5px",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={stopTimer}
              >
                <CircularProgressbarWithChildren
                  value={percentage}
                  styles={buildStyles({
                    rotation: 0,
                    pathTransitionDuration: getMilliseconds(3000),
                    pathColor: `#fff`,
                    trailColor: "#594BF6",
                    backgroundColor: "#594BF6",
                  })}
                >
                  <CloseIcon />
                </CircularProgressbarWithChildren>
              </Button>
            )}
          </Box>
        )}
        <Box className="d-flex">
          <ButtonRoot
            text={props.closeButtonTitle || "Close"}
            onClick={props.onClose}
            type="button"
            className={props.classes.warningButton}
            disabled={props.isLoading}
          />
          {props.backButtonTitle && (
            <ButtonRoot
              text={props.backButtonTitle || "Back"}
              onClick={props.handleBack}
              type="button"
              className={props.classes.warningButton}
              disabled={props.backButtonDisabled}
            />
          )}
          {props.handleSubmit && (
            <ButtonRoot
              text={props.submitButtonTitle || "Save"}
              onClick={props.handleSubmit}
              disabled={props.isLoading}
            />
          )}
        </Box>
      </Box>
    </MuiDialogActions>
  );
};

const ModalActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    borderTop: "1px solid #ced4da",
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  warningButton: {
    backgroundColor: "#6c757d",

    marginRight: 8,
    "&:hover": {
      backgroundColor: "#646b71",
    },
  },
  deleteButton: {
    display: "flex",
    alignItems: "center",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    border: "2px solid #f44336",
    background: "#f44336",
    cursor: "pointer",
    borderRadius: 4,
    height: "100%",
    "&:hover": {
      background: "#f32c1e",
    },
  },
}))(ModalFooter);

export default ModalActions;
