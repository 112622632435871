import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import SpaceSideBar from "./SpaceSideBar";
import { useQuery } from "react-apollo";
import { getSpaceInfo } from "api/queries";
import AllContent from "./SpaceTabs/AllContent";
import Overview from "./SpaceTabs/Overview";
import Content from "./SpaceTabs/Content";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import SpaceSettings from "./SpaceTabs/SpaceSettings";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "8px",
    marginBottom: "30px",
    backgroundColor: "white",
    border: "1px solid #E0E0E0",
    width: "100%",
    display: "flex",
    minHeight: "100vh",
  },
}));
const Space = (props) => {
  const history = useHistory();
  const { state } = useLocation();
  const id = history?.location?.pathname?.split("/").pop();
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();
  const [contentData, setContentData] = useState();
  const [space, setSpace] = useState(null);
  const [width, setWidth] = useState(240);
  const [editState, setEditState] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { data, loading, refetch } = useQuery(getSpaceInfo, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });
  const [isHovered, setHovered] = useState(false);
  const [isOpenSideBar, setOpenSideBar] = useState(true);
  const startWidthRef = useRef(width);
  const startXRef = useRef(0);
  const handleSwitchSideBar = () => {
    setOpenSideBar((prevState) => !prevState);
    setHovered(false);
  };
  const handleSwitchHoverEnter = () => {
    setHovered(true);
  };
  const handleSwitchHoverLeave = () => {
    setHovered(false);
  };
  useEffect(() => {
    if (state?.__typename === "WhiteboardType") {
      const fileId = state?.fileId;
      if (!loading && data && fileId) {
        const item = data?.spaceInfo?.whiteboards?.find(
          (item) => item?.id === fileId,
        );
        setTabValue(4);
        setContentData(item);
      }
    } else {
      const fileId = state?.fileId;
      if (!loading && data && fileId) {
        const item = data?.spaceInfo?.files?.find(
          (item) => item?.id === fileId,
        );
        setTabValue(4);
        setContentData(item);
      }
    }
  }, [data, loading, state?.fileId, state?.__typename]);
  useEffect(() => {
    if (!loading && data) {
      const mergedData = [
        ...data?.spaceInfo?.files,
        ...data?.spaceInfo?.whiteboards,
      ];
      const newData = data?.spaceInfo;
      newData.files = mergedData?.sort((a, b) => {
        return new Date(a?.createdAt) - new Date(b?.createdAt);
      });
      setSpace(newData);
    }
  }, [data, loading]);
  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);
  if (loading && space) return <p>Loading</p>;

  const handleOpenFile = (item) => {
    setTabValue(4);
    setContentData(item);
  };
  const tabs = [
    {
      id: 0,
      name: "Overview",
      space: space,
      component: <Overview space={space} refetch={refetch} />,
    },
    {
      id: 1,
      name: "All content",
      component: (
        <AllContent
          space={space}
          user={props?.user}
          handleOpenFile={handleOpenFile}
        />
      ),
    },
    {
      id: 2,
      name: "Space Settings",
      component: (
        <SpaceSettings user={props?.user} space={space} refetch={refetch} />
      ),
    },
    {
      id: 3,
      name: "Shortcuts",
    },
    {
      id: 4,
      name: "Content",
      component: (
        <Content
          width={width}
          contentData={contentData}
          space={space}
          refetch={refetch}
          setContentData={setContentData}
          setEditState={setEditState}
          editState={editState}
        />
      ),
    },
  ];
  const handleMouseDown = (e) => {
    if (isHovered) {
      setIsDragging(true);
      startWidthRef.current = width;
      startXRef.current = e.clientX;
      e.preventDefault();
    }
  };
  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startXRef.current;
      const newWidth = Math.max(0, startWidthRef.current + deltaX);
      setWidth(newWidth);
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Box className={classes.wrapper}>
      <Box
        style={{
          width: isOpenSideBar ? `${width}px` : 0,
          overflow: "hidden",
          whiteSpace: "nowrap",
          boxSizing: "border-box",
        }}
      >
        <SpaceSideBar
          setTabValue={setTabValue}
          tabValue={tabValue}
          tabs={tabs}
          id={id}
          space={data?.spaceInfo}
          setContentData={setContentData}
          setEditState={setEditState}
          editState={editState}
          refetch={refetch}
          contentData={contentData}
          isOpenSideBar={isOpenSideBar}
        />
      </Box>
      <Box
        style={{
          width: "30px",
          display: "flex",
          justifyContent: "center",
          margin: " -8px 5px 5px",
        }}
        onMouseEnter={handleSwitchHoverEnter}
        onMouseLeave={handleSwitchHoverLeave}
        onMouseDown={handleMouseDown}
      >
        <div
          style={{
            backgroundColor: isHovered ? "#0B65E4" : "rgb(203 ,203 ,203)",
            width: "1px",
            cursor: "ew-resize",
          }}
        >
          {" "}
          <Box
            style={{
              position: "absolute",
              transform: "translateX(-50%)",
              zIndex: 1,
              marginTop: "25px",
            }}
          >
            {isHovered ? (
              isOpenSideBar ? (
                <p
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#0B65E4",
                    borderRadius: "50%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "opacity 0.3s ease, bottom 0.3s ease",
                  }}
                >
                  <ArrowBackIosIcon
                    style={{
                      width: "14px",
                      height: "14px",
                      color: "white",
                      paddingLeft: "3px",
                    }}
                    onClick={() => handleSwitchSideBar()}
                  />
                </p>
              ) : (
                <p
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#0B65E4",
                    borderRadius: "50%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "opacity 0.3s ease, bottom 0.3s ease",
                  }}
                >
                  <ArrowForwardIosIcon
                    style={{
                      width: "14px",
                      height: "14px",
                      color: "white",
                      paddingRight: "3px",
                    }}
                    onClick={() => handleSwitchSideBar()}
                  />
                </p>
              )
            ) : null}
          </Box>
        </div>
      </Box>

      <Box style={{ width: `calc(100% - ${width}px)` }}>
        {tabs.map((tab) => {
          if (tab.id === tabValue) {
            return (
              <div style={{ width: `100%` }} key={tab.id}>
                {tab?.component}
              </div>
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
};

export default Space;
