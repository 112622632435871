import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import { Box, Card, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import HorizontalTimeBar from "components/HorizontalTimeBar";
import CreateActivityForm from "components/Forms/CreateActivityForm";
import * as Yup from "yup";
import { ExpandLess } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  time: { padding: "10px 10px" },
  hideActivityBarCard: {
    padding: "4px",
    borderRadius: 0,
    borderTop: "1px solid #D9D9D9",
    borderLeft: "1px solid #D9D9D9",
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
}));

const Activity = ({
  managers,
  manager,
  onSubmit,
  activities,
  maxHeight,
  fetchActivities,
  setTabValue,
}) => {
  const classes = useStyles();
  const [activityTimeBarOpen, setActivityTimeBarOpen] = useState(true);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Task name is required"),
    manager: Yup.object().required("Manager is required").nullable(),
  });

  const submitHandler = (values, resetForm, setFieldValue) => {
    onSubmit(values);
    setTabValue(0);
    resetForm();
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  return (
    <Formik
      initialValues={{
        title: "Call",
        activityType: 1,
        startDate: moment().format("YYYY-MM-DD"),
        startTime: { value: "00:00", text: "h.mm PM" },
        endDate: moment().format("YYYY-MM-DD"),
        endTime: { value: "00:00", text: "h.mm PM" },
        note: "",
        manager: manager,
        done: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => submitHandler(values, resetForm)}
      enableReinitialize={true}
    >
      {({
        values,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
        resetForm,
      }) => (
        <Box style={{ display: "flex" }}>
          <Box
            style={{
              width: activityTimeBarOpen ? "70%" : "100%",
              transition: `width 0.3s ease-in-out`,
            }}
          >
            <CreateActivityForm
              values={values}
              errors={errors}
              touched={touched}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              managers={managers}
              resetForm={resetForm}
            />
          </Box>
          <Box
            style={{
              width: activityTimeBarOpen ? "30%" : "40px",
              transition: `width 0.3s ease-in-out`,
            }}
          >
            <Card className={classes.hideActivityBarCard}>
              <Tooltip
                title={"Hide activity bar"}
                onClick={() => setActivityTimeBarOpen((prev) => !prev)}
              >
                <IconButton size="small">
                  <ExpandLess
                    style={{
                      transform: activityTimeBarOpen
                        ? "rotate(90deg)"
                        : "rotate(-90deg)",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <div style={{ marginLeft: "6px" }}>Activity schedule</div>
            </Card>
            <HorizontalTimeBar
              height={maxHeight - 110 || 500}
              isOpen={activityTimeBarOpen}
              activities={activities}
              values={values}
            />
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default Activity;
