import React, { useState } from "react";
import { Box, IconButton, InputLabel, Typography } from "@material-ui/core";
import { DeleteForeverOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import ButtonRoot from "components/Buttons/ButtonRoot";
import TextEditor from "components/TextEditor/TextEditor";
import { Field, Form } from "formik";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getCandidateTemplates, getLeadTemplates } from "api/queries";
import { Query, useApolloClient } from "react-apollo";
import Loading from "components/Loader";
import SelectAutocomplete from "components/Select/SelectAutocomplete";
import { convertToRaw } from "draft-js";
import ModalManageEmailTemplate from "components/Modals/ModalManageEmailTemplate";
import {
  createCandidateEmailTemplate,
  createLeadEmailTemplate,
} from "api/mutations";
import { EditorState, convertFromRaw } from "draft-js";
import { emailRegex } from "utils";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
const useStyles = makeStyles((theme) => ({
  textEditor: {
    "& .demo-wrapper": {
      display: "flex",
      flexDirection: "column-reverse",
      justifyContent: "start",
      borderTop: "1px solid #D9D9D9",
    },
    "& .rdw-editor-toolbar": {
      margin: 0,
      borderBottom: "1px solid #D9D9D9",
    },
    "& .demo-editor": {
      backgroundColor: "white",
    },
  },
  formRow: {
    borderTop: "1px solid #D9D9D9",
    padding: "4px 15px",
    color: "#747474",
    fontSize: "18px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
  formLabel: { margin: "0 10px 0 0" },
  sendButton: {
    width: "135px",
  },
  bottomBar: {
    display: "flex",
    justifyContent: "end",
    padding: "7px 15px",
    backgroundColor: "white",
  },
  emailsSelect: {
    width: "100%",
  },
}));

const CreateEmailForm = ({
  values,
  errors,
  touched,
  setFieldValue,
  emails,
  resetForm,
  type,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const apolloClient = useApolloClient();
  const [emailsOptions, setEmailsOptions] = useState(emails);
  const [emailTextFieldValue, setEmailTextFieldValue] = useState("");
  const [emailsFieldError, setEmailsFieldError] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onModalCloseHandler = () => {
    setModalOpen((prevState) => !prevState);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveEmailTemplateHandle = async (variables) => {
    let mutation;
    if (type === "candidate") {
      mutation = createCandidateEmailTemplate;
    } else if (type === "lead") {
      mutation = createLeadEmailTemplate;
    }
    const response = await apolloClient.mutate({
      mutation: mutation,
      variables: {
        content: JSON.stringify(
          convertToRaw(variables.letter.getCurrentContent()),
        ),
        templateName: `Draft ${variables.subject}`,
        subject: variables.subject,
      },
    });
  };

  const getQueryHandle = () => {
    let query;
    if (type === "candidate") {
      query = getCandidateTemplates;
    } else if (type === "lead") {
      query = getLeadTemplates;
    }
    return query;
  };

  const emailTextFieldChangeHandler = (e) => {
    if (
      e.target.value[e.target.value?.length - 1] === " " ||
      e.key === "Enter"
    ) {
      const newEmailOptionValue = e.target.value.replace(/\s/g, "");
      if (!emailRegex.test(newEmailOptionValue)) {
        setEmailsFieldError(true);
        return;
      }
      setEmailTextFieldValue("");
      const newEmailOption = {
        value: newEmailOptionValue,
        title: newEmailOptionValue,
      };
      setFieldValue("emails", [...values.emails, newEmailOption]);
      setEmailsOptions([...emailsOptions, newEmailOption]);
    } else {
      setEmailTextFieldValue(e.target.value);
      setEmailsFieldError(false);
    }
  };

  return (
    <Form style={{ height: "100%", overflowY: "hidden" }}>
      <Box className={classes.formRow}>
        <InputLabel className={classes.formLabel}>To:</InputLabel>
        <Field
          name="emails"
          render={({ field }) => (
            <Autocomplete
              className={classes.emailsSelect}
              multiple
              size="small"
              options={emailsOptions}
              getOptionLabel={(option) => option.title}
              value={field.value}
              onChange={(event, newValue) => {
                setFieldValue("emails", newValue);
              }}
              inputValue={emailTextFieldValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={emailsFieldError || (touched.emails && errors.emails)}
                  onChange={emailTextFieldChangeHandler}
                  onKeyPress={emailTextFieldChangeHandler}
                  variant="outlined"
                  placeholder="Select email"
                />
              )}
            />
          )}
        />
      </Box>
      <Box className={classes.formRow}>
        <InputLabel className={classes.formLabel} style={{ marginTop: "2px" }}>
          Subject:
        </InputLabel>
        <Field
          style={{
            border: 0,
            paddingLeft: 0,
            fontSize: "18px",
          }}
          variant="outlined"
          value={values.subject}
          onChange={(e) => setFieldValue("subject", e.target.value)}
        />
      </Box>
      <Box className={classes.formRow}>
        <InputLabel
          style={{
            cursor: "pointer",
            paddingTop: "8px",
            paddingBottom: "8px",
            alignItems: "center",
            transition: "background-color 0.3s",
            backgroundColor: "transparent",
          }}
          className={classes.formLabel}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = "#e8e8e8")
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
          }
        >
          Choose template
          <ArrowDropDownIcon
            style={{
              cursor: "pointer",
              padding: 0,
              verticalAlign: "middle",
            }}
          />
        </InputLabel>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Query query={getQueryHandle()} fetchPolicy="no-cache">
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div className="wrap-loading">
                    <Loading size={20} />
                  </div>
                );

              if (error) return `Error! ${error.message}`;
              const dataTemplates = data?.[`${type}EmailTemplates`];
              const templates = data?.[`${type}EmailTemplates`].map((el) => ({
                value: el.id,
                text: el.templateName,
              }));

              return (
                <MenuItem>
                  <Box className={classes.field}>
                    <InputLabel id="template" className={classes.label}>
                      Templates
                    </InputLabel>
                    <SelectAutocomplete
                      id="template"
                      name="template"
                      placeholder="Choose template"
                      error={errors.template && touched.template}
                      value={values.template}
                      setValue={(e) => {
                        setFieldValue("template", e);
                        const el = dataTemplates.find(
                          (item) => item.id === e.value,
                        );
                        setFieldValue(
                          "letter",
                          EditorState.createWithContent(
                            convertFromRaw(JSON.parse(el.content)),
                          ),
                        );
                        setFieldValue("subject", el.subject);
                      }}
                      options={templates}
                    />
                    {errors.template && touched.template ? (
                      <Typography className={classes.template}>
                        {errors.template}
                      </Typography>
                    ) : null}
                  </Box>
                </MenuItem>
              );
            }}
          </Query>
          <MenuItem onClick={() => saveEmailTemplateHandle(values)}>
            Save draft as template
          </MenuItem>
          <MenuItem onClick={onModalCloseHandler}>Manage templates</MenuItem>
        </Menu>
      </Box>
      <Box className={classes.textEditor}>
        <TextEditor
          changeEditor={(val) => {
            setFieldValue("letter", val);
          }}
          editorState={values.letter}
          minHeight="140px"
        />
      </Box>
      <Box className={classes.bottomBar}>
        <IconButton
          onClick={resetForm}
          size="small"
          style={{ marginRight: "14px" }}
        >
          <DeleteForeverOutlined fontSize={"large"} />
        </IconButton>

        <ButtonRoot
          onClick={() => console.log(values)}
          type="submit"
          className={classes.sendButton}
          text="Send"
        />
      </Box>
      <ModalManageEmailTemplate
        type={type}
        isOpen={isModalOpen}
        onClose={onModalCloseHandler}
      />
    </Form>
  );
};

export default CreateEmailForm;
