import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DesktopSignUp from "./DesktopSignUp";
import moment from "moment";
import MobileSignUp from "./MobileSignUp";
import * as path from "constants/routes";
import { useMutation } from "react-apollo";
import { register } from "../../api/mutations";
const companySizeList = [
  { value: "1-10", text: "1-10" },
  { value: "11-20", text: "11-20" },
  { value: "21-50", text: "21-50" },
  { value: "51-100", text: "51-100" },
  { value: "100+", text: "100+" },
];
const SignUp = (props) => {
  const isMobile = useMediaQuery("(max-width:699px)");

  const [registerMutation] = useMutation(register);

  const [acceptService, setAcceptService] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword1 = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword2 = (event) => {
    event.preventDefault();
  };
  const createNewAccount = (values, setErrors) => {
    const dateJoined = moment().utc().format();
    registerMutation({
      variables: {
        ...values,
        industry: parseInt(values.industry.value),
        companySize: parseInt(values.companySize.value),
        isAdmin: true,
        dateJoined: dateJoined,
      },
    }).then((response) => {
      if (response.data.register.success) {
        props.history.push(path.SIGN_IN);
      } else {
        let errors = {};
        response.data.register.error.validationErrors.map((error) => {
          errors[error.field] = error["messages"];
          return null;
        });
        setErrors.setErrors(errors);
      }
    });
  };

  return isMobile ? (
    <MobileSignUp
      companySizeList={companySizeList}
      submitForm={createNewAccount}
      acceptService={acceptService}
      setAcceptService={setAcceptService}
      showPassword1={showPassword1}
      showPassword2={showPassword2}
      handleClickShowPassword1={handleClickShowPassword1}
      handleMouseDownPassword1={handleMouseDownPassword1}
      handleMouseUpPassword1={handleMouseUpPassword1}
      handleClickShowPassword2={handleClickShowPassword2}
      handleMouseUpPassword2={handleMouseUpPassword2}
      handleMouseDownPassword2={handleMouseDownPassword2}
    />
  ) : (
    <DesktopSignUp
      companySizeList={companySizeList}
      submitForm={createNewAccount}
      acceptService={acceptService}
      setAcceptService={setAcceptService}
      showPassword1={showPassword1}
      showPassword2={showPassword2}
      handleClickShowPassword1={handleClickShowPassword1}
      handleMouseDownPassword1={handleMouseDownPassword1}
      handleMouseUpPassword1={handleMouseUpPassword1}
      handleClickShowPassword2={handleClickShowPassword2}
      handleMouseUpPassword2={handleMouseUpPassword2}
      handleMouseDownPassword2={handleMouseDownPassword2}
    />
  );
};

export default SignUp;
