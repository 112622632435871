import { makeStyles } from "@material-ui/styles";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

const Stepper = (props) => {
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      margin: 0,
      "& > div": {
        padding: 0,
        height: "26px",
        cursor: "pointer",
        clipPath: "polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 10% 50%, 0 0)",
        "&:nth-child(1)": {
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
          clipPath: "polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0% 50%, 0 0)",
        },
        "&:nth-last-child(1)": {
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          clipPath:
            "polygon(100% 0, 100% 48%, 100% 100%, 0 100%, 10% 47%, 0 0)",
        },
      },
    },
    element: {
      background: "#E4E4E5",
      color: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  return (
    <div style={props.style} id="stepper" className={classes.wrapper}>
      {props.schema.map((a, i) => (
        <Tooltip
          key={i}
          title={
            <>
              <span> {props?.stages[i]?.title} </span>
              {props.type && props?.labels[i] === null ? (
                <>
                  {" "}
                  <br />
                  This {props.type} has not been in this stage yet!{" "}
                </>
              ) : null}{" "}
            </>
          }
          placement="top"
          direction="center"
          arrow>
          <div
            style={{
              width: a[0],
              ...(a[1] ? { clipPath: `polygon(${a[1]})` } : {}),
              ...(props.stage > i
                ? { color: "white", background: "#2D8647" }
                : {}),
            }}
            className={classes.element}
            onClick={() => props.changeStage(i + 1)}>
            {props?.labels ? props?.labels[i] : " "}
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default Stepper;
