import { InputAdornment, styled } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { registerByInvitation } from "api/mutations";
import ButtonRoot from "components/Buttons/ButtonRoot";
import * as path from "constants/routes";
import { ErrorMessage, Form, Formik } from "formik";
import moment from "moment/moment";
import { InvitationSignUpValidation } from "pages/InvitationSignUp/validation";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/TUC_logo.svg";
import rightBottomBlob from "assets/rightBottomBlob.svg";
import leftBottomBlob from "assets/leftBottomBlob.svg";
import HeaderIcon from "assets/headerInvitation.svg";
import { useMutation, useQuery } from "react-apollo";
import { useHistory } from "react-router";
import { saveData } from "../../utils";
import { getUserByToken } from "api/queries";

const StyledCard = styled(Card)({
  background: "white",
  boxShadow: "0px 4px 17px 0px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  width: "610px",
  minHeight: "200px",
  margin: "60px auto 0px",
  "&:last-child": {
    marginBottom: "25px",
  },
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "hidden",
    overflowY: "hidden",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${HeaderIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#EAF0FA",
    backgroundPositionY: "bottom",
    paddingBottom: "230px",
  },
  formContainer: {
    padding: "50px 100px",
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "650px",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "60px",
    marginTop: "35px",
  },
  acceptService: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.02em",
      color: "#2B3674",
    },
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "6px",
  },
  label: {
    color: "rgba(0,0,0,0.32)",
    fontFamily: "Poppins",
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "-0.01em",
    "&.MuiInputLabel-shrink": {
      fontSize: "18px",
      color: theme.palette.primary.main,
    },
  },
  inputField: {
    height: "40px",
    "&:before": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0,0,0,0.32)",
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 500,
    width: "100%",
    fontSize: "24px",
    padding: "24px 12px",
    fontFamily: "Lato",
  },
  haveAccount: {
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontSize: "16px",
    letterSpacing: "-0.01em",
  },
}));

const InvitationSignUp = (props) => {
  const classes = useStyles();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [invitationToken, setInvitationToken] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [registerByInvitationMutation] = useMutation(registerByInvitation);
  const history = useHistory();

  useEffect(() => {
    let paramsString = props.location.search;
    let searchParams = new URLSearchParams(paramsString);
    let inviteToken = searchParams.get("token");
    let company = searchParams.get("company_name");
    setCompanyName(company);
    setInvitationToken(inviteToken);
  }, [props]);

   const { data } = useQuery(getUserByToken, {
        variables: { token: invitationToken },
        skip: !invitationToken,
        errorPolicy: 'ignore',
    });

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const handleMouseUpPassword1 = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const handleMouseUpPassword2 = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (values) => {
    const dateJoined = moment().utc().format("YYYY-MM-DD");
    registerByInvitationMutation({
      variables: {
        ...values,
        dateJoined: dateJoined,
        token: invitationToken,
      },
    })
      .then((response) => {
        if (response.data.registerByInvitation.success) {
          const token = response.data.registerByInvitation.token;
          saveData("token", token, false);
          props.history.push(path.ONBOARDING_DASHBOARD);
        } else {
          let error = "";
          response.data.registerByInvitation.errors.forEach((er) => {
            error = error + " " + er;
          });
          props.history.push(path.SIGN_IN);
        }
      })
      .catch((error) => {
        console.log("error-------", error);
      });
  };

  return (
    <Box
      style={{
        minHeight: "100vh",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        backgroundImage: `url(${HeaderIcon}), url(${leftBottomBlob}), url(${rightBottomBlob})`,
        backgroundPosition: "top, left bottom, right bottom",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "contain, contain, contain",
        backgroundColor: "white",
      }}
    >
      <Box
        style={{
          width: "80vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Logo
            onClick={() => (window.location.href = "https://tuc24.com")}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Typography
            className={classes.haveAccount}
            style={{
              fontSize: "16px",
            }}
          >
            Already have an account?
          </Typography>
          <ButtonRoot
            className={classes.button}
            onClick={() => {
              history.push(path.SIGN_IN);
            }}
            text={"Sign in"}
          />
        </Box>
      </Box>
      <Formik
        initialValues={{
          password2: "",
          password1: "",
          lastName: data?.userByToken?.lastName || "",
          firstName: data?.userByToken?.firstName || "",
        }}
        validationSchema={InvitationSignUpValidation}
        onSubmit={(values, { setErrors }) => {
          handleSubmit(values);
        }}
        enableReinitialize={true}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
          submitForm,
        }) => (
          <Form autoComplete="off">
            <Box className={classes.cardBox}>
              <StyledCard>
                <Box className={classes.formContainer}>
                  <Typography className={classes.title}>
                    Join to&nbsp;<strong>{companyName}</strong>&nbsp;company
                  </Typography>
                  <FormControl
                    sx={{ m: 1 }}
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.firstName && touched.firstName)}
                  >
                    <InputLabel className={classes.label} htmlFor="firstName">
                      First name
                    </InputLabel>
                    <Input
                      id="firstName"
                      type={"text"}
                      className={classes.inputField}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      autoCorrect="off"
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="firstName" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1 }}
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.lastName && touched.lastName)}
                  >
                    <InputLabel className={classes.label} htmlFor="lastName">
                      Last name
                    </InputLabel>
                    <Input
                      id="lastName"
                      type={"text"}
                      className={classes.inputField}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      autoCorrect="off"
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="lastName" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.password1 && touched.password1)}
                  >
                    <InputLabel className={classes.label} htmlFor="password1">
                      Password
                    </InputLabel>
                    <Input
                      id="password1"
                      type="text"
                      className={classes.inputField}
                      value={values.password1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      autoCorrect="off"
                      inputProps={{
                        style: {
                          WebkitTextSecurity: showPassword1 ? "none" : "disc",
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword1
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            onMouseUp={handleMouseUpPassword1}
                          >
                            {showPassword1 ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="password1" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.password2 && touched.password2)}
                  >
                    <InputLabel className={classes.label} htmlFor="password2">
                      Confirm password
                    </InputLabel>
                    <Input
                      id="password2"
                      type="text"
                      className={classes.inputField}
                      value={values.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                      autoCorrect="off"
                      inputProps={{
                        style: {
                          WebkitTextSecurity: showPassword2 ? "none" : "disc",
                        },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword2
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            onMouseUp={handleMouseUpPassword2}
                          >
                            {showPassword2 ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="password2" />
                    </Typography>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "50px",
                  }}
                >
                  <ButtonRoot
                    type={"submit"}
                    text={"Continue"}
                    style={{
                      textTransform: "none",
                      width: "153px",
                      height: "48px",
                    }}
                    className={classes.button}
                  />
                </Box>
              </StyledCard>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default InvitationSignUp;