import { styled } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import signupBackground from "assets/signup-background.svg";
import ButtonRoot from "components/Buttons/ButtonRoot";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import React from "react";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email!")
    .required("Email is required!")
    .nullable(),
});

const StyledCard = styled(Card)({
  background: "white",
  boxShadow: "0px 4px 17px 0px #00000040",
  borderRadius: "10px",
  minHeight: "200px",
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "hidden",
    overflowY: "hidden",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${signupBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#EAF0FA",
    backgroundPositionY: "bottom",
    paddingBottom: "230px",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    width: "100%",
    lineHeight: "28px",
    fontSize: "20px",
    padding: "0px 24px",
    fontFamily: "Lato",
  },
  formContainer: {
    gap: "30px",
    display: "flex",
    flexDirection: "column",
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "547px",
    padding: "10px",
  },
  inputField: {
    height: "40px",
    "&:before": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0,0,0,0.32)",
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "6px",
    width: "209px",
    height: "48px",
  },
  text: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#2D3748",
    textAlign: "justify",
  },
  cancel: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "none",
    color: "black",
    display: "flex",
    background: "#E0E0E0",
    cursor: "pointer",
    borderRadius: "6px",
    width: "209px",
    height: "48px",
    "&:hover": {
      background: "#dadada",
    },
  },
}));

const ForgotPasswordForm = ({ handleSubmit, initialEmail, isMobile }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        email: initialEmail || null,
      }}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleBlur, errors, touched, values }) => (
        <Form>
          {isMobile ? (
            <>
              <Box
                style={{
                  padding: "100px 30px 10px",
                  gap: "20px",
                }}
                className={classes.formContainer}
              >
                <Typography className={classes.title}>
                  Forgot Password
                </Typography>
                <Typography className={classes.text}>
                  If you have lost your password, we can help you reset it. To
                  initiate password reset process, please enter your email in
                  the box below and click on the button.
                </Typography>

                <FormControl
                  style={{ minHeight: "80px" }}
                  fullWidth
                  error={Boolean(errors.email && touched.email)}
                >
                  <InputLabel className={classes.label} htmlFor="email">
                    Email
                  </InputLabel>
                  <Input
                    id="email"
                    type={"email"}
                    className={classes.inputField}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      className: classes.inputField,
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="error"
                    style={{ marginTop: "4px" }}
                  >
                    <ErrorMessage name="email" />
                  </Typography>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                  gap: "10px",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                {" "}
                <ButtonRoot
                  className={classes.button}
                  text={"Reset Password"}
                  style={{
                    width: "90%",
                  }}
                  type={"submit"}
                />
                <ButtonRoot
                  className={classes.cancel}
                  text={"Cancel"}
                  type={"submit"}
                  onClick={() => (window.location.href = "https://tuc24.com")}
                  style={{
                    width: "90%",
                  }}
                />
              </Box>
            </>
          ) : (
            <Box className={classes.cardBox}>
              <StyledCard>
                <Box
                  className={classes.formContainer}
                  style={{
                    padding: "40px 60px 10px",
                  }}
                >
                  <Typography className={classes.title}>
                    Forgot Password
                  </Typography>
                  <Typography className={classes.text}>
                    If you have lost your password, we can help you reset it. To
                    initiate password reset process, please enter your email in
                    the box below and click on the button.
                  </Typography>

                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.email && touched.email)}
                  >
                    <InputLabel className={classes.label} htmlFor="email">
                      Email
                    </InputLabel>
                    <Input
                      id="email"
                      type={"email"}
                      className={classes.inputField}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        className: classes.inputField,
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="email" />
                    </Typography>
                  </FormControl>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  {" "}
                  <ButtonRoot
                    className={classes.button}
                    text={"Reset Password"}
                    type={"submit"}
                  />
                </Box>
              </StyledCard>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};
export default ForgotPasswordForm;
