import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ButtonRoot from "components/Buttons/ButtonRoot";
import { ResetPasswordForm } from "components/Forms/ResetPasswordForm";
import ResetPasswordMobile from "pages/ResetPassword/ResetPasswordMobile";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useMutation } from "react-apollo";
import { resetPassword } from "api/mutations";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "assets/TUC_logo.svg";
import blobLeft from "assets/blobLeft.svg";
import blobRight from "assets/blobRight.svg";

import * as path from "constants/routes";
import bottomWave from "assets/bottom_wave.svg";
const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    textTransform: "none",
    height: "48px",
    borderRadius: "6px",
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [resetPasswordMutation] = useMutation(resetPassword);
  const [token, setToken] = useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      setToken(token);
    }
  }, []);

  const resetPasswordHandle = useCallback(
    async (values) => {
      try {
        const response = await resetPasswordMutation({
          variables: {
            ...values,
            token: token,
          },
        });
        if (response.data.resetPassword.success) {
          history.push(path.SIGN_IN);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [resetPasswordMutation, token],
  );
  return isMobile ? (
    <ResetPasswordMobile
      resetPasswordHandle={resetPasswordHandle}
      isMobile={isMobile}
    />
  ) : (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        backgroundImage: `url(${bottomWave}), url(${blobLeft}), url(${blobRight})`,
        backgroundSize: "contain, contain, contain",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundPosition: "bottom, left top, right center",
        backgroundColor: "#fff",
      }}
    >
      <Box
        style={{
          width: "80vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Logo
            onClick={() => (window.location.href = "https://tuc24.com")}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <ButtonRoot
            className={classes.button}
            variant="outlined"
            onClick={() => {
              history.push(path.SIGN_IN);
            }}
            text={"Sign in"}
          />
        </Box>
      </Box>
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResetPasswordForm resetPassword={resetPasswordHandle} />
      </Box>
    </Box>
  );
};

export default ResetPassword;
