import React, { forwardRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { useForm, useWatch } from "react-hook-form";
import { currencies } from "constants/index";
import TextField from "components/EditableCollapsedList/FieldsComponents/TextField";
import Select from "components/EditableCollapsedList/FieldsComponents/Select";
import { getIndustryType, getLeadSource } from "api/queries";
import EditableCollapsedList from "components/EditableCollapsedList";
import { formatName } from "utils";
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import {
  AccountCircleOutlined,
  ApartmentOutlined,
  AttachMoneyOutlined,
  Facebook,
  LaptopOutlined,
  LinkedIn,
  LocalOfferOutlined,
  LocalPhoneOutlined,
  PersonOutline,
  VpnKeyOutlined,
} from "@material-ui/icons";
import { ReactComponent as Skype } from "assets/skype-logo.svg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
  },
  listOfValues: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  deleteFieldButton: {
    marginLeft: "4px",
    padding: "8px",
  },
  multiFieldIcon: { position: "absolute", left: "7.2%", color: "#747474" },
}));

const Detail = forwardRef(
  ({ lead, onSubmit, contacts, labels, createContact, createLabel }, ref) => {
    const classes = useStyles();

    const { register, control, setValue, handleSubmit, getValues } = useForm();
    const { contactPersons } = useWatch({ control });

    const currenciesOptions = currencies.map((a) => ({
      value: a.cc,
      text: a.cc,
    }));

    const contactsOptions = contacts.map((a) => ({
      value: a.id,
      text: formatName(a),
    }));

    const labelsOptions = labels?.map((a) => ({
      value: a.id,
      text: a.value,
    }));

    const createContactHandler = async (value, index) => {
      const contact = await createContact(value);
      setValue(
        "contactPersons",
        getValues().contactPersons?.map((a, i) =>
          i == index
            ? {
                value: contact?.id,
                text: formatName(contact),
              }
            : a,
        ),
      );
    };

    const createLabelHandler = async (value, index) => {
      const label = await createLabel(value);
      setValue("label", { value: label?.id, text: label.name });
    };

    const addNewContactField = () =>
      setValue("contactPersons", [...getValues()?.contactPersons, null]);

    const removeContactField = (index) => () => {
      const values = getValues()?.contactPersons;
      values?.splice(index, 1);
      setValue("contactPersons", values);
    };

    const filteredContactPersons = () =>
      contactPersons?.filter((a) => !!a).filter((a) => !!a);

    const filteredContactPersonsPrimaryContact = () => {
      const newContactPersons = values?.Person?.contactPersons;
      const primaryContactIndex = newContactPersons
        ?.map((contact) => contact?.value)
        ?.indexOf(lead?.primaryContact?.id + "");
      if (typeof primaryContactIndex !== "undefined") {
        const removedElement = newContactPersons.splice(primaryContactIndex, 1);
        newContactPersons.unshift(...removedElement);
      }
      return newContactPersons;
    };

    const values = {
      Person: {
        linkedin: lead?.primaryContact?.linkedin,
        facebook: lead?.primaryContact?.facebook,
        phone: lead?.primaryContact?.phone,
        skype: lead?.primaryContact?.skype,
        contactPersons: lead?.contactPersons?.length
          ? lead?.contactPersons.map((a) => ({
              value: a.id,
              text: formatName(a),
            }))
          : [null],
      },
      Details: {
        organization: lead?.organization,
        label: labelsOptions?.find((a) => a?.value == lead?.label?.id) || null,
        keyNeed: lead?.keyNeed,
        value: lead?.value,
        website: lead?.website,
        currency:
          currenciesOptions.find((a) => a.text === lead?.currency) || null,
        source: {
          value: lead?.sourceType?.id,
          text: lead?.sourceType?.value,
        },
        industry: {
          value: lead?.industryType?.id,
          text: lead?.industryType?.value,
        },
      },
    };

    const schema = [
      {
        title: "Person",
        items: [
          {
            key: "Contacts",
            component: lead?.contactPersons.length ? (
              <div
                className={classes.listOfValues}
                style={{ height: (lead?.contactPersons?.length || 1) * 48 }}
              >
                {filteredContactPersonsPrimaryContact()?.map((item, index) => (
                  <div style={{ paddingTop: "2px" }} key={item?.value}>
                    <AccountCircleOutlined className={classes.multiFieldIcon} />
                    {item?.text}
                  </div>
                ))}
              </div>
            ) : null,
            editableComponent: (
              <div className={classes.listOfValues}>
                {contactPersons &&
                  contactPersons?.map((item, index) => (
                    <div
                      key={`${index} ${item?.value}`}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <AccountCircleOutlined
                        className={classes.multiFieldIcon}
                      />
                      <Select
                        style={{ height: "44px" }}
                        fullWidth
                        disableClearable
                        noOptionsTitle={"Add"}
                        onNoOptionsTextClickHandler={createContactHandler}
                        options={contactsOptions.filter(
                          (a) =>
                            !contactPersons
                              .map((a) => a?.value)
                              .includes(a?.value),
                        )}
                        control={control}
                        name={"contactPersons"}
                        placeholder="Contact Person"
                        defaultValue={
                          lead?.contactPersons?.length > 0
                            ? contactsOptions[index]
                            : null
                        }
                        nameIndex={index + ""}
                      />
                      <IconButton
                        disabled={filteredContactPersons()?.length <= 1}
                        onClick={removeContactField(index)}
                        className={classes.deleteFieldButton}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </div>
                  ))}
              </div>
            ),
            underFieldMessage: "+ Add contact",
            underFieldMessageClickHandler: addNewContactField,
            componentHeight: (lead?.contactPersons?.length || 1) * 48,
            editableComponentHeight:
              (typeof contactPersons !== "undefined"
                ? contactPersons?.length || 1
                : lead?.contactPersons?.length || 1) * 48,
          },
          {
            key: "Phone",
            icon: <LocalPhoneOutlined />,
            isLink: false,
            component: lead?.primaryContact?.phone,
            editableComponent: (
              <TextField
                register={register}
                name="phone"
                placeholder="Phone"
                fullWidth
              />
            ),
          },
          {
            key: "Link to Linkedin",
            icon: <LinkedIn />,
            isLink: true,
            component: lead?.primaryContact?.linkedin,
            editableComponent: (
              <TextField
                register={register}
                name="linkedin"
                placeholder="Link to Linkedin"
                fullWidth
              />
            ),
          },
          {
            key: "Link to Facebook",
            icon: <Facebook />,
            isLink: true,
            component: lead?.primaryContact?.facebook,
            editableComponent: (
              <TextField
                register={register}
                name="facebook"
                placeholder="Link to Facebook"
                fullWidth
              />
            ),
          },
          {
            key: "Skype",
            icon: (
              <div
                style={{
                  maxWidth: "24px",
                  minWidth: "24px",
                  height: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skype
                  style={{
                    fill: lead?.primaryContact?.skype ? "#262831" : "#747474",
                    marginLeft: "0.2px",
                    minHeight: "19px",
                    maxHeight: "19px",
                    minWidth: "19px",
                    maxWidth: "19px",
                  }}
                />
              </div>
            ),
            component: lead?.primaryContact?.skype,
            editableComponent: (
              <TextField
                register={register}
                name="skype"
                placeholder="Skype"
                fullWidth
              />
            ),
          },
        ],
      },

      {
        title: "Details",
        items: [
          {
            key: "Organization",
            icon: <ApartmentOutlined />,
            component: lead?.organization,
            editableComponent: (
              <TextField
                register={register}
                name="organization"
                placeholder="Organization name"
                fullWidth
              />
            ),
          },
          {
            key: "Key",
            icon: <VpnKeyOutlined />,
            component: lead?.keyNeed,
            editableComponent: (
              <TextField
                register={register}
                name="keyNeed"
                placeholder="Key"
                fullWidth
              />
            ),
          },
          {
            key: "Value",
            icon: <AttachMoneyOutlined />,
            component: `${lead?.value || 0} ${lead?.currency || ""}`,
            editableComponent: (
              <div
                className="d-flex justify-content-between"
                style={{ width: "100%" }}
              >
                <TextField
                  style={{ width: "60%" }}
                  placeholder="Value"
                  register={register}
                  name="value"
                  type="number"
                />
                <Select
                  style={{ width: "40%", marginLeft: "8px" }}
                  placeholder="Currency"
                  disableClearable
                  defaultValue={currenciesOptions.find(
                    (a) => a.text === lead?.currency,
                  )}
                  options={currenciesOptions}
                  control={control}
                  name={"currency"}
                />
              </div>
            ),
          },
          {
            key: "Label",
            icon: <LocalOfferOutlined />,
            component: lead?.label?.name,
            editableComponent: (
              <Select
                placeholder="Choose label type"
                fullWidth
                options={labelsOptions}
                control={control}
                name="label"
                // actionButtons={[{ title: "Add new label", onClick: () => {} }]}
                noOptionsTitle={"Add"}
                onNoOptionsTextClickHandler={createLabelHandler}
              />
            ),
          },
          {
            key: "Source",
            icon: <LaptopOutlined />,
            component: lead?.sourceType?.value,
            editableComponent: (
              <Select
                placeholder="Choose source type"
                disableClearable
                fullWidth
                fetchPolicy="no-cache"
                query={getLeadSource}
                getOptions={(data) =>
                  data.leadSelector.sourceType.map((el) => ({
                    value: el.id,
                    text: el.value,
                  }))
                }
                control={control}
                name={"source"}
              />
            ),
          },
          {
            key: "Industry",
            icon: <ApartmentOutlined />,
            component: lead?.industryType?.value,
            editableComponent: (
              <Select
                placeholder="Choose industry"
                disableClearable
                fullWidth
                fetchPolicy="no-cache"
                query={getIndustryType}
                getOptions={(data) =>
                  data.leadSelector.industryType.map((el) => ({
                    value: el.id,
                    text: el.value,
                  }))
                }
                control={control}
                name={"industry"}
              />
            ),
          },
        ],
      },
    ];

    return (
      <div ref={ref} className={classes.wrapper}>
        <EditableCollapsedList
          schema={schema}
          onSubmit={(values) => onSubmit(values)}
          handleSubmit={handleSubmit}
          values={values}
          setValue={setValue}
        />
      </div>
    );
  },
);

export default Detail;
