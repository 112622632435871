import Box from "@material-ui/core/Box";
import * as path from "constants/routes";
import { useHistory } from "react-router";
import authArrowLeft from "../../assets/auth-arrow-left.svg";
import Typography from "@material-ui/core/Typography";
import ButtonRoot from "../../components/Buttons/ButtonRoot";
import { ErrorMessage, Form, Formik } from "formik";
import { RegisterValidation } from "./validation";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { InputAdornment, styled } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Query } from "react-apollo";
import { allIndustries } from "../../api/queries";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import signupBackground from "../../assets/signup-background.svg";
import Card from "@material-ui/core/Card";
import { ReactComponent as Logo } from "assets/TUC_logo.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const StyledCard = styled(Card)({
  background: "white",
  boxShadow: "0px 4px 17px 0px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  width: "610px",
  minHeight: "200px",
  margin: "60px auto 0px",
  "&:last-child": {
    marginBottom: "25px",
  },
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "hidden",
    overflowY: "hidden",
    width: "100vw",
    minHeight: "100vh",
    background: `url(${signupBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundColor: "#EAF0FA",
    backgroundPositionY: "bottom",
    paddingBottom: "230px",
  },
  formContainer: {
    padding: "50px 100px",
  },
  cardBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "650px",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "60px",
    marginTop: "35px",
  },
  acceptService: {
    "& .MuiFormControlLabel-label": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.02em",
      color: "#2B3674",
    },
  },
  button: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    textTransform: "none",
    padding: "10px 16px",
    borderRadius: "6px",
  },
  label: {
    color: "rgba(0,0,0,0.32)",
    fontFamily: "Poppins",
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: "16px",
    letterSpacing: "-0.01em",
    "&.MuiInputLabel-shrink": {
      fontSize: "18px",
      color: theme.palette.primary.main,
    },
  },
  inputField: {
    height: "40px",
    "&:before": {
      borderBottom: "1px solid rgba(0,0,0,0.13)",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(0,0,0,0.32)",
    },
    "&:after": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    width: "100%",
    fontSize: "24px",
    padding: "24px",
    fontFamily: "Lato",
  },
  haveAccount: {
    fontWeight: 500,
    fontFamily: "Poppins",
    lineHeight: "24px",
    fontSize: "16px",
    letterSpacing: "-0.01em",
  },
}));
const DesktopSignUp = ({
  companySizeList,
  submitForm,
  acceptService,
  setAcceptService,
  showPassword1,
  showPassword2,
  handleClickShowPassword2,
  handleMouseDownPassword2,
  handleClickShowPassword1,
  handleMouseDownPassword1,
  handleMouseUpPassword1,
  handleMouseUpPassword2,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const isTablet = useMediaQuery("(max-width:850px)");

  return (
    <Box className={classes.wrapper}>
      <Box
        style={{
          width: isTablet ? "90vw" : "80vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Logo
            onClick={() => (window.location.href = "https://tuc24.com")}
            style={{
              cursor: "pointer",
            }}
          />
          <span
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: '"DM Sans", sans-serif',
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "-0.02em",
              textAlign: "left",
              color: "#A3AED0",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          >
            <img src={`${authArrowLeft}`} alt="" />
            Back
          </span>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Typography
            className={classes.haveAccount}
            style={{
              fontSize: isTablet ? "14px" : "16px",
            }}
          >
            Already have an account?
          </Typography>
          <ButtonRoot
            className={classes.button}
            onClick={() => {
              history.push(path.SIGN_IN);
            }}
            text={"Sign in"}
          />
        </Box>
      </Box>
      <Formik
        initialValues={{
          companySize: null,
          industry: null,
          companySite: "",
          companyName: "",
          password2: "",
          password1: "",
          email: "",
          lastName: "",
          firstName: "",
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, { setErrors }) => {
          submitForm(values, { setErrors });
        }}
      >
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
          setFieldValue,
        }) => (
          <Form>
            <Box className={classes.cardBox}>
              <StyledCard>
                <Box className={classes.formContainer}>
                  <Typography className={classes.title}>Profile</Typography>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.firstName && touched.firstName)}
                  >
                    <InputLabel className={classes.label} htmlFor="firstName">
                      First name
                    </InputLabel>
                    <Input
                      id="firstName"
                      type={"text"}
                      className={classes.inputField}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        className: classes.inputField,
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="firstName" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1 }}
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.lastName && touched.lastName)}
                  >
                    <InputLabel className={classes.label} htmlFor="lastName">
                      Last name
                    </InputLabel>
                    <Input
                      id="lastName"
                      type={"text"}
                      className={classes.inputField}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="lastName" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.email && touched.email)}
                  >
                    <InputLabel className={classes.label} htmlFor="email">
                      Email address
                    </InputLabel>
                    <Input
                      id="email"
                      className={classes.inputField}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="email" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.password1 && touched.password1)}
                  >
                    <InputLabel className={classes.label} htmlFor="password1">
                      Password
                    </InputLabel>
                    <Input
                      id="password1"
                      type={showPassword1 ? "text" : "password"}
                      className={classes.inputField}
                      value={values.password1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword1
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword1}
                            onMouseDown={handleMouseDownPassword1}
                            onMouseUp={handleMouseUpPassword1}
                          >
                            {showPassword1 ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="password1" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.password2 && touched.password2)}
                  >
                    <InputLabel className={classes.label} htmlFor="password2">
                      Confirm password
                    </InputLabel>
                    <Input
                      id="password2"
                      type={showPassword2 ? "text" : "password"}
                      className={classes.inputField}
                      value={values.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={
                              showPassword2
                                ? "hide the password"
                                : "display the password"
                            }
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            onMouseUp={handleMouseUpPassword2}
                          >
                            {showPassword2 ? (
                              <Visibility style={{ color: "black" }} />
                            ) : (
                              <VisibilityOff style={{ color: "black" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="password2" />
                    </Typography>
                  </FormControl>
                </Box>
              </StyledCard>
              <StyledCard>
                <Box className={classes.formContainer}>
                  <Typography className={classes.title}>
                    Company info
                  </Typography>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.companyName && touched.companyName)}
                  >
                    <InputLabel className={classes.label} htmlFor="companyName">
                      Company name
                    </InputLabel>
                    <Input
                      id="companyName"
                      className={classes.inputField}
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{
                        className: classes.inputField,
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="companyName" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    variant="standart"
                    fullWidth
                    error={Boolean(errors.companySite && touched.companySite)}
                  >
                    <InputLabel className={classes.label} htmlFor="companySite">
                      Company web-site
                    </InputLabel>
                    <Input
                      id="companySite"
                      className={classes.inputField}
                      value={values.companySite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ marginTop: "4px" }}
                    >
                      <ErrorMessage name="companySite" />
                    </Typography>
                  </FormControl>
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.industry && touched.industry)}
                  >
                    <Query query={allIndustries}>
                      {({ loading, error, data }) => {
                        if (loading) {
                          return <Typography>Loading...</Typography>;
                        }
                        if (error) {
                          return (
                            <Typography color="error">
                              Error! {error.message}
                            </Typography>
                          );
                        }

                        const optionsIndustries = data.allIndustries.map(
                          (industry) => ({
                            value: industry.id,
                            text: industry.name,
                          }),
                        );

                        return (
                          <Autocomplete
                            id="industry"
                            options={optionsIndustries}
                            getOptionLabel={(option) => option.text || option}
                            className={classes.inputField}
                            value={values.industry}
                            onChange={(e, newValue) =>
                              setFieldValue("industry", newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Industry"
                                InputLabelProps={{ className: classes.label }}
                                InputProps={{
                                  ...params.InputProps,
                                  className: classes.inputField,
                                }}
                                error={Boolean(
                                  errors.industry && touched.industry,
                                )}
                              />
                            )}
                          />
                        );
                      }}
                    </Query>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <ErrorMessage name="industry" />
                    </Typography>
                  </FormControl>{" "}
                  <FormControl
                    style={{ minHeight: "80px" }}
                    fullWidth
                    error={Boolean(errors.industry && touched.industry)}
                  >
                    <Autocomplete
                      id="companySize"
                      options={companySizeList}
                      getOptionLabel={(option) => option.text || option}
                      className={classes.inputField}
                      value={values.companySize}
                      onChange={(e, newValue) =>
                        setFieldValue("companySize", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Company size"
                          InputLabelProps={{ className: classes.label }}
                          InputProps={{
                            ...params.InputProps,
                            className: classes.inputField,
                          }}
                          error={Boolean(
                            errors.companySize && touched.companySize,
                          )}
                        />
                      )}
                    />
                    <Typography
                      variant="caption"
                      color="error"
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      <ErrorMessage name="companySize" />
                    </Typography>
                  </FormControl>
                </Box>
              </StyledCard>
            </Box>
            <Box className={classes.footer}>
              <FormControlLabel
                style={{
                  margin: 0,
                }}
                control={
                  <Checkbox
                    required
                    id="accept-terms"
                    name="accept-terms"
                    color="primary"
                    value={acceptService}
                    onChange={(e) => setAcceptService(e.target.checked)}
                  />
                }
                label="I Accept The Terms Of Service"
                labelPlacement="end"
                className={classes.acceptService}
              />
              <ButtonRoot
                type={"submit"}
                text={"Start 14 days trial"}
                className={classes.button}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default DesktopSignUp;
