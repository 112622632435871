import gql from "graphql-tag";
import * as fragment from "../fragments/index";

export const getUsers = gql`
  query getUsers {
    users {
      ...User
    }
  }
  ${fragment.UserFragment}
`;

export const users_employee_page_filter = gql`
  query getUsers {
    users {
      id
      dateJoined
      lastName
      isSuperuser
      isAdmin
      email
      firstName
      lastName
      avatar
      userDocuments {
        document
        id
        filename
      }
      workingTime
      isActive
      startTime
      status
      online
      phone
      birthday
      workFormat
      typeOfWork
      department {
        id
        name
      }
      role
      additionalInformation
      programmingLangsAndSpecializations {
        id
        name
      }
      experience
      jobTitle {
        id
        name
      }
      team {
        id
        name
      }
      englishLevel {
        id
        name
      }
      equipments {
        id
        modelName
        type
      }
    }
  }
`;

export const allProgrammingLangsAndSpecializations = gql`
  query allProgrammingLangsAndSpecializations {
    allProgrammingLangsAndSpecializations {
      id
      name
    }
  }
`;

export const allTechnologies = gql`
  query technologies {
    technologies {
      id
      name
    }
  }
`;

export const allEquipments = gql`
  query allEquipments {
    allEquipments {
      id
      modelName
      manufacturer
    }
  }
`;

export const getEmployees = gql`
  query getUsers {
    users {
      id
      firstName
      lastName
      status
      salary {
        id
        salary
        startDate
      }
    }
  }
`;

export const User = gql`
  query me {
    me {
      ...User
    }
  }
  ${fragment.UserFragment}
`;

export const getUser = gql`
  query getUser($id: ID) {
    user(id: $id) {
      id
      lastName
      firstName
      avatar
      workingTime
    }
  }
`;

export const allManagers = gql`
  query allManagers {
    allManagers {
      ...User
    }
  }
  ${fragment.UserFragment}
`;

export const teamsByCompany = gql`
  query teamsByCompany($companyId: ID) {
    teamsByCompany(companyId: $companyId) {
      ...Team
    }
  }
  ${fragment.TeamFragment}
`;

export const teams = gql`
  query teams {
    teams {
      ...Team
    }
  }
  ${fragment.TeamFragment}
`;

export const teamsTeamsPage = gql`
  query teams {
    teams {
      id
      name
      teamLead {
        id
        firstName
        lastName
      }
      projects {
        id
        name
      }
      technologies {
        id
        name
      }
      users {
        id
        lastName
        firstName
        avatar
      }
    }
  }
`;

export const getTeams = gql`
  query teams {
    teams {
      id
      name
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getTeams_employee_page_filter = gql`
  query teams {
    teams {
      id
      name
    }
  }
`;

export const teamById = gql`
  query teamById($teamId: ID) {
    teamById(teamId: $teamId) {
      ...Team
    }
  }
  ${fragment.TeamFragment}
`;

export const jobTitles_employee_page_filter = gql`
  query jobTitles {
    jobTitles {
      id
      name
    }
  }
`;

export const jobTitles = gql`
  query jobTitles {
    jobTitles {
      ...JobTitle
    }
  }
  ${fragment.JobTitleFragment}
`;

export const project = gql`
  query project($id: Int) {
    project(id: $id) {
      id
      name
      avatar
      trackingTime
      spentTime
      projectType
      client {
        id
        organization
        primaryContact {
          city
          country
          email
          firstName
          facebook
          id
          lastName
          linkedin
          notes
          phone
          position
          skype
          company {
            id
            name
            locationName
            siteUrl
            avatar
          }
        }
      }
      status
      team {
        id
        name
      }
      projectemployeeSet {
        id
        service {
          id
          name
        }
        employeeRate {
          currency
          id
          rate
          rateType
        }
        employee {
          id
          firstName
          lastName
          jobTitle {
            id
            name
          }
        }
      }
      projectManager {
        id
        firstName
        lastName
      }
      productManager {
        id
        firstName
        lastName
      }
      technologies {
        id
        name
      }
      files {
        id
        file
      }
      notes {
        id
        note {
          id
          title
          text
          lastUpdatedDate
          author {
            id
            firstName
            lastName
          }
          lastEditUser {
            id
            firstName
            lastName
          }
        }
        permissions {
          id
          publicMode
          privateMode
          visibleFor {
            id
            firstName
            lastName
          }
          editableFor {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const projects = gql`
  query projects {
    projects {
      ...Project
    }
  }
  ${fragment.ProjectFragment}
`;

export const getProjects = gql`
  query projects {
    projects {
      id
      name
    }
  }
`;

export const simpleProjects = gql`
  query projects {
    projects {
      id
      name
      icon
      status
    }
  }
`;

export const projectsByTeam = gql`
  query projectsByTeam($teamId: ID) {
    projectsByTeam(teamId: $teamId) {
      id
      name
      avatar
      status
      team {
        id
        name
      }
      projectemployeeSet {
        currency
        id
        rate
        rateType
        service {
          id
          name
        }
        employee {
          id
          firstName
          lastName
          jobTitle {
            id
            name
          }
        }
      }
      client {
        id
        organization
      }
      projectType
      technologies {
        id
        name
      }
    }
  }
`;

export const projectsByCompany = gql`
  query projectsByCompany($companyId: ID) {
    projectsByCompany(companyId: $companyId) {
      id
      name
      avatar
      status
      team {
        id
        name
      }
      projectemployeeSet {
        id
        service {
          id
          name
        }
        employeeRate {
          currency
          id
          rate
          rateType
        }
        employee {
          id
          firstName
          lastName
          jobTitle {
            id
            name
          }
        }
      }
      client {
        id
        organization
      }
      projectType
      files {
        id
        file
      }
    }
  }
`;
export const getUserByToken = gql`
  query GetUserByToken($token: String!) {
    userByToken(token: $token) {
      firstName
      lastName
    }
  }
`;
export const myProjects = gql`
  query myProjects {
    myProjects {
      id
      name
      avatar
    }
  }
`;

export const jobTitleById = gql`
  query jobTitleById($jobTitleId: ID) {
    jobTitleById(jobTitleId: $jobTitleId) {
      programmingLangsAndSpecializations {
        id
        name
      }
    }
  }
`;

export const allEnglishLevel = gql`
  query allEnglishLevel {
    allEnglishLevel {
      id
      name
    }
  }
`;

export const userProjects = gql`
  query userProjects {
    userProjects {
      id
      name
      avatar
      status
      projectManager {
        id
      }
      team {
        id
        name
      }
    }
  }
`;

export const getUserTimesForProjectAndDate = gql`
  query getUserTimesForProjectAndDate(
    $projectId: Int!
    $userId: Int!
    $date: Date!
  ) {
    getUserTimesForProjectAndDate(
      userId: $userId
      projectId: $projectId
      date: $date
    )
  }
`; // TODO: do smth with it

export const usersReports = gql`
  query usersReports($dateParam: Date) {
    usersReports(dateParam: $dateParam) {
      ...DailyReport
    }
  }
  ${fragment.DailyReportFragment}
`;

export const paginatedUsers = gql`
  query paginatedUsers($page: Int) {
    paginatedUsers(page: $page) {
      pages
      hasNext
      hasPrev
      objects {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`; // pagination is broken

export const paginatedProjects = gql`
  query paginatedProjects($page: Int) {
    paginatedProjects(page: $page) {
      pages
      hasNext
      hasPrev
      objects {
        ...Project
      }
    }
  }
  ${fragment.ProjectFragment}
`;
export const globalUsersWorkStatistics = gql`
  query globalUsersWorkStatistics($dateParam: Date, $team: String) {
    globalUsersWorkStatistics(dateParam: $dateParam, team: $team)
  }
`; // NOT OK

export const globalUsersWorkStatisticsYear = gql`
  query globalUsersWorkStatisticsYear(
    $dateParam: Date
    $team: ID
    $office: ID
  ) {
    globalUsersWorkStatisticsYear(
      dateParam: $dateParam
      team: $team
      office: $office
    )
  }
`;

export const globalUsersWorkStatisticsMonth = gql`
  query globalUsersWorkStatisticsMonth(
    $dateParam: Date
    $team: ID
    $office: ID
  ) {
    globalUsersWorkStatisticsMonth(
      dateParam: $dateParam
      team: $team
      office: $office
    )
  }
`;

export const globalUsersWorkStatisticsWeek = gql`
  query globalUsersWorkStatisticsWeek(
    $dateParam: Date
    $team: ID
    $office: ID
  ) {
    globalUsersWorkStatisticsWeek(
      dateParam: $dateParam
      team: $team
      office: $office
    )
  }
`;

export const globalUserWorkStatistics = gql`
  query globalUserWorkStatistics($userId: ID, $dateParam: Date) {
    globalUserWorkStatistics(userId: $userId, dateParam: $dateParam)
  }
`;

export const userReportsInfoPerWeek = gql`
  query userReportsInfoPerWeek($userId: ID, $dateParam: Date) {
    userReportsInfoPerWeek(userId: $userId, dateParam: $dateParam)
  }
`;

export const userStatisticsByProject = gql`
  query userStatisticsByProject($dateParam: Date, $userId: ID) {
    userStatisticsByProject(dateParam: $dateParam, userId: $userId)
  }
`;

export const userStatisticsByProjectWeek = gql`
  query userStatisticsByProjectWeek($dateParam: Date, $userId: ID) {
    userStatisticsByProjectWeek(dateParam: $dateParam, userId: $userId)
  }
`;

export const userStatisticsByProjectMonth = gql`
  query userStatisticsByProjectMonth($dateParam: Date, $userId: ID) {
    userStatisticsByProjectMonth(dateParam: $dateParam, userId: $userId)
  }
`;

export const userStatisticsByProjectYear = gql`
  query userStatisticsByProjectYear($dateParam: Date, $userId: ID) {
    userStatisticsByProjectYear(dateParam: $dateParam, userId: $userId)
  }
`;

export const dailyReports = gql`
  query dailyReports {
    dailyReports {
      project {
        ...Project
      }
      user {
        ...User
      }
    }
  }
  ${fragment.ProjectFragment}
  ${fragment.UserFragment}
`;

export const workersReportByDay = gql`
  query workersReportByDay($date: Date, $projectId: ID, $userId: ID) {
    workersReportByDay(date: $date, projectId: $projectId, userId: $userId) {
      id
      firstName
      lastName
      avatar
      date
      team
      jobtitle
      projects {
        id
        name
        icon
        reportPoints {
          id
          text
          spendTime
        }
        trackedTime
        spentTime
      }
    }
  }
`;

export const userReports = gql`
  query userReports($userId: ID) {
    userReports(userId: $userId) {
      id
      firstName
      lastName
      avatar
      projects {
        id
        name
        icon
        reports {
          id
          date
          trackedTime
          spentTime
          reportPoints {
            id
            text
            spendTime
          }
        }
      }
    }
  }
`;

export const userReport = gql`
  query userReport($userId: ID, $dateParam: Date) {
    userReport(userId: $userId, dateParam: $dateParam) {
      ...DailyReport
    }
  }
  ${fragment.DailyReportFragment}
`;
export const usersReportsInfoPerDay = gql`
  query usersReportsInfoPerDay($dateParam: Date, $team: ID, $office: ID) {
    usersReportsInfoPerDay(dateParam: $dateParam, team: $team, office: $office)
  }
`;
export const allMonthlyCalendarData = gql`
  query allMonthlyCalendarData {
    allMonthlyCalendarData {
      ...MonthlyCalendarData
    }
  }
  ${fragment.MonthlyCalendarDataFragment}
`;
export const allIndustries = gql`
  query allIndustries {
    allIndustries {
      id
      name
    }
  }
`;
export const userCompanyInfo = gql`
  query userCompanyInfo($id: ID) {
    userCompanyInfo(id: $id) {
      ...userCompanyInfo
    }
  }
  ${fragment.CompanyInfoFragment}
`;

export const workerProjectsByDay = gql`
  query workerProjectsByDay($userId: ID, $dateParam: [Date]) {
    workerProjectsByDay(userId: $userId, dateParam: $dateParam) {
      id
      reportId
      name
      icon
      trackedTime
      date
      workDone {
        id
        text
        spendTime
      }
    }
  }
`;

export const workerProjects = gql`
  query workerProjects($userId: ID) {
    workerProjects(userId: $userId) {
      id
      projectId
      name
      avatar
      trackedTime
      date
      workDone {
        id
        text
        spentTime
      }
    }
  }
`;

export const projectUsers = gql`
  query projectUsers($projectId: ID!) {
    projectUsers(projectId: $projectId) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const reportWorkerProject = gql`
  query reportWorkerProject(
    $userId: ID
    $startDate: Date
    $endDate: Date
    $projectId: ID
    $teamId: ID
    $officeId: ID
    $isApproved: Boolean
  ) {
    reportWorkerProject(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
      projectId: $projectId
      teamId: $teamId
      officeId: $officeId
      isApproved: $isApproved
    ) {
      id
      date
      timeWorked
      spentTime
      reportPoints {
        id
        text
        spendTime
      }
      user {
        firstName
        lastName
        id
        online
        status
      }
      project {
        id
        name
      }
    }
  }
`;

export const allEventsByMonth = gql`
  query allEventsByMonth($dateParam: Date) {
    allEventsByMonth(dateParam: $dateParam) {
      id
      dateStart
      dateEnd
      user {
        id
      }
      title
    }
  }
`;

export const allEventsByMonthUser = gql`
  query allEventsByMonthUser($dateParam: Date, $userId: ID) {
    allEventsByMonthUser(dateParam: $dateParam, userId: $userId) {
      id
      dateStart
      dateEnd
      title
    }
  }
`;

export const FINANCE_OVERVIEW = gql`
  query financeOverview(
    $fromDate: Date
    $toDate: Date
    $range: Int!
    $typeof: Int!
  ) {
    financeOverview(
      fromDate: $fromDate
      toDate: $toDate
      range: $range
      typeof: $typeof
    )
  }
`;

export const INCOME_AND_EXPENSES_LABELS = gql`
  query incomeAndExpensesLabels {
    incomeAndExpensesLabels {
      id
      name
    }
  }
`;

export const INCOME_AND_EXPENSES = gql`
  query incomeAndExpenses {
    incomeAndExpenses {
      id
      name
      label {
        id
        name
      }
      amount
      currency
      type
      date
      note
      fee
      feeType
      file
      result
      tag
      project {
        name
        id
      }
      client {
        id
        firstName
        lastName
      }
      repeatablePayment {
        afterAmountPayments
        everyDays
        id
        lastDatePayment
        repeatTypeEnd
        formatDays
      }
    }
  }
`;

export const FIRST_LAST_ENTRY = gql`
  query firstLastFinanceEntry {
    firstLastFinanceEntry
  }
`;

export const ALL_COMPANY_EQUIPMENTS = gql`
  query allCompanyEquipments {
    allCompanyEquipments {
      id
      type
      modelName
      manufacturer
      serialNumber
      photos {
        id
        photo
      }
      user {
        id
        email
        firstName
        lastName
        department {
          id
        }
      }
      notes
    }
  }
`;

export const allHolidaysPerMonth = gql`
  query allHolidaysPerMonth($year: Int, $month: Int) {
    allHolidaysPerMonth(year: $year, month: $month) {
      id
      date
      title
      type
      user {
        id
        firstName
        lastName
        department {
          id
        }
      }
    }
  }
`;

export const allEventsPerMonth = gql`
  query allEventsPerMonth($year: Int, $month: Int) {
    allEventsPerMonth(year: $year, month: $month) {
      id
      dateStart
      dateEnd
      type
      status
      user {
        id
        firstName
        lastName
        department {
          id
        }
      }
    }
  }
`;

export const userDocuments = gql`
  query userDocuments(
    $userId: ID
    $order: String
    $q: String
    $createdBy: ID
    $updatedBy: ID
  ) {
    userDocuments(
      userId: $userId
      order: $order
      q: $q
      createdBy: $createdBy
      updatedBy: $updatedBy
    ) {
      id
      title
      content
      user {
        id
        firstName
        lastName
      }
      editable
      createDate
      updateDate
      public
      visibleFor {
        id
        firstName
        lastName
      }
      editableFor {
        id
        firstName
        lastName
      }
      updateBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getManualTimeForProjectAndDate = gql`
  query getManualTimeForProjectAndDate(
    $userId: Int!
    $projectId: Int!
    $date: Date!
  ) {
    getManualTimeForProjectAndDate(
      userId: $userId
      projectId: $projectId
      date: $date
    )
  }
`;

export const clients = gql`
  query clients {
    clients {
      id
      contactPersons {
        id
        firstName
        lastName
        email
      }
      primaryContact {
        id
        lastName
        firstName
        facebook
        email
        phone
        skype
        linkedin
      }
      industryType {
        id
        value
      }
      projectType {
        id
        value
      }
      sourceType {
        id
        value
      }
      organization
      createdAt
      currency
      industry
      keyNeed
      project
      source
      value
      website
      owner {
        id
        firstName
        lastName
      }
      archived
    }
  }
`;

export const getContactPersons = gql`
  query contactPersons {
    contactPersons {
      id
      firstName
      lastName
      position
      country
      city
      phone
      linkedin
      facebook
      skype
      notes
      email
    }
  }
`;

export const cities = gql`
  query cities($cityName: String!, $language: String) {
    cities(cityName: $cityName, language: $language) {
      id
      name
      displayName
      population
    }
  }
`;

export const getOfficesList = gql`
  query getOfficesList {
    getOfficesList {
      id
      name
    }
  }
`;

export const getCityByCountryAndName = gql`
  query getCityByCountryAndName($cityName: String!, $countryName: String!) {
    getCityByCountryAndName(cityName: $cityName, countryName: $countryName) {
      id
      name
      displayName
      population
    }
  }
`;

export const getUserReportsForDate = gql`
  query getUserReportsForDate($userId: ID!, $date: Date!) {
    getUserReportsForDate(userId: $userId, date: $date) {
      id
      project {
        id
        name
      }
      trackedTime
      manualTime
    }
  }
`;

export const getPollsByCompany = gql`
  query getPollsByCompany {
    getPollsByCompany {
      id
      question
      isAnonymous
      isMultipleChoice
      totalVotes
      isUsersAddOption
      choices {
        id
        choiceText
        voted
        users {
          id
          user {
            id
            firstName
            lastName
            avatar
          }
        }
      }
      userChoice {
        id
        answer
        choice {
          id
        }
      }
      createdAt
      startDate
      endDate
      answers {
        id
        answer
        user {
          id
          firstName
          lastName
          avatar
        }
      }
      totalUniqueVotes
      totalUsers
      allAnswers {
        id
        answer
        user {
          id
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

export const getPollByCompany = gql`
  query getPollByCompany($id: ID) {
    getPollByCompany(id: $id) {
      id
      question
      choices {
        id
        choiceText
        voted
        users {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
      userChoice {
        id
        answer
        choice {
          id
        }
      }
      createdAt
      startDate
      endDate
      answers {
        id
        answer
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const getRecurringNotificationsByCompany = gql`
  query getRecurringNotificationsByCompany {
    getRecurringNotificationsByCompany {
      id
      name
      title
      recipients {
        id
        firstName
        lastName
        avatar
      }
      startDate
      endDate
      time
      frequencyType
      frequencyValue
    }
  }
`;

export const getCustomNotificationsByCompany = gql`
  query getCustomNotificationsByCompany {
    getCustomNotificationsByCompany {
      id
      title
      createdAt
      name
      recipients {
        id
        firstName
        lastName
        avatar
      }
    }
  }
`;

export const getNotificationSettingsByCompany = gql`
  query getNotificationSettingsByCompany {
    getNotificationSettingsByCompany {
      id
      notificationType
      isOn
    }
  }
`;

export const getReviewReviews = gql`
  query getReviewReviews {
    getReviewReviews {
      id
      fromUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      toUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      general
      skills
      qualityOfWork
      meetsDeadlines
      adherenceToWorkingSchedule
      communication
      cooperation
      feedback
      status
    }
  }
`;

export const getReviewRequestedReviews = gql`
  query getReviewRequestedReviews {
    getReviewRequestedReviews {
      id
      fromUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      toUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      general
      skills
      qualityOfWork
      meetsDeadlines
      adherenceToWorkingSchedule
      communication
      cooperation
      feedback
      status
    }
  }
`;

export const getReviewReviewsToUser = gql`
  query getReviewReviewsToUser {
    getReviewReviewsToUser {
      id
      fromUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      toUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      general
      skills
      qualityOfWork
      meetsDeadlines
      adherenceToWorkingSchedule
      communication
      cooperation
      feedback
      status
    }
  }
`;

export const getReviewRequestedReviewsFromUser = gql`
  query getReviewRequestedReviewsFromUser {
    getReviewRequestedReviewsFromUser {
      id
      fromUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      toUser {
        id
        firstName
        lastName
        team {
          id
          name
        }
      }
      general
      skills
      qualityOfWork
      meetsDeadlines
      adherenceToWorkingSchedule
      communication
      cooperation
      feedback
      status
    }
  }
`;

export const companyDiagrams = gql`
  query companyDiagrams {
    companyDiagrams {
      id
      title
      body
    }
  }
`;

export const getCompanyPayouts = gql`
  query companyPayouts {
    companyPayouts {
      bonusAmount
      currency
      id
      paidDate
      payoutAmount
      salaryReview
      user {
        id
        firstName
        lastName
      }
      salary
      salaryReviewType {
        value
        text
      }
    }
  }
`;

export const companyInvoices = gql`
  query companyInvoices {
    companyInvoices {
      total
      status
      id
      letter
      logo
      invoiceDueDate
      logoWidth
      companyName
      clientName
      invoiceTitle
      invoiceDate
      tax
      currency
      notes
      term
      productLines {
        id
        description
        quantity
        rate
        reportId
        reportType
      }
      quantityField
      rateField
      itemDescriptionField
      amountField
      pdfInvoice {
        filename
        file
      }
      swiftNumber
      phone
      location
      legalNumber
      email
      name
      clientLocation
      clientLegalNumber
      beneficiaryName
      bankName
      bankInfo
      accountNumber
      project {
        name
        id
      }
    }
  }
`;

export const getCandidatesBoard = gql`
  query candidatesDesk($archived: Boolean, $managerId: ID) {
    candidatesDesk(archived: $archived, managerId: $managerId) {
      id
      title
      items {
        candidate {
          id
          jobTitle {
            name
          }
          firstName
          lastName
          activity
        }
      }
    }
  }
`;

export const getAddCandidateEmailTypes = gql`
  query {
    candidateSelector {
      emailType {
        id
        value
      }
    }
  }
`;

export const getAddCandidateEnglishLevels = gql`
  query {
    candidateSelector {
      englishLevel {
        id
        value
      }
    }
  }
`;
export const getAddCandidateJobTypes = gql`
  query {
    candidateSelector {
      jobType {
        id
        value
      }
    }
  }
`;
export const getAddCandidateJobTitles = gql`
  query {
    candidateSelector {
      jobTitle {
        id
        value
      }
    }
  }
`;
export const getAddCandidateCurrency = gql`
  query {
    candidateSelector {
      currency {
        id
        value
      }
    }
  }
`;

export const allCandidates = gql`
  query {
    candidatesList {
      id
      firstName
      lastName
    }
  }
`;

export const getCandidateInfo = gql`
  query candidate($candidateId: ID) {
    candidate(candidateId: $candidateId) {
      id
      firstName
      lastName
      manager {
        ...User
      }
      jobTitle {
        id
        name
      }
      item {
        list {
          id
        }
      }
      education
      source
      sourceType {
        id
        value
      }
      birthday
      expectedValue
      jobType
      linkedinLink
      facebookLink
      candidateJobType
      emails {
        id
        email
        type
      }
      phones {
        id
        phone
        type
      }
      englishLevel {
        id
        name
      }
      archived
      currency
    }
  }
  ${fragment.UserFragment}
`;

export const getCandidateActivities = gql`
  query candidateActivities($candidateId: ID!) {
    candidateActivities(candidateId: $candidateId) {
      activityType
      endDate
      endTime
      id
      note
      startDate
      startTime
      title
      done
      candidate {
        id
        firstName
        lastName
      }
      manager {
        ...User
      }
      createdAt
      createdBy {
        ...User
      }
      updatedBy {
        ...User
      }
      updatedAt
    }
  }
  ${fragment.UserFragment}
`;

export const getCandidateNotes = gql`
  query candidateNotes($candidateId: ID!) {
    candidateNotes(candidateId: $candidateId) {
      createdAt
      id
      note
      createdBy {
        firstName
        lastName
      }
      candidate {
        firstName
        lastName
      }
    }
  }
`;

export const getCandidateFiles = gql`
  query candidateFiles($candidateId: ID!) {
    candidateFiles(candidateId: $candidateId) {
      createdAt
      file
      id
      title
      size
      manager {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const getCandidateHistoryItem = gql`
  query candidateHistoryItem($candidateId: ID!) {
    candidateHistoryItem(candidateId: $candidateId) {
      candidateId
      createdAt
      historyDate
      historyId
      historyUserId
      id
      nameItem
      candidateName
      managerName
    }
  }
`;

export const getCandidateHistoryEmail = gql`
  query CandidateHistoryEmail($candidateId: ID!) {
    candidateHistoryEmail(candidateId: $candidateId) {
      content
      emailFrom
      emailTo
      id
      title
      sentAt
      candidate {
        firstName
        lastName
        manager {
          lastName
          firstName
        }
      }
      createdBy {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const getFocusActivity = gql`
  query focusActivity($candidateId: ID!) {
    focusActivity(candidateId: $candidateId) {
      activityType
      createdAt
      endDate
      endTime
      id
      note
      done
      startDate
      startTime
      title
      manager {
        ...User
      }
      candidate {
        lastName
        firstName
      }
      createdBy {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const getCareerGrowthType = gql`
  query careerGrowthType {
    careerGrowthType
  }
`;

export const getSalaryReviews = gql`
  query salaryReviews {
    salaryReviews {
      id
      value
    }
  }
`;

export const getUserSalary = gql`
  query userSalary($userId: ID!) {
    userSalary(userId: $userId)
  }
`;

export const getCandidatesTable = gql`
  query candidatesTable($managerId: ID, $archived: Boolean) {
    candidatesTable(archived: $archived, managerId: $managerId) {
      id
      firstName
      lastName
      source
      nextActivity {
        startTime
        startDate
        title
        note
        id
        endDate
        done
        createdAt
        activityType
        endTime
      }
      sourceType
      manager {
        firstName
        lastName
        id
      }
    }
  }
`;

export const getAddCandidateSourceTypes = gql`
  query {
    candidateSelector {
      sourceType {
        id
        value
      }
    }
  }
`;

export const getLeadsBoard = gql`
  query leadsDesk($archived: Boolean, $ownerId: ID) {
    leadsDesk(archived: $archived, ownerId: $ownerId) {
      id
      title
      items {
        id
        lead {
          id
          organization
          primaryContact {
            id
            lastName
            firstName
          }
          activity
          label {
            ...Label
          }
        }
        order
      }
    }
  }
  ${fragment.LabelFragment}
`;

export const allLeads = gql`
  query leadsList($q: String) {
    leadsList(q: $q) {
      ...Lead
    }
  }
  ${fragment.LeadFragment}
`;

export const allOwners = gql`
  query allOwners {
    allOwners {
      ...User
    }
  }
  ${fragment.UserFragment}
`;

export const getLeadsTable = gql`
  query leadsTable($ownerId: ID, $archived: Boolean) {
    leadsTable(ownerId: $ownerId, archived: $archived) {
      createdAt
      id
      organization
      source
      projectType {
        id
        value
      }
      industryType {
        id
        value
      }
      sourceType {
        id
        value
      }
      label {
        color
        id
        name
      }
      nextActivity {
        startTime
        startDate
        title
        note
        id
        endDate
        done
        createdAt
        activityType
        endTime
      }
      owner {
        firstName
        id
        lastName
      }
      contactPersons {
        firstName
        lastName
        id
      }
      primaryContact {
        firstName
        lastName
        email
        id
      }
      website
    }
  }
`;

export const getLeadCurrency = gql`
  query getLeadCurrency {
    leadSelector {
      currency {
        id
        value
      }
    }
  }
`;

export const getLeadLabels = gql`
  query getLeadLabels {
    leadSelector {
      labels {
        id
        value
      }
    }
  }
`;

export const getLeadSource = gql`
  query getLeadSource {
    leadSelector {
      sourceType {
        id
        value
      }
    }
  }
`;
export const getClientSource = gql`
  query getClientSource {
    clientSelector {
      sourceType {
        id
        value
      }
    }
  }
`;
export const getClientProject = gql`
  query getClientProject {
    clientSelector {
      projectType {
        id
        value
      }
    }
  }
`;
export const getClientIndustry = gql`
  query getClientIndustry {
    clientSelector {
      industryType {
        id
        value
      }
    }
  }
`;

export const getLeadsEmailTypes = gql`
  query getLeadEmailType {
    leadSelector {
      emailType {
        id
        value
      }
    }
  }
`;

export const getLeadInfo = gql`
  query lead($leadId: ID) {
    lead(leadId: $leadId) {
      ...Lead
    }
  }
  ${fragment.LeadFragment}
`;

export const getLeadHistoryItem = gql`
  query leadHistoryItem($leadId: ID) {
    leadHistoryItem(leadId: $leadId) {
      createdAt
      historyDate
      historyId
      historyChangeReason
      historyType
      historyUserId
      id
      listId
      order
      leadId
      nameItem
    }
  }
`;

export const getLeadActivities = gql`
  query leadActivities($leadId: ID!) {
    leadActivities(leadId: $leadId) {
      activityType
      endDate
      endTime
      id
      note
      startDate
      startTime
      title
      done
      lead {
        ...Lead
      }
      manager {
        ...User
      }
      createdBy {
        ...User
      }
      createdAt
      updatedBy {
        ...User
      }
      updatedAt
    }
  }
  ${fragment.LeadFragment}
  ${fragment.UserFragment}
`;

export const getLeadNotes = gql`
  query leadNotes($leadId: ID!) {
    leadNotes(leadId: $leadId) {
      createdAt
      id
      note
      createdBy {
        firstName
        lastName
      }
      lead {
        ...Lead
      }
    }
  }
  ${fragment.LeadFragment}
`;

export const getLeadFiles = gql`
  query leadFiles($leadId: ID!) {
    leadFiles(leadId: $leadId) {
      createdAt
      file
      id
      title
      size
      lead {
        ...Lead
      }
      manager {
        ...User
      }
    }
  }
  ${fragment.LeadFragment}
  ${fragment.UserFragment}
`;

export const getFocusLeadActivity = gql`
  query focusLeadActivity($leadId: ID!) {
    focusLeadActivity(leadId: $leadId) {
      activityType
      createdAt
      endDate
      endTime
      id
      note
      startDate
      startTime
      title
      done
      manager {
        ...User
      }
      lead {
        ...Lead
      }
      createdBy {
        ...User
      }
    }
  }
  ${fragment.LeadFragment}
  ${fragment.UserFragment}
`;

export const getLeadHistoryEmail = gql`
  query leadHistoryEmail($leadId: ID!) {
    leadHistoryEmail(leadId: $leadId) {
      content
      emailFrom
      emailTo
      id
      title
      sentAt
      lead {
        ...Lead
      }
      createdBy {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
  ${fragment.LeadFragment}
`;

export const getIndustryType = gql`
  query industryType {
    leadSelector {
      industryType {
        id
        value
      }
    }
  }
`;
export const getLeadsProject = gql`
  query leadsProject {
    leadSelector {
      projectType {
        id
        value
      }
    }
  }
`;

export const getCandidateStages = gql`
  query getCandidateStages {
    candidateStages {
      id
      title
    }
  }
`;
export const getClientFiles = gql`
  query clientFiles($clientId: ID!) {
    clientFiles(clientId: $clientId) {
      createdAt
      description
      file
      id
      size
      title
      manager {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getLeadStages = gql`
  query getLeadStages {
    leadStages {
      id
      title
    }
  }
`;

export const getCandidateTemplates = gql`
  query getCandidateTemplates {
    candidateEmailTemplates {
      archived
      category
      content
      createdAt
      id
      templateName
      subject
      updatedAt
    }
  }
`;
export const getClientActivities = gql`
  query clientActivities($clientId: ID!) {
    clientActivities(clientId: $clientId) {
      activityType
      createdAt
      endDate
      endTime
      id
      done
      note
      startDate
      startTime
      title
      updatedAt
      manager {
        ...User
      }
      createdBy {
        ...User
      }
      updatedBy {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;
export const getClientHistoryEmail = gql`
  query clientHistoryEmail($clientId: ID!) {
    clientHistoryEmail(clientId: $clientId) {
      content
      emailFrom
      emailTo
      id
      sentAt
      title
      client {
        id
        primaryContact {
          id
          lastName
          firstName
          facebook
          email
          phone
          skype
          linkedin
        }
      }
      createdBy {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const getClientNotes = gql`
  query clientNotes($clientId: ID) {
    clientNotes(clientId: $clientId) {
      createdAt
      id
      note
      createdBy {
        id
        firstName
        lastName
      }
      client {
        id
        primaryContact {
          id
          lastName
          firstName
          facebook
          email
          phone
          skype
          linkedin
        }
      }
    }
  }
`;

export const getLeadTemplates = gql`
  query getLeadTemplates {
    leadEmailTemplates {
      archived
      category
      content
      createdAt
      id
      templateName
      subject
      updatedAt
    }
  }
`;

export const getClientFocusActivity = gql`
  query focusClientActivity($clientId: ID) {
    focusClientActivity(clientId: $clientId) {
      activityType
      endDate
      done
      createdAt
      id
      endTime
      note
      startDate
      startTime
      title
      updatedAt
      manager {
        id
        firstName
        lastName
      }
      client {
        id
        primaryContact {
          id
          lastName
          firstName
          facebook
          email
          phone
          skype
          linkedin
        }
      }
      createdBy {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;
export const getContactPersonByProject = gql`
  query contactPersons($projectId: ID!) {
    contactPersonsByProject(projectId: $projectId) {
      city
      firstName
      id
      lastName
    }
  }
`;
export const getContactPerson = gql`
  query contactPerson($contactId: ID!) {
    contactPerson(contactId: $contactId) {
      phone
      firstName
      email
      facebook
      country
      lastName
      id
      linkedin
      position
      skype
    }
  }
`;

export const getIndustryClientType = gql`
  query getIndustryClientType {
    clientSelector {
      industryType {
        id
        value
      }
    }
  }
`;

export const getProjectClientType = gql`
  query getProjectClientType {
    clientSelector {
      projectType {
        value
        id
      }
    }
  }
`;

export const getSourceClientType = gql`
  query getSourceClientType {
    clientSelector {
      sourceType {
        id
        value
      }
    }
  }
`;

export const allJobPosts = gql`
  query jobPostsList {
    jobPostsList {
      id
      title
      publicationDate
      publicLink
      reviewCount
      owner {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;
export const getJobPostParagraph = gql`
  query MyQuery {
    jobPostParagraphList {
      createdAt
      file
      id
      title
      owner {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const getJobPost = gql`
  query jobPost($jobPostId: ID) {
    jobPost(jobPostId: $jobPostId) {
      id
      publicLink
      publicationDate
      reviewCount
      title
      paragraphs {
        createdAt
        file
        id
        title
      }
      owner {
        ...User
      }
    }
  }
  ${fragment.UserFragment}
`;

export const getAllProspects = gql`
  query allProspects {
    allProspects {
      coverLetter
      createdAt
      id
      jobLink
      q1
      q2
      q4
      q5
      q3
      status
      q6
      prospectFiles {
        createdAt
        file
        id
        name
        manager {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
export const getCandidateOwnershipHistory = gql`
  query candidateOwnershipHistory($candidateId: ID!) {
    candidateOwnershipHistory(candidateId: $candidateId) {
      historyChangeReason
      historyDate
      historyId
      historyType
      historyUserId
      id
      managerName
      managerNameChanged
    }
  }
`;
export const getLeadOwnershipHistory = gql`
  query MyQuery2($leadId: ID!) {
    leadOwnershipHistory(leadId: $leadId) {
      historyChangeReason
      historyDate
      historyId
      historyType
      historyUserId
      id
      managerName
      managerNameChanged
    }
  }
`;
export const getClientOwnershipHistory = gql`
  query MyQuery($clientId: ID!) {
    clientOwnershipHistory(clientId: $clientId) {
      historyDate
      historyId
      historyType
      historyUserId
      id
      managerName
      managerNameChanged
      historyChangeReason
    }
  }
`;

export const getGeneratedReports = gql`
  query getGeneratedReports {
    getGeneratedReports {
      clientAddress
      clientAddress2
      clientCountry
      clientName
      companyAddress
      companyAddress2
      companyCountry
      companyName
      endDate
      id
      logo
      logoWidth
      name
      startDate
      title
      type
      reports {
        employee
        id
        reportId
        project
        reports
        spentTime
      }
      project {
        name
      }
      employee {
        firstName
        lastName
      }
      pdfReport
    }
  }
`;

export const getSMTPProfiles = gql`
  query getSMTPProfiles {
    smtpProfiles {
      authUser
      id
      smtpHost
      smtpPort
      accessType
      accessUsers {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getIMAPProfiles = gql`
  query getIMAPProfiles {
    imapProfiles {
      authUser
      id
      imapHost
      imapPort
      accessType
      accessUsers {
        id
        firstName
        lastName
      }
    }
  }
`;

export const getAccessEmail = gql`
  query getAccessEmail {
    userAccessMails {
      id
      authUser
    }
  }
`;
export const getSpaceImages = gql`
  query spaceImages {
    spaceImages {
      id
      logo
      name
    }
  }
`;

export const getAllUserSpaces = gql`
  query allUserSpaces {
    allUserSpaces {
      id
      logo
      name
      isStarred
    }
  }
`;

export const getSpaceInfo = gql`
  query spaceInfo($id: ID!) {
    spaceInfo(id: $id) {
      id
      logo
      name
      whiteboards {
        body
        createdAt
        id
        name
        updatedAt
        isStarred
        updatedBy {
          id
          lastName
          firstName
        }
        createdBy {
          id
          lastName
          firstName
        }
      }
      shortcuts {
        id
        link
        name
      }
      files {
        createdAt
        file
        id
        editable
        updatedAt
        createdBy {
          id
          lastName
          firstName
        }
        filename
        isStarred
        statusDraft
      }
      updatedBy {
        id
        lastName
        firstName
      }
      overviewFile {
        createdAt
        file
        id
        editable
        updatedAt
        createdBy {
          id
          lastName
          firstName
        }
        filename
        isStarred
      }
      description
      createdBy {
        id
        lastName
        firstName
      }
      accessType
      accessUsers {
        id
        lastName
        firstName
      }
      isArchived
    }
  }
`;
export const getAllSpacesWhiteboards = gql`
  query getAllSpacesWhiteboards {
    spacesWhiteboard {
      body
      createdAt
      id
      isStarred
      name
      updatedAt
      createdBy {
        id
        lastName
        firstName
      }
      updatedAt
      updatedBy {
        id
        lastName
        firstName
      }
      space {
        id
        name
      }
    }
  }
`;

export const getAllSpacesFiles = gql`
  query getAllSpacesFiles {
    spacesFiles {
      createdAt
      editable
      file
      filename
      id
      isStarred
      statusDraft
      updatedAt
      space {
        id
        name
      }
      updatedBy {
        id
        lastName
        firstName
      }
      createdBy {
        id
        lastName
        firstName
      }
    }
  }
`;

export const convertHtmlToDocxString = gql`
  query convertHtmlToDocxString($html: String!) {
    convertHtmlToDocx(html: $html)
  }
`;

export const incomeAndExpensesForProjects = gql`
  query incomeAndExpensesForProjects {
    incomeAndExpensesForProjects {
      id
      totalExpenses
      totalIncome
      project {
        id
        name
      }
      week
      year
      day
      month
    }
  }
`;
export const incomeAndExpensesByClassification = gql`
  query incomeAndExpensesByClassification {
    incomeAndExpensesByClassification {
      classification {
        id
        name
      }
      week
      month
      year
      day
      id
      totalExpenses
      totalIncome
    }
  }
`;
export const incomeAndExpensesByPeriod = gql`
  query incomeAndExpensesByPeriod {
    incomeAndExpensesByPeriod {
      month
      totalIncome
      week
      year
      day
      totalExpenses
    }
  }
`;
export const getMonthlyTableFinanceData = gql`
  query getMonthlyTable {
    getMonthlyTable {
      netCashFlow
      income
      expense
      endBalance
      date
      beginningBalance
    }
  }
`;
export const getXlsxFileIncomeAndExpenses = gql`
  query MyQuery {
    generateXlsxTableIncomeAndExpenses {
      file
      filename
    }
  }
`;
export const getFutureIncomeAndExpenses = gql`
  query futureIncomeAndExpenses {
    futureIncomeAndExpenses {
      day
      month
      totalExpenses
      totalIncome
      week
      year
    }
  }
`;
export const getInvoiceData = gql`
  query getInvoiceData($startDate: Date, $projectId: ID, $endDate: Date) {
    getInvoiceData(
      endDate: $endDate
      projectId: $projectId
      startDate: $startDate
    ) {
      description
      quantity
      rate
    }
  }
`;
export const getInvoiceId = gql`
  query getInvoiceId {
    getInvoiceId
  }
`;

export const approvedReports = gql`
  query approvedReports(
    $endDate: Date
    $projectId: ID
    $startDate: Date
    $userId: ID
  ) {
    approvedReports(
      startDate: $startDate
      projectId: $projectId
      endDate: $endDate
      userId: $userId
    ) {
      createdAt
      id
      title
      rId
      updatedAt
      service {
        id
        name
      }
      user {
        lastName
        firstName
        id
        jobTitle {
          name
        }
      }
      createdBy {
        lastName
        firstName
        id
      }
      updatedBy {
        lastName
        firstName
        id
      }
      project {
        name
        id
        typeof
        company {
          id
          name
          owner {
            lastName
            firstName
            id
          }
        }
        client {
          id
          organization
          contactPersons {
            firstName
            id
            lastName
          }
          primaryContact {
            lastName
            firstName
            id
          }
        }
      }
      endDate
      startDate
      totalSpentHours
      approvedReportItems {
        date
        id
        trackedHours
        spentHours
        workDone
      }
    }
  }
`;

export const getLatestInvoiceByProject = gql`
  query getLatestInvoiceByProject($projectId: ID!) {
    getLatestInvoiceByProject(projectId: $projectId) {
      status
      id
      letter
      logo
      logoWidth
      companyName
      clientName
      invoiceTitle
      invoiceDate
      tax
      currency
      notes
      term
      productLines {
        id
        description
        quantity
        rate
      }
      quantityField
      rateField
      itemDescriptionField
      amountField
      pdfInvoice {
        filename
        file
      }
      swiftNumber
      phone
      location
      legalNumber
      email
      name
      clientLocation
      clientLegalNumber
      beneficiaryName
      bankName
      bankInfo
      accountNumber
    }
  }
`;

export const getCompanyPaymentsInfo = gql`
  query getCompanyPaymentsInfo($companyId: ID!) {
    getCompanyPaymentsInfo(companyId: $companyId) {
      swiftNumber
      isDefault
      id
      beneficiaryName
      bankName
      bankInfo
      accountNumber
      isDefault
    }
  }
`;
export const getCompanyServices = gql`
  query getCompanyServices {
    getCompanyServices {
      id
      name
    }
  }
`;

export const clientsSelectData = gql`
  query clientsSelectData {
    clientsSelect {
      id
      organization
    }
  }
`;

export const getPlans = gql`
  query plans {
    plans {
      duration
      id
      name
      description
      price
      planitemSet {
        id
        name
      }
    }
  }
`;
export const isSubscriptionValid = gql`
  query isSubscriptionValid {
    isSubscriptionValid
  }
`;

export const getMyOnboarding = gql`
  query getMyOnboarding {
    getMyOnboarding {
      completedAt
      id
      isCompleted
      steps {
        id
        isCompleted
        completedAt
        step
      }
    }
  }
`;
export const isCompletedOnboarding = gql`
  query isCompletedOnboarding {
    isCompletedOnboarding
  }
`;

export const incomeAndExpensesEmployee = gql`
  query incomeAndExpensesEmployee {
    incomeAndExpensesEmployee {
      billedHours
      balance
      id
      workedHours
      employee {
        id
        firstName
        lastName
        salary {
          id
          salary
          startDate
        }
        jobTitle {
          name
          id
        }
      }
    }
  }
`;
export const incomeAndExpensesProject = gql`
  query incomeAndExpensesProject {
    incomeAndExpensesProject {
      balance
      billedHours
      id
      totalExpenses
      totalIncome
      workedHours
      project {
        id
        name
      }
    }
  }
`;
export const productLinesInvoiceByReportIds = gql`
  query productLinesInvoiceByReportIds(
    $reportType: ReportTypeEnum!
    $reportIds: [ID]!
  ) {
    productLinesInvoiceByReportIds(
      reportIds: $reportIds
      reportType: $reportType
    ) {
      rate
      quantity
      description
      reportId
      reportType
    }
  }
`;

export const getProspect = gql`
  query prospect($prospectId: ID!) {
    prospect(prospectId: $prospectId) {
      coverLetter
      id
      createdAt
      jobLink
      q1
      q2
      q3
      q4
      q5
      status
      q6
      prospectFiles {
        createdAt
        file
        id
        name
        manager {
          id
          lastName
          firstName
        }
      }
    }
  }
`;

export const checkLimits = gql`
  query checkLimits($modelType: PaymentLimitTypeEnum!) {
    checkLimits(modelType: $modelType)
  }
`;
export const getNotificationsForUser = gql`
  query getNotificationsForUser($page: Int, $number: Int) {
    getNotificationsForUser(page: $page, number: $number) {
      page
      pages
      isNew
      objects {
        redirectTo
        id
        title
        createdAt
        isViewed
        user {
          id
          firstName
          lastName
          avatar
        }
      }
    }
  }
`;

export const saveUserViewedNotifications = gql`
  mutation saveUserViewedNotifications {
    saveUserViewedNotifications {
      success
      errors
    }
  }
`;

export const allEvents = gql`
  query allEvents {
    allEvents {
      id
      dateStart
      dateEnd
      type
      status
      user {
        id
        firstName
        lastName
        department {
          id
        }
      }
    }
  }
`;

export const employeeProfit = gql`
  query employeeProfit {
    employeeProfit {
      balance
      id
      month
      year
      employee {
        id
        lastName
        firstName
      }
    }
  }
`;
export const employeeIncomeByProject = gql`
  query employeeIncomeByProject {
    employeeIncomeByProject {
      amount
      id
      month
      year
      employee {
        id
        firstName
        lastName
      }
      project {
        id
        name
      }
      service {
        name
        id
      }
    }
  }
`;
