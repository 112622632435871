import React, { useEffect, useState } from "react";
import { saveData, useQueryParams } from "utils";
import Box from "@material-ui/core/Box";
import { ReactComponent as LoadingLogo } from "assets/Loading.svg";
import { useApolloClient } from "react-apollo";
import { User } from "api/queries";
import * as path from "constants/routes";

const ProcessToken = (props) => {
  const token = useQueryParams().get("token");
  const keepLoggedIn = useQueryParams().get("keepLoggedIn");
  const [loading, setLoading] = useState(true);
  const apolloClient = useApolloClient();
  useEffect(() => {
    const fetchUser = async () => {
      if (token && keepLoggedIn !== null) {
        saveData("token", token, keepLoggedIn);
        try {
          const response = await apolloClient.query({ query: User });

          if (response.data) {
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUser();
  }, [token, keepLoggedIn, apolloClient]);
  useEffect(() => {
    if (!loading) {
      props.history.push(path.DAILY_WORK);
    }
  }, [loading]);
  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingLogo
        style={{
          height: "176px",
          width: "145px",
        }}
      />
    </Box>
  );
};

export default ProcessToken;
